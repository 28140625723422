@import './dust-0.less';

itd5ka#pvp_progress_span {
	width: 100%;
	position: absolute;
	bottom: 0;
	padding: 0px 10px;
	transform: scale(var(--scale-hud-a));

	display: flex;
	justify-content: center;
	flex-direction: column;

	text-align: center;
}

itd5ka#pvp_progress_span {
	&.state-0 {
		progressbar,
		icons.container,
		label#pvp_timer {
			color: var(--color-5ka-b);
			text-shadow: none ;
		}
		progressbar,
		icons.container {
			display: none;
		}
	}

	&.state-1 {
		bottom: 6vw;

		button#pvp_start,
		button#pvp_stop {
			display: none;
		};
		label#pvp_timer {
			display: none;
			color: var(--color-5ka-b);
			text-shadow: none ;
		}

		icon {
			transform: translate(-50%, 45%) scale(0.7);
		}
	}

	&.state-win {
		display: none;
	}

	&.state-lose {
		progressbar,
		icons.container,
		label#pvp_timer,
		button#pvp_start,
		button#pvp_stop {
			display: none;
		}
	}

	button#pvp_start,
	button#pvp_stop {
		width: 30vw;
		margin: auto;
		margin-bottom: 7vw;
		margin-top: 2vw;
		font-size: 4vw;
	}

	container#controls {
		display: flex;
		margin: auto;
		gap: 10px;
	}

	label#pvp_timer {
		width: min-content;
		margin: auto;
		color: white;
		text-shadow: 0px 1px 0px black;
		display: flex;
		font-size: 1.5em;
	}

	progressbar {
		height: 20px;
		border-radius: 4px;
		background-color: #00000090;
		border: 2px solid #ffbc6a;

		&::before {
			transition-duration: 2s;
			background: linear-gradient(45deg, #ff8400, #ff0000);
			border-radius: 4px;
		}
	}

	progressbar,
	icons.container {
		width: 80%;
		margin: auto;
	}

	icons.container {
		position: relative;
		height: 64px;
	}

	icon {
		--progress: 0%;
		transition-duration: 1s;

		display: block;
		width: 64px;
		height: 64px;
		border-radius: 100%;
		background-color: #ffffff90;
		transform: translate(-50%, 45%) scale(0.7);
		z-index: 1;
		position: absolute;
		left: var(--progress);

		&::after {
			content: '^';
			color: red;
		}

		&::before {
			border-radius: 100%;
			content: '';
			.sprite-textures();
			transform: translate(-50%, -50%);
			margin-top: 50%;
			margin-left: 50%;
		}

		&#employee {
			z-index: 2;
		}

		&#employee::before {
			.textures-test_icon_1();
		}

		&#task::before {
			.textures-support_1-64px();
		}

		&.defeated {
			filter: grayscale(1);
			opacity: 0.3;
		}
	}
}
