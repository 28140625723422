@import './generic.less';
@import './textures.less';

itd5ka#menu.container {
	width: 100%;
	position: absolute;
	margin-bottom: 12px;
	bottom: 0;

	display: flex;
	justify-content: center;
	align-items: flex-end;
	gap: 1.5vw;

	text-align: center;
}

itd5ka#menu.container {
	--button-scale-js: 0.8;
	button#itd_main {
		font-size: 3.3vw;
		min-width: 30vw;
		display: flex;
		color: white;
		text-shadow: 1px 1px 1px #00000090;
		height: 15vw;
		align-items: center;
		justify-content: center;
	}

	label#itd_status {
		font-size: 2vw;
		margin: 0 auto;
		width: 80%;
		height: 4vw;
		// padding: 5px;
		background-color: #00000020;
		border-radius: 0 0 var(--radius-border-a) var(--radius-border-a);
		box-shadow: 0px 0px 10px inset #00000055;
		color: lightgreen;
		font-weight: 500;
		display: grid;
		align-items: center;
		text-align: center;
		transition-duration: 200ms;
	}

	.container {
		display: flex;
		flex-direction: column;
		height: 15vw;
		max-height: 120px;
	}

	button#inventory:active,
	button#shop:active,
	button#itd_reset:active,
	button#pvp:active {
		margin-bottom: 10px;

		&::before {
			transform: translate(-50%, -50%) scale(calc(var(--button-scale-js) * 0.8));
		}
	}

	button#inventory.selected,
	button#shop.selected,
	button#itd_main.selected,
	button#itd_reset.selected,
	button#pvp.selected {
		margin-bottom: 10px;
		margin-top: -10px;

		&::before {
			transform: translate(-50%, -50%) scale(calc(var(--button-scale-js) * 0.8));
		}
	}

	label#itd_status.selected {
		margin-top: -10px;
		transition-duration: 200ms;
	}

	button#inventory::before {
		.textures-icon-pawn();
	}

	button#shop::before {
		.textures-icon-shop();
	}

	button#itd_reset::before {
		.textures-icon-locations();
	}

	button#pvp::before {
		.textures-icon-pvp();
	}

	& > button {
		&::before {
			transition-duration: inherit;
			.sprite-textures();
			content: '';
			margin: auto;
			transform: translate(-50%, -50%) scale(calc(var(--button-scale-js) * 0.7));
			position: absolute;
		}

		width: 15vw;
		max-width: 120px;
		height: 15vw;
		max-height: 120px;
		display: block;
	}
}
