.reception_1 .screen .sportzal .sign {
	animation: blink 10s infinite;
}

.hr .screen2,
.kabinet_glavnogo .screen3 {
	animation: blink 3s infinite;
}

@keyframes blink {
	0% {
		opacity: 100%;
		filter: hue-rotate(0deg);
	}

	30% {
		opacity: 40%;
	}

	70% {
		opacity: 90%;
		filter: hue-rotate(90deg);
	}

	90% {
		opacity: 0%;
		filter: hue-rotate(120deg);
	}
}

.reception_1 .tv {
	opacity: 0;
	pointer-events: all;
	transition-duration: 500ms;

	&:hover {
		animation: poweron-blink 1s;
		animation-fill-mode: both;
		opacity: 1;
	}

	@keyframes poweron-blink {
		0% {
			opacity: 0%;
			filter: hue-rotate(0deg);
		}

		30% {
			opacity: 70%;
		}

		70% {
			opacity: 20%;
		}

		100% {
			opacity: 100%;
		}
	}
}

.reception_1,
.kafe {
	.effect_lamp,
	.effect_lamp2,
	.effect_lamp3 {
		opacity: 0.1;
		transition-duration: 300ms;
		pointer-events: all;
	}

	.effect_lamp:hover,
	.effect_lamp2:hover,
	.effect_lamp3:hover {
		opacity: 1;
	}
}
