// ITD 5ka

// Пыль (Да, надо подмести)
@import './dust-0.less';

// Верхняя панель itd
// [ gold gems coffee ---- journal settings ]
@import './header.less';

// gameplay ITD
// Этажи
@import './gameplay.less';

// gameplay - cutscenes
@import './cutscene.less';

// popup ITD
// (Настройки, log)
@import './popup.less';

// Нижняя панель itd
// [ --- inventory shop stages itd_reset pvp --- ]
@import './menu.less';

// Стили для pvp
@import './pvp.less';

// Вдогонку

@import './decor.less';
@import './elements.less';

// d220726
@import './debugmode.less';

// Texture Packer generated
// ask @tynrare
@import './textures.less';
@import './sprites-managers.less';
@import './npc.less';

// ----------------------------------------------------------------

html body {
	max-width: initial;
	padding: 0;
	overflow:hidden;
}

html,
body {
	width: 100%;
	height: 100%;
	margin: 0 auto;
}

db {
	display: none;
}

#fps {
	position: relative;
	bottom: -97%;
	margin: auto;
    margin-right: 10px;
    margin-top: -3.35%;
	color: #fff;
	font-weight: 200;
	font-size: 2.5vw;
	text-shadow: 1 1 0.2em rgb(0, 0, 140);
	z-index: 1;
	display: flex;
    justify-content: right;
}

#pointer-events-catcher {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	pointer-events: all;
	z-index: -2;
}

// tweakpane
.tp-dfwv {
	z-index: 1;
	pointer-events: all;	
    top: auto;
    bottom: 22vw;
	width: 50px;
    left: 3vw;

	.tp-rotv {
		transition-duration: 100ms;
		margin-top: -2em;
		opacity: 0.5;
		width: 50px;

		.tp-rotv_t {
			transition-duration: inherit;
			margin-left: -4vw;
		}

		&.tp-rotv-expanded {
			margin-top: 0em;
			opacity: 1;
			width:256px;

			.tp-rotv_t {
				margin-bottom: 0em;
			}
		}
	}
}
