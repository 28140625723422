@import './generic.less';
@import './elements.less';
@import './textures.less';
@import './animations.less';
@import './dust-0.less';
@import './tyndustre.less';

popup.container {
    background: linear-gradient(0deg, #00000000,#0000003b,#0000003b,#0000003b,#0000003b,#0000003b,#0000003b,#00000000);
    position: absolute;
    z-index: 8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 85%;
    pointer-events: auto;
	
	&.animated {
		.anim-popup-appear-1();
	}

	popup_footer {
		z-index: 8;
		width: 200%;
		height: 50%;
		pointer-events: auto;
		background: linear-gradient(#0000,#0000);
		position: absolute;
		left: -50%;
		bottom: -30vh;
	}

	popup_bg {
		content: "";
		--padding: 30px;
		background-color: var(--color-5ka-b);
		width: 90%;
		height: calc(var(--height) + var(--padding)*2);
		border-radius: var(--radius-border-a);
		border-top: 5px solid #ffffff50;
		border-bottom: 5px solid #00000050;
		position: absolute;
		box-shadow: 0 0 10px 2px #0003;
		z-index: 9;
		--width: 90%;
		--height: 60vh;
		top: calc(56% - var(--height)/2);
		left: calc(50% - var(--width)/2);
		pointer-events: all;
		margin: auto;
		display: flex;
	
	}

	popup_inner {
		@decor_padding: 30px;
		--width: 75vw;
		--height: 60vh;
	
		&.area-style-itd5ka-0 {
			background-color: var(--color-5ka-white);
	
			border-radius: var(--radius-border-a);
			border-bottom: solid 5px #dbcfcb;
	
			/*&::before {
				content: '';
				--padding: @decor_padding;
				position: absolute;
				background-color: var(--color-5ka-b);
	
				width: calc(var(--width) + var(--padding) * 2);
				height: calc(var(--height) + var(--padding) * 2);
				top: @decor_padding * -1;
				left: @decor_padding * -1;
				border-radius: var(--radius-border-a);
				border-bottom: solid 5px #00000050;
				border-top: solid 5px #ffffff50;
				box-shadow: 0px 0px 10px 2px #00000033;
			}*/
	
			&::after {
				@dots_size: 4vh;
	
				width: calc(var(--width) * 0.8);
				left: calc(var(--width) * 0.1);
	
				content: '';
				border-top: dotted @dots_size var(--color-5ka-b);
				position: absolute;
				height: 0;
				top: @dots_size * -0.5;
			}
	
			label#header {
				z-index: 1;
				color: white;
				border-image: url('./res/img/decoration_flag-green.png') 46 / 46px;
				font-size: 6vw;
			}
	
			page {
				position: relative;
	
				&::before {
					width: 100%;
					position: absolute;
					height: 26vw;
					background-color: #00000020;
					border-bottom: 2px solid #00000020;
					content: '';
					border-radius: var(--radius-border-a) var(--radius-border-a) 0 0;
					top: 0;
					left: 0;
				}
			}
		}
	
		//transform: translate(-50%, -50%);
		position: absolute;
		top: calc(60% - var(--height) / 2);
		left: calc(50% - var(--width) / 2);
	
		display: flex;
		margin: auto;
	
		width: var(--width);
		height: var(--height);
	
		pointer-events: all;
		z-index: 10;
	
		button#close_popup {
			//transform: translate(50%, -50%);
			right: -@decor_padding - 35px;
			top: -@decor_padding - 35px;
	
			display: flex;
	
			position: absolute;
			//border-top: solid 2px #ffffff50;
			//box-shadow: 0 0 4px 4px #f33;
			transform: scale(0.5);
			background-color: transparent;
			border: none;
	
			&::before {
				.sprite-textures();
				.textures-icon-cross();
				content: '';
				margin: auto;
				transform: scale(1);
				background-color: #00000000;
				opacity: 1;
				filter: none;
				mix-blend-mode: normal;
			}
	
			transition-duration: 100ms;
	
			&:active {
				transform: rotate(-15deg) scale(0.7);
			}
		}
	}
}

page#shell_log {
	font-family: 'Azeret Mono', monospace;

	padding: 0.3em;
	height: 100%;
	overflow-y: auto;
	width: 100%;
	font-size: 14px;
}

page {
	label#header {
		font-weight: 600;
		padding: 10px 40px;
		font-size: min(3rem, 8vw);
		color: gray;
		text-transform: uppercase;
		margin: 0 auto;
		border-bottom: 2px solid gray;
		height: max-content;
		text-align: center;
	}

	&,
	* {
		scrollbar-width: if(@HIDE_SCROLLS, none, auto);
		font-family: "5ka Sans"!important
	}

	/* Works on Chrome, Edge, and Safari */
	&,
	*::-webkit-scrollbar {
		width: if(@HIDE_SCROLLS, 0px, auto);
	}
}

page#shop,
page#inventory,
page#itd_reward,
page#itd_room_details {
	#buttons {
		display: flex;
		width: 100%;
		justify-content: space-evenly;
		margin-bottom: 5px;
		font-size: 4vw;
		height: 1.5em;
		min-height: 1.5em;
		font-weight: 500;
		margin-top: 6vw;
	}

	icon {
		.sprite-textures();
		border-radius: 3px;

		&.empty {
			.textures-lock_closed-red-128px();
			height: 130px;
			transform: scale(0.7);
		}
	}

	tabs {
		//padding-top: 10px;
		//margin: 20px;
		overflow-y: hidden;

		button {
			font-size: 4vw;
			outline: none!important;
			border: none;
			background-color: transparent;
		}

		button.selected {
			border-bottom: 4px solid gray; //var(--color-5ka-a-ref);
		}

		button:active {
			border-bottom: 4px solid gray;
		}
	}

	tabs#content {
		flex-grow: 1;

		button {
			min-width: 100px;
			font-weight: bolder;

			.decor-itd-0();
			& > * {
				pointer-events: none;
			}
		}

		tab {
			display: flex;
			flex-flow: wrap;
			justify-content: space-around;
		}

		entry {
			height: 47vw;
			width: 65%;
			background: linear-gradient(180deg,#dddddd 0%,#eeeeee 100%);
			box-shadow: 0px 2px 2px gray;
			border-radius: 10px;
			margin: -6vw;
			margin-bottom: -4vw;
			padding: 0px;
			position: relative;
			transform: scale(0.7);

			display: flex;
			flex-direction: column;

			& > * {
				margin: 1vw auto;
			}

			h1 {
				text-align: center;
			}

			&.disabled {
				pointer-events: none;
				progressbar,
				label#level {
					display: none;
				}
			}

			.amount_container {
				width: 90%;
				display: flex;

				label#amount {
					margin: auto;
				}
			}

			progressbar#exp_bar {
				.progressbar-style-ref-0();

				--progress: 0%;
				width: 59%;
				height: 7vw;
				text-shadow: 0 2px 2px #fff;
				font-size: 5vw;
				font-weight: 500;
				position: relative;
				top: 1vw;

				&::before {
					background-color: #3eb953;
					box-shadow: 0px 2px #066f2b;
					border-radius: 4px;
					border-bottom: solid 1px #ffffff90;
				}
			}
			
			label#amount {
				.decor-5ka-red-gradient-effect();
				padding: 2px 0.5em;
				border-radius: 4px;
				color: white;
				text-shadow: 0px 2px 1px #00000090;
				border-top: solid 1px #ffffff90;
				font-weight: 600;
				font-size: 4vw;
				position: absolute;
				top: 20px;
				left: 5px;
				height: min-content;
			}
			label#level {
				.decor-5ka-green-gradient-effect();
				padding: 2px 0.5em;
				border-radius: 4px;
				color: white;
				text-shadow: 0px 2px 1px #00000090;
				border-top: solid 1px #ffffff90;
				height: min-content;
				font-weight: 600;
				font-size: 4vw;
			}
			label#level {
				position: absolute;
				top: -10px;
				left: 5px;
			}
			label#name {
				margin-top: -6vw;
				font-size: 4.5vw;
				font-weight: 400;
				text-align: center;
				padding: 4px;
			}
			icon {
				display: flex;
				margin: auto;
				margin-bottom: -1vw;
				justify-content: center;

				&.disabled {
					filter: grayscale(1);
				}
			}
			button {
				font-size: 7vw;
				padding: 3px;
				margin-bottom: -16px;
			}

			button.buy {
				position: absolute;
				margin-top: 48.5vw;
				align-self: center;
				display: flex;
				justify-content: center;
				font-size: 7vw;
				
				.decor-5ka-blue-gradient-effect-with-hover();
				a {
					margin-left: 0%;
					min-width: 30px;
					text-align: left;
					z-index: 1;
				}
				&::before {
					z-index: 0;
					content: '';
					margin: auto;
					margin-left: 0px;
					margin-right: 0px;

					.textures-icon-gem-36px();
					.sprite-textures();
					/*
					top: 50%;
					right: -20px;
					transform: translate(-100%, -50%) scale(0.9);
					position: absolute;
					*/
				}

				button#upgrade_relic {
					top: -10vw;
				}
			}
		}

		// hover animation
		entry {
			transition-duration: 200ms;
		}
		entry:active {
			transform: scale(0.75);
			box-shadow: 0px 7px 7px rgba(128, 128, 128, 0.466);
		}
			
		entry.item_rarity {
			&.default {
				background: linear-gradient(#adadad45 0%,#d8d8d83d 100%);
			}
	
			&.common {
				background: linear-gradient(#9b9b9b45 0%,#cacaca3d 100%);
			}
	
			&.uncommon {
				background: linear-gradient(#57619c45 0%,#5985ff3d 100%);
			}
	
			&.rare {
				background: linear-gradient(#9c925745 0%,#fff4593d 100%);
			}
	
			&.unique {
				background: linear-gradient(#81579c45 0%,#af59ff3d 100%);
			}
		}
	}
}

page#shop {
	tabs#content {
		entry {
			margin-bottom: 5vw;

			//	label.rarity {
			//		top: 1vw
			//	}

			.shop_entry_label {
				margin: auto;
				top: 40vw;
				position: absolute;
				place-self: center;
				pointer-events: none;
			}

			icon {
				//transform: translateY(-10vw) scale(.9);
				//margin-bottom: -13vw;
				margin: 0;
			}
		}
	}

	tabs {
		margin-bottom: -3vw;
	}

	.shop_entry_icon_wrapper {
		margin: 0;
		width: 100%;
		height: 80%;
		display: flex; 
		justify-content: center;
		align-items: center;
		pointer-events: none;
	}
}

page#inventory,
page#itd_reward,
page#itd_room_details {
	entry {
		& * {
			pointer-events: none;
		}
	}

	progressbar:extend(.progressbar-style-ref-0, .progressbar-color-green) {
		width: 59%;
		height: 7vw;
		position: relative;
		top: 1vw;
		text-shadow: 0px 2px 2px #fff;
		font-size: 5vw;
		font-weight: 500;
	}
}

page#itd_reset {
	color: var(--color-5ka-white);
	button {
		.decor-itd-0();
		margin: auto;
		font-size: 2em;
		font-weight: 600;
	}
	
	button#confirm {
		font-size: 4vw;
	}

	label#description {
		font-size: 8vw;
		font-weight: 600;
		color: #ab2b4f;
		display: flex;
		align-items: center;
		margin: auto;

		&::before {
			content: '';
			margin: auto;
			margin-right: -2vw;
			margin-left: -5vw;
			.textures-icon-gem();
			.sprite-textures();
			transform: scale(0.5);
		}
	}

	background#boss {
		background-image: url('./res/img/boss-background.png');
		width: 100%;
		height: 340px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
	}
}

page#message {
	container.flex-simple {
		display: flex;
		flex-direction: column;
		& > * {
			margin: auto;
		}
	}
}

page#message,
page#shop,
page#inventory,
page#settings,
page#itd_reset,
page#itd_room_details,
page#itd_employee_details,
page#itd_battle_prepare,
page#itd_pvp_prepare,
page#itd_pvp_results,
page#itd_reward,
page#hint_pvp,
page#hint_battle,
page#hint_inventory,
page#hint_shop,
page#hint_hello,
page#hint_reset {
	padding: 0.5em;
	padding-top: 2em;
	height: 100%;
	width: 100%;
	font-size: 5vw;
	display: flex;
	flex-direction: column;

	text.container {
		align-self: center;
		padding: 20px;
	}
}

page#settings {
	container {
		margin: auto;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		gap: 1em;

		button#sound {
			&::before {
				.textures-icon-sound();
			}

			&.muted::before {
				margin-left: -12px;
				.textures-icon-sound-muted();
			}
			margin: auto;
		}

		button#music {
			&::before {
				margin-left: -3px;
				.textures-icon-music();
			}

			&.muted::before {
				margin-left: 0px;
				.textures-icon-music-muted();
			}
			margin: auto;
		}

		button#help {
			&::before {
				content: '?';
				background-image: none;
				margin: auto;
				position: absolute;
				font-size: 6rem;
			}
			.decor-5ka-blue-gradient-effect-with-hover();
			padding: 0;
			font-size: 400%;
			margin: auto;
		}

		&::before, &::after {
			content: '';
			width: 100%;
			order: 1;
		}

		button {
			&::before {
				.sprite-textures();
				content: '';
				margin: auto;
				transform: translate(-50%, -50%) scale(0.8);
				position: absolute;
			}

			width: 80px;
			height: 80px;
			display: block;

			.decor-itd-0();
		}

		div#meta_info {
			position: relative;
			bottom: -14vw;
			font-weight: 400;
			font-size: 3vw;
			z-index: 1;
			display: grid;
			justify-items: center;
			min-width: 100%;
		}
	}
}

page#journal {
	padding: 30px 10px 30px 10px;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	gap: 5;

	container#journal {
		overflow-y: scroll;
		margin-top: 6vw;
		margin-bottom: -5vw;
	}

	itd5ka.journal_entry {
		justify-content: space-between;
		display: flex;

		background-color: white;
		border-radius: 5px;
		box-shadow: 0px 2px 2px 0px #00000010;
		border-top: 10px solid var(--color-5ka-b);
		margin-bottom: 2vw;

		pointer-events: none;

		&.completed {
			pointer-events: all;
			& * {
				pointer-events: none;
			}
			border-top: 10px solid var(--color-5ka-c);
		}

		&.collected {
			opacity: 0.7;
			border-top: 10px solid gray;
		}

		& > * {
			//margin: 5px 0px 5px 0px;
		}

		icon#jentry {
			.sprite-textures();
			transform: scale(.7);
			padding: 0px;
			margin-left: -3vw;
		}

		#labels {
			display: inline-flex;
			flex-direction: column;
			max-width: 50%;
			align-items: start;
			margin: 5vw auto 5vw 0;
			transform: scale(1.2);
			padding-right: 4vw;

			label {
				margin: auto 0px;
				font-weight: 600;
				font-size: 2.5vw;
			}

			#description {
				margin: auto;
				font-size: 2.6vw;
				font-weight: 300;
			}
		}

		&.completed icon#jentry_status::before {
			.textures-icon-star();
		}

		&.collected icon#jentry_status::before {
			.textures-icon-checkmark();
		}

		icon#jentry_status {
			margin: auto;

			--size: 30px;
			width: 10vw;
			height: 10vw;
			border: 2px solid #00000020;
			border-radius: 4px;

			display: block;
			position: relative;
			transform: scale(0.8);

			&::before {
				content: '';
				.sprite-textures();
				transform: translate(-50%, -50%);
				top: 50%;
				left: 50%;
				position: absolute;
			}
		}
	}
}

page#itd_employee_details,
page#inventory,
page#itd_reward,
page#itd_room_details,
page#shop {
	entry.rarity,
	label.rarity {
		color: var(--color-5ka-white);
		border: 0;
		font-size: 3.5vw;
		margin-bottom: 1vw;
		text-shadow: -2px -1px 4px #00000087, 1px -2px 4px #00000087, -2px 2px 4px #00000087, 2px 2px 4px #00000087;

		&.default {
			.decor-5ka-gold-gradient-effect();
			filter: grayscale(1);
		}

		&.common {
			.decor-5ka-green-gradient-effect();
			filter: grayscale(1);
		}

		&.uncommon {
			.decor-5ka-blue-gradient-effect();
		}

		&.rare {
			.decor-5ka-gold-gradient-effect();
		}

		&.unique {
			.decor-5ka-blue-gradient-effect();
			filter: hue-rotate(60deg);
		}
	}

	label.rarity {
		border-radius: 0 0 10px 10px;
		padding: 2vw;
		padding-left: 2vw;
		padding-right: 2vw;
		position: relative;
		bottom: -0.5vw;
		align-self: center;
		text-align: center;
		width: 100%;
		font-weight: 500;
	}
}

page#itd_employee_details {
	entry.rarity {
		text-align: center;
		border-radius: 3px;
		margin: auto;
		padding: 0.5vw 2vw;
		font-weight: 300;
	}
}

page#itd_employee_details {
	container {
		display: grid;
		height: 100%;

		grid-template:
        "icon description description" 60%
        "params params params" 30%
        "labels labels labels" / 34% 56%;

		gap: 5px;
		padding: 0px;
	}

	.icon_container {
		grid-area: icon;
		display: flex;
		margin-top: 5vw;
		flex-direction: column;
		.icon {
			align-self: center;
			transform: scale(0.9);
			icon {
				margin: auto;
				.sprite-textures();
				z-index: 1;
			}
		}
	}

	description {
		grid-area: description;
		border-radius: var(--radius-border-a);
		display: flex;
		flex-direction: column;
		font-size: 3vw;
		margin-top: 5vw;

		label#name {
			color: var(--color-5ka-white);
			padding: 4px;

			background-color: var(--color-5ka-b);
			border-radius: 5px 5px 0 0;
			text-shadow: 0px 2px 1px #00000090;
			font-weight: 500;
		}

		text#employee_description {
			overflow-wrap: break-word;
			background-color: #eeffee;
			height: 100%;
			padding: 4px;
			border-radius: 0 0 5px 5px;
			font-weight: 300;
		}
	}

	stats {
		top: -1vw;
		position: relative;
		text-align: center;

		entry {
			text-align: center;
			border-radius: 3px;
			background-color: #eeeeee;
			font-size: 3vw;
			margin: auto;
			display: flow-root;
		}

		progressbar {
			.progressbar-style-ref-0();

			--progress: 0%;
			margin: auto;
			margin-top: 1vw;
			font-size: 4vw;
			font-weight: 500;

			&#level {
				&.expirience {
					.progressbar-color-green();
				}
				//.progressbar-color-green();
			}

			&#energy {
				&.health {
					.progressbar-color-red();
				}
				&.charges {
					.progressbar-color-blue-white-text();
				}
			}

			&#level,
			&#energy {
				&::before {
					border-radius: 4px;
				}

				&::after {
					font-size: 3vw;
					width: 50px;
					left: -36px;

					position: absolute;
					padding: 3px;
					border-radius: 3px;
				}
			}
		}
	}

	params {
		grid-area: params;
		display: flex;
		flex-direction: column;
		position: relative;

		label {
			background-color: gray;
			color: white;
			padding: 3px 5px;
			font-weight: 500;
			font-size: 3vw;

			border-radius: 5px 5px 0 0;
			text-shadow: 0px 2px 1px #00000090;
		}

		pcontainer {
			font-size: 3vw;
			height: 74%;
			border-radius: 0 0 5px 5px;
			padding: 2px;
			font-weight: 300;
			background-color: #eeeeee;

			badges {
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;

				entry {
					display: block;
					width: 8vw;
					height: 8vw;
					background-color: #98b29b;
					border-radius: 100%;
					margin-top: 1vw;
					margin-left: 5vw;

					&::before {
						content: '';
						.sprite-textures();
						transform: translate(-50%, -50%) scale(0.5);
						margin-top: 50%;
						margin-left: 50%;
					}

					&.profit_bonus {
						&::before {
							.textures-bonus_etazh();
						}
					}

					&.upgrade_bonus {
						&::before {
							.textures-bonus_sale();
						}
					}

					&.threshold_bonus {
						&::before {
							.textures-bonus_money();
						}
					}

					&.click_bonus {
						&::before {
							.textures-attack();
						}
					}

					&.heal_bonus {
						&::before {
							.textures-heal();
							transform: translate(-50%, -50%) scale(0.1);
						}
					}
				}
			}

			#entries {
				position: absolute;
				top: 6vw;
				left: 27vw;
			}
	
			li {
			}
		}
	}

	labels {
		grid-area: labels;
		display: flex;
		flex-direction: row;
		gap: 5px;
		font-weight: 500;
		position: relative;
		margin-top: -2vw;
		margin-bottom: auto;

		entry {
			text-align: center;
			padding: 3px;
			padding-top: 7px;
			font-size: 3vw;
			width: 30%;
			height: 9vw;
			position: relative;
			top: 0vw;
			left: 0vw;
			white-space: nowrap;
			box-shadow: 0px -1px inset #ffffff90, 0px 4px #838383;
			margin-left: 0;
		}

		button {
			background-color: var(--color-5ka-a);
			--button-shade: #660f0a;
			color: var(--color-5ka-white);
			text-shadow: 0px 2px 1px #00000090;
			text-align: center;
			box-shadow: 0px -1px inset #ffffff90,0px 4px var(--button-shade);
			border-radius: 5px;
			padding: 4px;
			font-size: 3vw;
			margin-right: 0;
			margin-left: auto;
			width: 35%;

			.button-anim-opt-0();
		}
			
		button#assign_btn {
			width: 35%;			
		}
	}
}

/*
page#itd_room_details {
	container#assigned {
		display: flex;
		justify-content: center;
		border-bottom: dashed 2px #00000050;

		entry {
			width: 200px;
			height: 100px;
			display: flex;
			flex-direction: column;

			label {
				width: 100%;
				text-align: center;
				display: block;
			}

			icon {
				margin: auto;
			}
		}
	}
}
*/

page#itd_battle_prepare {
	#start_btn {
		max-width: max-content;
		margin: auto;
		margin-bottom: 10vw;
		font-size: 6vw;
		padding: 10px;
	}
	container {
		margin: 40px 10px 10px 10px;
		display: flex;
		position: relative;
		justify-content: center;
		align-self: center;

		//border: 2px solid #00000020;
		background-color: #00000020;
		//border-radius: 7px;
		padding-left: 10px;
		padding-right: 10px;
		position: relative;

		&::after {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			//background: linear-gradient(90deg, #38ff00 0%, #ff6000 50%, #ff0000 100%);
			background-repeat: no-repeat;
			//mix-blend-mode: hue;
			pointer-events: none;
		}

		.task_icon {
			display: flex;
			cursor: pointer;
			transform: scale(0.35);
			width: 128px;
			margin-top: -5vw;
			margin-bottom: -5vw;
			margin-right: -45px;
			margin-left: -41px;
			justify-content: center;
			backface-visibility: hidden;

			& > * {
				pointer-events: none;
			}

			.task_icon_image {
				align-self: center;
			}

			&.available {
				.task_icon_image {
					.sprite-textures();
					.textures-support_1-red();
				}
			}

			&.available:nth-child(n + 4) {
				.task_icon_image {
					.sprite-textures();
					.textures-task_1-h126px-red();
				}
			}

			&.unavailable {
				.task_icon_image {
					.sprite-textures();
					.textures-lock_closed-mq-blue();
				}
				pointer-events: none;
			}
			&.deselected {
				opacity: 0.5;
				transform: scale(0.35);
				filter: grayscale(1);
				transition-duration: 100ms;

				p {
					font-weight: 300;
				}
			}

			p {
				margin: auto;
				margin-bottom: -0.2em;
				margin-right: 0;
				font-size: 3em;
				font-weight: 600;
				color: #ffb1b1;
				pointer-events: none;
				width: 128px;
				text-shadow: 1px 1px 0px #0009, 0px -1px 0px #feef, -1px -0px 0px #feea;
			}

			transition-duration: 100ms;
			&:active {
				transform: translateX(-3vw) scale(calc(var(--scale-js) * 1.4));
			}
		}
	}
}

page#itd_pvp_prepare,
page#itd_battle_prepare,
page#shop,
page#inventory,
page#itd_reset {
	button#hint {
		.decor-5ka-blue-gradient-effect-with-hover();
		position: absolute;
		width: 50px;
		height: 50px;
		font-size: 30px;
		padding: 0;
		top: -20px;
		left: -20px;
		z-index: 1;
	}
}

.hint-page {
	p {
		padding-top: 0.5em;
		font-size: 1.2em;
		font-weight: 300;
	}

	container {
		overflow: scroll;
		padding: 10px;
		margin-top: 6vw;
		font-size: 3vw;
	}

	strong {
		font-weight: bolder;
	}

	em {
		font-style: italic;
	}

	h1 {
		padding-top: 0.5em;
		font-size: 2em;
		text-align: center;
	}

	h2 {
		padding-top: 0.5em;
		font-size: 1.5em;
		text-align: center;
	}
}

page#itd_pvp_prepare,
page#itd_pvp_results {
	container {
		margin: auto;
		display: flex;
		flex-direction: column;

		& > * {
			margin: auto;
		}

		label#player_rating {
			font-size: 1em;
		}

		icon#player_rank,
		icon#pvp_reward {
			.sprite-textures();
		}

		icon#player_rank {
			overflow: visible;
		}

		*[hover-label]:after {
			white-space: break-spaces;
			width: fit-content;
			position: relative;
			font-size: 4vw;
		}

		icon#pvp_reward {
			transform: scale(0.7);
		}

		button#open_reward {
			font-size: 1.5em;
		}

		decor#searching {
			display: flex;
			flex-direction: column;
			opacity: 0;

			& > * {
				margin: auto;
			}

			label {
				font-size: 0.7em;
			}
		}
		
		label#pvp_start_warn {
			font-size: 0.7em;
			height: 20px;
		}

		button#start_btn {
			font-size: 4vw;
			padding: 10px;
		}
	}

	&.searching container {
		decor#searching {
			opacity: 1;
		}

		button#start_btn {
			pointer-events: none;
			filter: grayscale(1);
		}
	}

	/* From cssbuttons.io by @gagan-gv */
	.loader {
		border: 0 solid transparent;
		border-radius: 50%;
		width: 100px;
		height: 100px;
	}

	.loader::before,
	.loader::after {
		content: '';
		border: 7px solid #ccc;
		border-radius: 50%;
		width: inherit;
		height: inherit;
		position: absolute;
		animation: loader 2s linear infinite;
		opacity: 0;
	}

	.loader::before {
		animation-delay: 1s;
	}

	@keyframes loader {
		0% {
			transform: scale(0);
			opacity: 0;
		}

		50% {
			opacity: 1;
		}

		100% {
			transform: scale(1);
			opacity: 0;
		}
	}
}

page#itd_pvp_results {
	font-weight: 600;
	container {
		margin: auto;
		display: flex;
		flex-direction: column;

		& > * {
			margin: auto;
			margin-top: 2vw;
		}

		label#player_rating {
			font-size: 4vw;
			margin-top: 0;
		}

		label#pvp_result {
			text-align: center;
		}

		icon#player_rank,
		icon#pvp_reward {
			.sprite-textures();
			overflow: visible;
			font-weight: 400;
			font-size: 5vw;
			transform: scale(0.8);
		}

		icon#pvp_reward {
			transform: scale(0.7);
			margin: auto;
			padding: 5px 15px;
		}

		button#open_reward {
			font-size: 5vw;
		}
	}
}

popup#message container.battle-results {
	display: flex;
	flex-direction: column;
	& > * {
		margin: auto;
	}

	div.battle-reward-container {
		display: flex;
		gap: 1vw;
		position: relative;
		margin-left: auto;
		min-height: 12rem;
	}

	icon.gems-icon {
		margin-top: 18px;
		display: flex;
		position: relative;
		overflow: visible;
		transform: scale(0.85);
	}
	icon.lootpack-icon {
		display: flex;
		position: relative;
		overflow: visible;
		transform: scale(.65);
	}

	label.gems {
		color: #ff2777;
		text-shadow: 0 1px #000, 0px -2px 1px #ffb6b6;
		position: absolute;
		right: -1.5rem;
		bottom: -0.5rem;
		font-size: 2rem;
		font-weight: 600;
	}

	label.duration {
		padding-top: 5vw;
		font-size: 6vw;
		font-weight: 700;
		margin-bottom: 8vw;
	}

	label.header {
		font-size: 4vw;
	}

	button#open_reward {
		font-size: 5vw;
	}
}

popup#message container.battle-warn-message {
	font-size: 4vw;
    position: relative;
    top: 5vw;

	li:before {
		content: '⨯ ';
		color: red;
	}
	li[checked]:before {
		content: '✓ ';
		color: green;
	}
	li[checked] {
		text-decoration: line-through;
		opacity: 0.5;
	}
}

page#itd_reward {
	div#lootpack {
		transform: translateY(30%) scale(0.45);
		icon#lootpack {
			margin: auto;
			.sprite-textures();
		}
	}

	tabs#content {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	&.state-0 {
		tabs#content,
		#claim_reward {
			display: none;
		}

		div#lootpack {
			transform: translateY(30%) scale(0.45);
			align-self: center;
			icon#lootpack {
				//.textures-lootpack-7();
			}
		}
	}

	&.state-1 {
		tabs#content,
		#claim_reward {
			display: none;
		}

		div#lootpack {
			transform: translateY(30%) scale(0.45);
			align-self: center;
			icon#lootpack {
				//.textures-lootpack-7-open();
				.anim-popup-appear-0();
				//.anim-shine-0();
				overflow: visible;
			}
		}
	}

	&.state-2 {
		div#lootpack {
			transform: translateY(30%) scale(0.45);
			align-self: center;			
			icon#lootpack {
				// display: none;
			}
		}

		--delay: 0s;

		entry {
			animation: appear 0.2s ease-out;
			animation-fill-mode: backwards;
			animation-delay: var(--delay);
			pointer-events: none;
		}

		#claim_reward {
			min-width: 30vw;
			height: 13vw;
			margin: auto;
			margin-bottom: 3vw;
			padding-left: 5vw;
			padding-right: 5vw;
			font-size: 6vw;
			position: relative;
		}
	}

	@keyframes appear {
		0% {
			transform: translateY(30px);
			opacity: 0;
		}

		100% {
			transform: translateX(0px);
			opacity: 1;
		}
	}
}
