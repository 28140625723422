@import './generic.less';

itd5ka {
	decor#game_background {
		position: absolute;
		width: 100%;
		height: 100%;

		&.style-default {
			background-color: #80dcf3;
			//background-image: url('./res/img/bg1_loop.jpg');
			background-position: bottom;
		}

		.background_tile_move {
			background-position: 0px;
			//animation: slide 400s linear infinite;

			@keyframes slide {
				from {
					background-position: 0px;
				}

				to {
					background-position: 3776px;
				}
			}
		}

		.background_tile_move();
		.style-default();

		&.color-0 {
			filter: hue-rotate(0deg);
		}

		&.color-1 {
			filter: hue-rotate(-40deg);
		}

		&.color-2 {
			filter: hue-rotate(220deg);
		}

		&.color-3 {
			filter: hue-rotate(180deg);
		}

		&.color-4 {
			filter: hue-rotate(50deg);
		}

		&.style-shop {
			background-color: white;
			background-image: url('./res/img/icons/shopping.png');
			background-size: 100px;
			.background_tile_move();
		}

		&.style-employees {
			background-color: white;
			background-image: url('./res/img/icons/pawn.png');
			background-size: 100px;
			.background_tile_move();
		}

		&.style-star {
			background-color: white;
			background-image: url('./res/img/icons/star.png');
			background-size: 100px;
			.background_tile_move();
		}

		&.style-pvp {
			background-color: white;
			background-image: url('./res/img/icons/crown.png');
			background-size: 100px;
			.background_tile_move();
		}
	}

	decor#temporal_elements {
		z-index: 1;
		position: absolute;
		width: 100%;
		height: 100%;

		damage_numbers {
			pointer-events: none;
			position: absolute;
			color: black;
			font-size: 10vw;
			font-weight: 600;
			animation: damage_numbers_fade 0.7s linear;
			animation-fill-mode: forwards;
			-webkit-text-stroke: 1.5px #fff573;
		}
		damage_numbers.click {
			color: red;
		}
		damage_numbers.task {
			color: magenta;
		}
		damage_numbers.employer {
			color: yellow;
			-webkit-text-stroke: 1.5px red;
		}

		@keyframes damage_numbers_fade {
			0% {
				opacity: 1;
				transform: translateY(0px) scale(0.5);
			}

			10% {
				transform: translateY(-20px) scale(1.5);
			}

			40% {
				transform: translateY(-40px) scale(1);
			}

			80% {
				opacity: 0;
			}

			100% {
				opacity: 0;
				transform: translateY(-150px);
			}
		}

		area_effect {
			position: absolute;

			//heal,attack,poison,boost,slowdown,freeze
			&.heal {
				--color: rgb(19, 255, 157);
				&::before {
					.textures-heal();
				}
			}

			&.attack {
				--color: #f00;
				&::before {
					.textures-attack();
				}
			}

			&.poison {
				--color: #000;
				&::before {
					.textures-poison();
				}
			}

			&.boost {
				--color: #44f;
				&::before {
					.textures-speed();
				}
			}

			&.slowdown {
				--color: #000;
				&::before {
					.textures-slowdown();
				}
			}

			&.freeze {
				--color: #2df;
				&::before {
					.textures-freeze();
				}
			}

			&.notification {
				--color: #fb0;
			}

			&::before {
				content: '';
				.sprite-textures();
				margin-top: 50%;
				margin-left: 50%;
				transform: translate(-50%, -50%);
				animation: area_effect_icon 1.5s ease-in;
				animation-fill-mode: forwards;
				position: absolute;
			}

			&::after {
				content: '';
				width: 50%;
				height: 50%;
				margin: auto;
				border: 7px solid var(--color);
				box-shadow: inset 0px 0px 10px 5px var(--color);
				display: block;
				border-radius: 100%;
				animation: area_effect_secondary 1.5s ease-in;
			}

			display: flex;
			border-radius: 100%;
			//box-shadow: inset 0px 0px 10px 5px var(--color);
			border: 7px solid var(--color);
			animation: area_effect 1.5s ease-in;
			animation-fill-mode: forwards;
			transform: translate(-50%, -50%);
			transform-origin: center;
			width: 100px;
			height: 100px;
		}

		@keyframes area_effect_secondary {
			0% {
				width: 50%;
				height: 50%;
			}

			50% {
				width: 100%;
				height: 100%;
			}
		}

		@keyframes area_effect_icon {
			0% {
				transform: translate(-50%, -50%) scale(0.2);
			}

			30% {
				transform: translate(-50%, -50%) scale(0.3);
				opacity: 1;
			}

			50% {
				transform: translate(-50%, -50%) scale(0.4);
			}

			70% {
				opacity: 0;
			}

			100% {
				opacity: 0;
			}
		}

		@keyframes area_effect {
			0% {
				opacity: 0;
				transform: translate(-50%, -50%) scale(0.3);
				width: 0;
				height: 0;
			}

			20% {
				opacity: 1;
			}

			30% {
				transform: translate(-50%, -50%) scale(0.5);
			}

			40% {
				opacity: 1;
			}

			100% {
				width: 500px;
				height: 500px;
				opacity: 0;
			}
		}
	}

	decor#menu_background {
		box-shadow: inset 0px 7px #fff2;

		height: @size-grid-0 * 1.8;
		width: 100%;
		position: absolute;
		bottom: 0;
		background-color: var(--color-5ka-b);
	}

	decor#header_background {
		box-shadow: inset 0px -7px #0002;

		height: calc(@size-grid-0 * 2.1 * calc(var(--scale) + 0.2) * var(--iphone-header));
		width: 100%;
		position: absolute;
		top: calc(0vw - (var(--iphone-header) - 1) * 4vw);
		background-color: var(--color-5ka-b);
	}

	decor#game_foreground {
		z-index: 30;
		position: absolute;
		width: 0%;
		right: 0;
		height: 100%;
		background: linear-gradient(0deg, #55e04d, #ebf3d5 200%);
		backface-visibility: hidden;

		&::after {
			content: '';
			background-image: url('./res/img/stamp-256px-tiled.png');
			background-size: 256px;
			width: 100%;
			height: 100%;
			display: block;
			mix-blend-mode: lighten;
		}

		&.animate-forwards {
			z-index: 30;
			animation: animate 1s forwards ease-in-out;
			width: 0%;
			right: 100%;
		}
		&.animate-backwards {
			z-index: 30;
			animation: animate 1s reverse ease-in-out;
			width: 0%;
			right: 0;
		}

		@keyframes animate {
			0% {
				width: 0%;
				right: 0;
			}
			50% {
				width: 100%;
				right: 0%;
			}
			100% {
				width: 0%;
				right: 100%;
			}
		}
	}

	decor#itd5ka_paper {
		position: absolute;
		z-index: 10;
		display: flex;
		height: 70vh;
		width: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		animation: paper_appear 1s ease-out;

		background {
			background-image: url('./res/img/paper.png');
			background-repeat: no-repeat;
			background-position: center;
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: -1;
			background-size: contain;
		}

		label {
			margin: auto;
			margin-top: 35vw;
			font-weight: 300;
			font-style: italic;
			overflow: clip;
			animation: label_appear 1s ease-in;
			height: 100%;
			animation-fill-mode: backwards;
			animation-delay: 1s;
			width: 30vh;
			font-size: 3vw;//~'min(2vh, 1em)';
		}

		#reward_amount {
			color: #ab2b4f;
			font-weight: bolder;
		}

		icon#stamp {
			.sprite-textures();
			.textures-stamp();
			position: absolute;
			left: calc(50% - 34vw);
			bottom: 30vw;
			transform: scale(0.6);
			animation: stamp_appear 0.5s ease-in;
			animation-delay: 2s;
			animation-fill-mode: backwards;
		}

		icon#sign {
			.sprite-textures();
			.textures-sign();
			position: absolute;
			left: calc(50% + 10vw);
			bottom: 40vw;
			transform: scale(0.8);
			animation: sign_appear 1s ease-out;
			transform-origin: left;
			animation-delay: 2.5s;
			animation-fill-mode: backwards;
		}

		@keyframes paper_appear {
			0% {
				transform: translate(-100%, -10%) rotate(-80deg) scale(1.5);
			}
			35% {
				transform: translate(-52%, -43%) rotate(-5deg) scale(1);
			}
		}

		@keyframes label_appear {
			0% {
				height: 0px;
			}
		}

		@keyframes stamp_appear {
			0% {
				opacity: 0;
				transform: scale(1.6);
			}
			100% {
				transform: scale(0.6);
			}
		}

		@keyframes sign_appear {
			0% {
				width: 0px;
			}
		}
	}

	// @media screen and (max-width: 1030px) {
	// 	itd5ka#psources.container {
	// 		--width: 90vw;
	// 	}
	// }
}
