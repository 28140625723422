.noselect {
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.noselect_webkit {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.div_background_image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

:root {
  --color-5ka-a-ref: #eb2316;
  --color-5ka-b-ref: #008f32;
  --color-5ka-c-ref: #f08b0b;
  --color-5ka-a-brume: #a0cf06;
  --color-5ka-b-brume: #2c2c2c;
  --color-5ka-c-brume: #ff7a00;
  --color-5ka-a: var(--color-5ka-a-ref);
  --color-5ka-b: var(--color-5ka-b-ref);
  --color-5ka-c: var(--color-5ka-c-ref);
  --color-5ka-white: #fff;
  --scale-hud-a: 1;
  --radius-border-a: 20px;
  --font-size-absolute: 24px;
  --font-size: 24px;
}

@font-face {
  font-family: "5ka Sans";
  src: url("./res/fonts/5kaSansDesign-Thin.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "5ka Sans";
  src: url("./res/fonts/5kaSansDesign-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "5ka Sans";
  src: url("./res/fonts/5kaSansDesign-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "5ka Sans";
  src: url("./res/fonts/5kaSansDesign-SemiBold.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "5ka Sans";
  src: url("./res/fonts/5kaSansDesign-Ultra.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

body {
  pointer-events: none;
}

itd5ka#main.hidden, itd5ka#minified.hidden {
  display: none !important;
}

itd5ka#main.container {
  width: 100%;
  height: 100%;
  pointer-events: none;
  font-size: var(--font-size);
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  position: absolute;
  overflow: clip;
  font-family: "5ka Sans" !important;
}

itd5ka#main.container .hidden {
  display: none !important;
}

itd5ka#main.container .highlighted {
  filter: brightness(1.5);
  box-shadow: 0px 0px 0px 5px var(--color-5ka-white);
}

itd5ka#main.container .template {
  border: 4px dashed #00f;
  display: none !important;
}

itd5ka#main.container decor#psource-info-icon.container {
  z-index: 2;
}

itd5ka#main.container decor#psource-info-profit.container {
  z-index: 1;
}

itd5ka#main.container decor#psource-info-profit.container progressbar#psource-progress, itd5ka#main.container decor#psource-info-profit.container progressbar#psource-progress:after {
  z-index: -1;
}

itd5ka#main.container cutscene#game_cutscene.container {
  z-index: 9;
}

itd5ka#main.container itd5ka#header.container, itd5ka#main.container itd5ka#menu.container, itd5ka#main.container itd5ka#pvp_progress_span {
  z-index: 1;
}

itd5ka#main.container popup.container:before {
  z-index: -1;
}

itd5ka#main.container itd5ka#psources.container {
  z-index: -2;
}

itd5ka#main.container decor#game_background {
  z-index: -10;
}

.sprite-effects {
  background-image: url("./dust/res/img/effects.png");
  background-repeat: no-repeat;
  display: inline-block;
  overflow: hidden;
}

.effects-gym-sign-light {
  width: 491px;
  height: 320px;
  background-position: -1px -1px;
}

.effects-hr-screen-a {
  width: 96px;
  height: 67px;
  background-position: -341px -323px;
}

.effects-kg-screen {
  width: 262px;
  height: 208px;
  background-position: -1px -597px;
}

.effects-reception_1-lamp-a {
  width: 338px;
  height: 272px;
  background-position: -1px -323px;
}

.effects-reception_1-lamp {
  width: 240px;
  height: 240px;
  background-position: -265px -597px;
}

.effects-reception_1-screen {
  width: 240px;
  height: 146px;
  background-position: -249px -990px;
}

.effects-reception_1-tv-disabled {
  width: 246px;
  height: 149px;
  background-position: -1px -807px;
}

.effects-reception_1-tv-logo {
  width: 246px;
  height: 149px;
  background-position: -249px -839px;
}

.effects-reception_1-tv {
  width: 246px;
  height: 149px;
  background-position: -1px -958px;
}

.sprite-effects {
  transform-origin: center;
  position: absolute;
}

.reception_1 .effect_lamp {
  transform-origin: center;
  width: 240px;
  height: 240px;
  background-image: url("./dust/res/img/effects.png");
  background-position: -265px -597px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 450px;
  left: 290px;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(1.05);
}

.reception_1 .effect_lamp2 {
  transform-origin: center;
  width: 338px;
  height: 272px;
  background-image: url("./dust/res/img/effects.png");
  background-position: -1px -323px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 487px;
  left: 315px;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(1.05);
}

.reception_1 .tv {
  transform-origin: center;
  width: 246px;
  height: 149px;
  background-image: url("./dust/res/img/effects.png");
  background-position: -249px -839px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 140px;
  left: 1115px;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(1.05);
}

.reception_1 .screen {
  transform-origin: center;
  width: 240px;
  height: 146px;
  background-image: url("./dust/res/img/effects.png");
  background-position: -249px -990px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 240px;
  left: 875px;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(1.05);
}

.reception_5 .effect_lamp2 {
  transform-origin: center;
  width: 240px;
  height: 240px;
  background-image: url("./dust/res/img/effects.png");
  background-position: -265px -597px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 547px;
  left: 425px;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(.95, .9);
}

.reception_5 .effect_lamp3 {
  transform-origin: center;
  width: 240px;
  height: 240px;
  background-image: url("./dust/res/img/effects.png");
  background-position: -265px -597px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 547px;
  left: 1205px;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(.95, .9);
}

.hr .screen2 {
  transform-origin: center;
  width: 96px;
  height: 67px;
  background-image: url("./dust/res/img/effects.png");
  background-position: -341px -323px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 492px;
  left: 867px;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(1.05);
}

.kabinet_glavnogo .screen3 {
  transform-origin: center;
  width: 262px;
  height: 208px;
  background-image: url("./dust/res/img/effects.png");
  background-position: -1px -597px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 220px;
  left: 860px;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(1.05);
}

.kafe .effect_lamp {
  transform-origin: center;
  width: 240px;
  height: 240px;
  background-image: url("./dust/res/img/effects.png");
  background-position: -265px -597px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 463px;
  left: 375px;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(1.4);
}

.kafe .effect_lamp2 {
  transform-origin: center;
  width: 240px;
  height: 240px;
  background-image: url("./dust/res/img/effects.png");
  background-position: -265px -597px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 470px;
  left: 754px;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(1.4);
}

.kafe .effect_lamp3 {
  transform-origin: center;
  width: 240px;
  height: 240px;
  background-image: url("./dust/res/img/effects.png");
  background-position: -265px -597px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 460px;
  left: 1280px;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(1.4);
}

.kafe .tv {
  transform-origin: center;
  width: 246px;
  height: 149px;
  background-image: url("./dust/res/img/effects.png");
  background-position: -1px -958px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 125px;
  left: 610px;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(1.05);
}

.kafe .tv2 {
  transform-origin: center;
  width: 246px;
  height: 149px;
  background-image: url("./dust/res/img/effects.png");
  background-position: -1px -958px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 125px;
  left: 1130px;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(1.05);
}

.sklad .effect_lamp {
  transform-origin: center;
  width: 240px;
  height: 240px;
  background-image: url("./dust/res/img/effects.png");
  background-position: -265px -597px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 185px;
  left: 275px;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(1.6, 1);
}

.sklad .effect_lamp2 {
  transform-origin: center;
  width: 240px;
  height: 240px;
  background-image: url("./dust/res/img/effects.png");
  background-position: -265px -597px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 215px;
  left: 820px;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(1.6, 1);
}

.sklad .effect_lamp3 {
  transform-origin: center;
  width: 240px;
  height: 240px;
  background-image: url("./dust/res/img/effects.png");
  background-position: -265px -597px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 200px;
  left: 1300px;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(1.6, 1);
}

.sportzal .sign {
  transform-origin: center;
  width: 491px;
  height: 320px;
  background-image: url("./dust/res/img/effects.png");
  background-position: -1px -1px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 168px;
  left: 523px;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(1, 1.07);
}

.reception_1 .screen .sportzal .sign {
  animation: blink 10s infinite;
}

.hr .screen2, .kabinet_glavnogo .screen3 {
  animation: blink 3s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
    filter: hue-rotate();
  }

  30% {
    opacity: .4;
  }

  70% {
    opacity: .9;
    filter: hue-rotate(90deg);
  }

  90% {
    opacity: 0;
    filter: hue-rotate(120deg);
  }
}

.reception_1 .tv {
  opacity: 0;
  pointer-events: all;
  transition-duration: .5s;
}

.reception_1 .tv:hover {
  opacity: 1;
  animation: poweron-blink 1s both;
}

@keyframes poweron-blink {
  0% {
    opacity: 0;
    filter: hue-rotate();
  }

  30% {
    opacity: .7;
  }

  70% {
    opacity: .2;
  }

  100% {
    opacity: 1;
  }
}

.reception_1 .effect_lamp, .kafe .effect_lamp, .reception_1 .effect_lamp2, .kafe .effect_lamp2, .reception_1 .effect_lamp3, .kafe .effect_lamp3 {
  opacity: .1;
  pointer-events: all;
  transition-duration: .3s;
}

.reception_1 .effect_lamp:hover, .kafe .effect_lamp:hover, .reception_1 .effect_lamp2:hover, .kafe .effect_lamp2:hover, .reception_1 .effect_lamp3:hover, .kafe .effect_lamp3:hover {
  opacity: 1;
}

.anim-popup-appear-0 {
  animation: popup-appear-0 .4s ease-out;
}

.anim-popup-appear-1 {
  animation: popup-appear-1 .5s ease-in-out;
}

.anim-grow-1 {
  animation: grow-1 .5s ease-in-out;
}

.anim-grow-2 {
  animation: grow-2 .5s ease-in-out;
}

@keyframes popup-appear-1 {
  0% {
    margin-top: 300px;
  }

  60% {
    margin-top: -40px;
  }

  100% {
    margin-top: 0;
  }
}

@keyframes popup-appear-0 {
  0% {
    opacity: 0;
    transform: scaleX(0);
  }

  30% {
    transform: scaleX(1.3);
  }

  60% {
    opacity: 1;
    transform: scaleY(1.1);
  }

  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes anim-shine-0 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%)scaleX(0);
  }

  50% {
    transform: translate(-50%, -50%)scaleX(2);
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
    transform: translate(-50%, -50%)scaleX(1);
  }
}

@keyframes anim-shine-1 {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes grow-1 {
  0% {
    transform: scale(.4)translate(40px, -100%);
  }

  50% {
    transform: scale(.7)translate(0, -100%);
  }

  70% {
    transform: scale(.2)translate(0, -100%);
  }

  100% {
    transform: scale(.4)translate(40px, -100%);
  }
}

@keyframes grow-2 {
  0% {
    transform: scale(.4)translate(-40px, -100%);
  }

  50% {
    transform: scale(.7)translate(0, -100%);
  }

  70% {
    transform: scale(.2)translate(0, -100%);
  }

  100% {
    transform: scale(.4)translate(-40px, -100%);
  }
}

button#location_prev {
  width: 95px;
  height: 149px;
  background-color: #0000;
  background-image: url("./res/img/textures.png");
  background-position: -1248px -600px;
  background-repeat: no-repeat;
  border: none;
  transition-duration: .1s;
  display: inline-block;
  position: absolute;
  top: 80%;
  left: 100px;
  overflow: hidden;
  transform: scale(.4)translate(-40px, -100%);
}

button#location_prev:active {
  transform: scale(.6)translate(-20px, -70%);
}

button#location_next {
  width: 95px;
  height: 149px;
  opacity: 1;
  filter: none;
  mix-blend-mode: normal;
  background-color: #0000;
  background-image: url("./res/img/textures.png");
  background-position: -1345px -600px;
  background-repeat: no-repeat;
  border: none;
  transition-duration: .1s;
  display: inline-block;
  position: absolute;
  top: 80%;
  right: 100px;
  overflow: hidden;
  transform: scale(.4)translate(40px, -100%);
}

button#location_next:active {
  transform: scale(.6)translate(20px, -70%);
}

@media screen and (max-width: 1200px) {
  button#location_next {
    right: 5px;
  }

  button#location_prev {
    left: 5px;
  }
}

.decor-5ka-green-gradient-effect {
  background: linear-gradient(135deg, #008731 0%, #01a13b 50%, #008f32 51% 100%);
}

.decor-5ka-red-gradient-effect {
  background: linear-gradient(135deg, #ff6767 0%, #d12323 50%, #da1616 51% 100%);
}

.decor-5ka-blue-gradient-effect {
  background: linear-gradient(135deg, #3b679e 0%, #2b88d9 50%, #207cca 51%, #7db9e8 100%);
}

.decor-5ka-gold-gradient-effect {
  background: linear-gradient(135deg, #ffb76b 0%, #ffa73d 50%, #ff7c00 51%, #ff7f04 100%);
}

.decor-5ka-red-gradient-effect {
  background: linear-gradient(135deg, #ff3131 0%, #ff2a2a 50%, red 51%, #ac0000 100%);
}

.decor-office-bg-blue {
  background: linear-gradient(135deg, #8ad6fe 0%, #7abde1 50%, #659dbb 51%, #4e7990 100%);
}

.decor-office-bg-green {
  background: linear-gradient(135deg, #8afed3 0%, #75d6b2 50%, #60ae92 51%, #4b8771 100%);
}

.decor-office-bg-yellow {
  background: linear-gradient(135deg, #fee78a 0%, #d4c173 50%, #ac9d5d 51%, #877c4a 100%);
}

.decor-office-bg-red {
  background: linear-gradient(135deg, #febe8a 0%, #cf9a6f 50%, #a77d5a 51%, #785941 100%);
}

.decor-office-bg-purple {
  background: linear-gradient(135deg, #c89dff 0%, #a884d6 50%, #8d6fb4 51%, #6c558b 100%);
}

.decor-office-bg-empty {
  background: linear-gradient(135deg, #00000027 0%, #00000032 50%, #00000036 51%, #0000004a 100%);
}

.decor-5ka-gradient-effect-hover-animation {
  background-position: 99%;
  background-size: 250%;
  transition-duration: .2s;
}

.decor-5ka-gradient-effect-hover-animation:hover {
  perspective: 100px;
  background-position: 0;
  background-size: 110%;
  transform: scale(1.05);
}

.decor-5ka-red-gradient-effect-with-hover {
  background: linear-gradient(135deg, #ff3131 0%, #ff2a2a 50%, red 51%, #ac0000 100%) 99% / 250%;
  transition-duration: .2s;
}

.decor-5ka-red-gradient-effect-with-hover:hover {
  perspective: 100px;
  background-position: 0;
  background-size: 110%;
  transform: scale(1.05);
}

.decor-5ka-gold-gradient-effect-with-hover {
  background: linear-gradient(135deg, #ffb76b 0%, #ffa73d 50%, #ff7c00 51%, #ff7f04 100%) 99% / 250%;
  transition-duration: .2s;
}

.decor-5ka-gold-gradient-effect-with-hover:hover {
  perspective: 100px;
  background-position: 0;
  background-size: 110%;
  transform: scale(1.05);
}

.decor-5ka-blue-gradient-effect-with-hover {
  background: linear-gradient(135deg, #3b679e 0%, #2b88d9 50%, #207cca 51%, #7db9e8 100%) 99% / 250%;
  transition-duration: .2s;
}

.decor-5ka-blue-gradient-effect-with-hover:hover {
  perspective: 100px;
  background-position: 0;
  background-size: 110%;
  transform: scale(1.05);
}

.progressbar-style-ref-0, itd5ka#psources.container progressbar#psource-employee-stat, itd5ka#psources.container progressbar#psource-gadget-stat, itd5ka#psources.container #psource-tasks.container entry progressbar#psource-task-stat, page#inventory progressbar, page#itd_reward progressbar, page#itd_room_details progressbar {
  height: 5vw;
  color: #414042;
  text-shadow: 0 1px #ffffff90;
  background-color: #98b29b;
  border-radius: 4px;
  font-size: .8em;
  font-weight: bolder;
  box-shadow: 0 2px #435445;
}

.progressbar-color-gold:before {
  background-color: #f0c755;
  border-bottom: 1px solid #ffffff90;
  box-shadow: 0 2px #b94a00;
}

.progressbar-color-green:before {
  background-color: #67d47a;
  border-bottom: 1px solid #ffffff90;
  box-shadow: 0 2px #066f2b;
}

.progressbar-color-red:before {
  background-color: #ff7166;
  border-bottom: 1px solid #ffffff90;
  box-shadow: 0 2px #b60b0f;
}

.progressbar-color-blue:before {
  background-color: #a6d3eb;
  border-bottom: 1px solid #ffffff90;
  box-shadow: 0 2px #0078a4;
}

.progressbar-color-blue-white-text:before {
  background-color: #34aaff;
  border-bottom: 1px solid #ffffff90;
  box-shadow: 0 2px #404455;
}

[hover-label] {
  position: relative;
}

[hover-label]:after {
  opacity: 0;
  content: attr(hover-label);
  max-width: 165px;
  min-width: 100px;
  z-index: 20;
  pointer-events: none;
  white-space: break-spaces;
  background: #fff;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 4vw;
  transition: opacity .3s;
  position: absolute;
  top: 30%;
  left: 0;
  box-shadow: 0 2px 2px #00000090;
}

[hover-label]:hover:after {
  opacity: 1;
  transition-delay: .1s;
}

.tooltip {
  opacity: 0;
  max-width: 30vw;
  min-width: 100px;
  z-index: 100;
  pointer-events: none;
  background: #fff;
  border-radius: 10px;
  padding: 4px 8px;
  font-size: 2.8vw;
  transition: opacity 2s;
  position: absolute;
  box-shadow: 0 2px 2px #00000090;
}

.tooltip.active {
  opacity: 1;
  transition: opacity 2s;
}

itd5ka#header.container {
  width: 100%;
  height: calc(24px * 2.1 * var(--scale-js) * var(--iphone-header) );
  top: calc(0vw - (var(--iphone-header)  - 1) * 4vw);
  transform: scale(var(--scale-hud-a) );
  flex-direction: column-reverse;
  display: flex;
  position: absolute;
}

itd5ka#header.container #currency.container {
  transform-origin: bottom;
  gap: 4px;
  margin: auto auto auto 6px;
  display: flex;
  position: absolute;
  bottom: 14px;
}

itd5ka#header.container #currency.container div#gold:before {
  width: 81px;
  height: 79px;
  transform: translateX(1.8rem) scale(calc(var(--scale-js) * .4) );
  background-position: -1647px -883px;
}

itd5ka#header.container #currency.container div#gems:before {
  width: 81px;
  height: 74px;
  transform: translateX(1.8rem) scale(calc(var(--scale-js) * .4) );
  background-position: -1564px -792px;
}

itd5ka#header.container #currency.container div#coffee:before {
  width: 88px;
  height: 100px;
  transform: translateX(1.8rem) translateY(-1vw) scale(calc(var(--scale-js) * .4) );
  background-position: -1242px -1274px;
}

itd5ka#header.container #currency.container .decor-0 {
  border: 3px solid #9e9e9e;
  box-shadow: inset 0 0 0 2px #e1e1e1;
}

itd5ka#header.container #currency.container .decor-1 {
  border-bottom: 4px solid #00000030;
}

itd5ka#header.container #currency.container div.decor-currency {
  color: var(--color-5ka-b);
  min-width: 24vw;
  max-width: 24vw;
  height: calc(var(--scale-js) * 35px);
  background-color: var(--color-5ka-white);
  border-radius: calc(var(--radius-border-a) * .7);
  border: 3px solid #9e9e9e;
  justify-content: center;
  align-items: center;
  font-size: 3vw;
  font-weight: 600;
  display: flex;
  box-shadow: inset 0 0 0 2px #e1e1e1;
}

itd5ka#header.container #currency.container div.decor-currency:before {
  content: "";
  background-image: url("./res/img/textures.png");
  background-repeat: no-repeat;
  margin-left: -3.7rem;
  display: block;
  position: relative;
  overflow: hidden;
}

itd5ka#header.container #currency.container div.decor-currency label {
  text-align: center;
  white-space: nowrap;
  margin-left: auto;
  margin-right: 1.6vw;
  display: block;
  position: relative;
}

itd5ka#header.container #currency.container div.decor-currency.coffee {
  min-width: 18vw;
  max-width: 18vw;
}

itd5ka#minified {
  pointer-events: all;
}

itd5ka#minified button {
  cursor: pointer;
}

itd5ka#minified itd5ka#header {
  width: -webkit-min-content;
  width: min-content;
  flex-direction: column-reverse;
  gap: 30px;
  padding: 0;
}

itd5ka#minified itd5ka#header #currency {
  flex-direction: column;
  padding: 0 50px;
}

itd5ka#minified itd5ka#header #controls {
  justify-content: left;
  padding: 0 10px;
}

#controls.container {
  width: 35vw;
  gap: 1vw;
  margin: auto auto -13vw 2vw;
  display: flex;
}

#controls.container button#journal:before {
  width: 50px;
  height: 50px;
  background-position: -1442px -694px;
  transform: translate(-50%, -50%)scale(.5);
}

#controls.container button#journal:active:before {
  transform: translate(-50%, -50%)scale(.7);
}

#controls.container button#journal.notification:after {
  content: "!";
  color: #ff0;
  margin: auto;
  font-size: 10vw;
  line-height: 0;
  position: relative;
}

#controls.container button#settings:before {
  width: 50px;
  height: 49px;
  background-position: -1549px -1037px;
  transform: translate(-50%, -50%)scale(.5);
}

#controls.container button#settings:active:before {
  transform: translate(-50%, -50%)scale(.7);
}

#controls.container button#change_size_mode.minify:before {
  width: 34px;
  height: 34px;
  background-position: -1687px -1352px;
}

#controls.container button#change_size_mode.maxify:before {
  width: 34px;
  height: 34px;
  background-position: -1094px -821px;
}

#controls.container button.decor-itd-0 {
  width: 10vw;
  height: 10vw;
  border-radius: 10px;
  display: flex;
  position: relative;
  box-shadow: 0 0 3px #000, inset 0 0 0 4px #ffffff30;
}

#controls.container button.decor-itd-0:before {
  content: "";
  background-image: url("./res/img/textures.png");
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
}

#controls.container button:before {
  transition-duration: inherit;
}

itd5ka#psources.container {
  --background_width_px: 1520;
  --decor-scale-js: 1;
  --decor-scale: min(1, var(--decor-scale-js) );
  --pixel_ref_img_width: 1520px;
  --clamped_pixel_img_width: min(var(--pixel_ref_img_width), var(--width) );
  width: 100%;
  flex-direction: column-reverse;
  margin: auto auto 72px;
  display: flex;
}

itd5ka#psources.container itd5ka#psource {
  height: 49.2763vw;
  background-position: top;
  background-repeat: no-repeat;
  background-size: 102%;
  display: block;
  position: relative;
}

itd5ka#psources.container itd5ka#psource #psource-controls {
  z-index: 1;
  position: relative;
}

itd5ka#psources.container itd5ka#psource.unactivated {
  box-shadow: 0 3px 5px #00000050;
}

itd5ka#psources.container itd5ka#psource.unactivated #psource-controls {
  z-index: 1;
  position: relative;
}

itd5ka#psources.container itd5ka#psource.unactivated #psource-upgrade-container {
  transform-origin: 100% 0;
}

itd5ka#psources.container itd5ka#psource.disabled:after {
  background: linear-gradient(90deg, #00000050 0%, #000b 10% 90%, #00000050 100%);
}

itd5ka#psources.container itd5ka#psource.disabled decor#psource-lock-icon {
  display: initial;
  z-index: 1;
}

itd5ka#psources.container itd5ka#psource.disabled #psource-employee-stat, itd5ka#psources.container itd5ka#psource.disabled #psource-gadget-stat, itd5ka#psources.container itd5ka#psource.unactivated label#psource-gadget-stat, itd5ka#psources.container itd5ka#psource.unactivated decor#psource-gadget-sprite, itd5ka#psources.container itd5ka#psource.unactivated decor#psource-employee-sprite, itd5ka#psources.container itd5ka#psource.unactivated decor#psource-npc-sprite-1, itd5ka#psources.container itd5ka#psource.unactivated decor#psource-npc-sprite-2, itd5ka#psources.container itd5ka#psource.unactivated decor#psource-npc-sprite-3, itd5ka#psources.container itd5ka#psource.unactivated label#psource-employee-stat {
  display: none;
}

itd5ka#psources.container itd5ka#psource:before {
  content: "";
  width: var(--clamped_pixel_img_width);
  height: 49.2763vw;
  z-index: -1;
  background-size: cover;
  position: absolute;
}

itd5ka#psources.container decor#psource-employee-sprite, itd5ka#psources.container #psource-employee-stat {
  animation: human-breath 3s linear infinite;
  position: absolute;
  bottom: 3.5vw;
  left: 40%;
}

itd5ka#psources.container decor#psource-npc-sprite-1, itd5ka#psources.container decor#psource-npc-sprite-2, itd5ka#psources.container decor#psource-npc-sprite-3 {
  --h_position: 30%;
  --v_position: 20%;
  --breath_delay: 0ms;
  left: var(--h_position);
  bottom: var(--v_position);
  animation: human-breath 3s ease-out var(--breath_delay) infinite;
  position: absolute;
}

@keyframes human-breath {
  0% {
    transform: translate(-50%, 0%) scale(calc(var(--decor-scale) * 1.5) );
  }

  50% {
    transform: translate(-50%, 0%) scale(calc(var(--decor-scale) * 1.5), calc(var(--decor-scale) * 1.5 * 1.02) );
  }

  100% {
    transform: translate(-50%, 0%) scale(calc(var(--decor-scale) * 1.5) );
  }
}

itd5ka#psources.container #psource-gadget-stat, itd5ka#psources.container decor#psource-gadget-sprite {
  position: absolute;
  bottom: 7%;
  left: 20%;
}

itd5ka#psources.container decor#effects-container {
  transform: scale(var(--decor-scale-js) );
  mix-blend-mode: screen;
  z-index: 0;
  opacity: 1;
  position: absolute;
}

itd5ka#psources.container progressbar#psource-employee-stat {
  width: 28vw;
  height: 8vw;
  pointer-events: none;
  z-index: 2;
  margin-bottom: 1vw;
}

itd5ka#psources.container progressbar#psource-employee-stat:before {
  border-radius: inherit;
  background: linear-gradient(90deg, red 0%, #f55 100%);
  border-bottom: 1px solid #ffffff90;
  transition-duration: .5s;
  box-shadow: 0 4px #6b070a;
}

itd5ka#psources.container decor#psource-gadget-sprite {
  transform: translate3d(-50%, 0%, 0) scale(var(--decor-scale) );
  transform-origin: bottom;
  z-index: 2;
  background-image: url("./res/img/textures.png");
  background-repeat: no-repeat;
  display: inline-block;
  overflow: hidden;
}

itd5ka#psources.container decor#psource-employee-sprite {
  transform: translate(-50%, -2%) scale(calc(var(--decor-scale) * 1.5) );
  transform-origin: bottom;
  z-index: 2;
  background-image: url("./res/img/sprites-managers.png");
  background-repeat: no-repeat;
  display: grid;
  overflow: visible;
}

itd5ka#psources.container decor#psource-npc-sprite-1, itd5ka#psources.container decor#psource-npc-sprite-2, itd5ka#psources.container decor#psource-npc-sprite-3 {
  --y-rotate: 0deg;
  transform: translate(-50%, 0%) scale(var(--decor-scale) ) rotateY(var(--y-rotate) );
  transform-origin: bottom;
  z-index: 0;
  background-image: url("./res/img/npc.png");
  background-repeat: no-repeat;
  display: grid;
  overflow: visible;
}

itd5ka#psources.container decor#psource-employee-sprite.shielded:after {
  content: "";
  width: 114px;
  height: 125px;
  background-image: url("./res/img/textures.png");
  background-position: -1593px -194px;
  background-repeat: no-repeat;
  margin: auto;
  animation: shield-appear .5s ease-in;
  display: block;
  bottom: 100px;
  overflow: hidden;
}

@keyframes shield-appear {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

itd5ka#psources.container button#use_coffee {
  margin: 1vw auto auto 8vw;
  padding: 2vw 92px 2vw 4vw;
  font-size: 5vw;
  display: none;
  position: relative;
}

itd5ka#psources.container button#use_coffee:before {
  content: "";
  width: 88px;
  height: 100px;
  pointer-events: none;
  background-image: url("./res/img/textures.png");
  background-position: -1242px -1274px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: -36px;
  left: 26vw;
  overflow: hidden;
  transform: scale(.5);
}

itd5ka#psources.container button#use_coffee:after {
  content: attr(data-cost);
  color: #ffba59;
  pointer-events: none;
  font-size: 5vw;
  position: relative;
  top: 2px;
  left: 70px;
}

itd5ka#psources.container button#use_coffee.disabled {
  opacity: .7;
  box-shadow: initial;
  pointer-events: none;
  font-weight: 600;
}

itd5ka#psources.container itd5ka#psource.currently_in_battle button#use_coffee {
  display: flex;
}

itd5ka#psources.container .stat_decor {
  height: 5vw;
  color: #414042;
  text-shadow: 0 1px #ffffff90;
  color: #fff;
  text-shadow: 0 2px 1px #00000090;
  background-color: #98b29b;
  border-top: 1px solid #ffffff90;
  border-radius: 4px;
  margin: auto;
  padding: 2px 10px;
  font-size: .8em;
  font-weight: bolder;
  box-shadow: 0 2px #435445;
}

itd5ka#psources.container progressbar#psource-gadget-stat {
  width: 10vw;
  height: 3vw;
  color: #fff;
  text-shadow: 0 2px 1px #00000090;
  z-index: 2;
  background-color: #272e28;
  margin-bottom: auto;
  font-size: 3vw;
}

itd5ka#psources.container progressbar#psource-gadget-stat:before {
  border-radius: inherit;
  background-color: #34aaff;
  border-bottom: 1px solid #ffffff90;
  transition-duration: .5s;
  box-shadow: 0 2px #404455;
}

itd5ka#psources.container #psource-employee-stat {
  transform: translate(-50%, -100%);
}

itd5ka#psources.container #psource-gadget-stat {
  transform: translate3d(-50%, -50%, 0);
}

itd5ka#psources.container label#psource-employee-stat:after {
  content: "♥";
}

itd5ka#psources.container progressbar#psource-gadget-stat:after {
  content: "✦";
  font-size: 3vw;
}

itd5ka#psources.container decor#psource-lock-icon {
  width: 146px;
  height: 195px;
  transform: translate(-50%, -50%) scale(calc(var(--decor-scale-js) * 2) );
  background-image: url("./res/img/textures.png");
  background-position: -968px -260px;
  background-repeat: no-repeat;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
}

itd5ka#psources.container itd5ka#psource.header_office {
  display: flex;
}

itd5ka#psources.container itd5ka#psource.header_office button#battle_start {
  color: #fff;
  text-shadow: 0 1px 1px #00000090;
  border: 2px solid #00000050;
  margin: auto;
  padding: .5em 20px;
  font-size: 4vw;
  display: block;
}

itd5ka#psources.container itd5ka#psource.header_office.unavailable button#battle_start {
  display: none;
}

itd5ka#psources.container itd5ka#psource.header_office label#buy_all_prev_hint {
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px #000;
  background-color: #467c898c;
  margin: 0 auto auto;
  padding: 4px 5vw 5px;
  font-size: 3.5vw;
  font-weight: bolder;
  display: none;
}

itd5ka#psources.container itd5ka#psource.header_office.unavailable label#buy_all_prev_hint {
  display: block;
}

itd5ka#psources.container itd5ka#psource.roof_office {
  height: 25vw;
  display: flex;
}

itd5ka#psources.container #psource-controls.container {
  padding: 4px calc(8vw * var(--decor-scale) );
  width: 100%;
  z-index: 1;
  justify-content: space-between;
  display: flex;
  position: relative;
}

itd5ka#psources.container #psource-tasks.container {
  width: 50%;
  height: 50%;
  padding: 10px calc(30vw * var(--decor-scale) );
  pointer-events: all;
  margin-left: 50%;
  margin-bottom: calc(30px * var(--decor-scale) );
  z-index: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: end;
  display: flex;
  position: absolute;
  bottom: 0;
}

itd5ka#psources.container #psource-tasks.container:active entry:nth-child(2) icon#psource-task {
  transform: translate(-50%, 0%) scale(calc(var(--decor-scale) * 1.4) );
}

itd5ka#psources.container #psource-tasks.container entry {
  height: 100%;
  pointer-events: none;
  flex-direction: column;
  font-size: 1rem;
  display: flex;
  position: relative;
}

itd5ka#psources.container #psource-tasks.container entry icon#psource-task {
  transform: translate(-50%, 0%) scale(calc(var(--decor-scale) * 1.6) );
  transform-origin: bottom;
  background-image: url("./res/img/textures.png");
  background-repeat: no-repeat;
  margin-bottom: 0;
  transition-duration: 70ms;
  display: inline-block;
  position: absolute;
  bottom: 1vw;
  left: 50%;
  overflow: hidden;
}

itd5ka#psources.container #psource-tasks.container entry label {
  height: 5vw;
  color: #414042;
  text-shadow: 0 1px #ffffff90;
  color: #fff;
  text-shadow: 0 2px 1px #00000090;
  z-index: 1;
  filter: hue-rotate(270deg);
  background-color: #98b29b;
  border-top: 1px solid #ffffff90;
  border-radius: 4px;
  margin: auto;
  padding: 2px 10px;
  font-size: .8em;
  font-weight: bolder;
  box-shadow: 0 2px #435445;
}

itd5ka#psources.container #psource-tasks.container entry label#psource-task-text {
  font-size: .7em;
  display: none;
}

itd5ka#psources.container #psource-tasks.container entry label#psource-task-text:after {
  content: "♥";
}

itd5ka#psources.container #psource-tasks.container entry progressbar#psource-task-stat {
  width: 8vw;
  height: 2.5vw;
  margin-top: 0;
}

itd5ka#psources.container #psource-tasks.container entry progressbar#psource-task-stat:before {
  background: linear-gradient(90deg, red 0%, #ffc755 100%);
  border-bottom: 1px solid #ffffff90;
  border-radius: 2px;
  transition-duration: .5s;
  box-shadow: 0 2px #8f0e11;
}

itd5ka#psources.container #psource-tasks.container entry label#psource-task-header, itd5ka#psources.container #psource-tasks.container entry:nth-child(n+5) {
  display: none;
}

itd5ka#psources.container #psource-info-container {
  transform: scale(min(1, calc(var(--decor-scale) * 2.5) ) );
  transform-origin: 0 0;
  pointer-events: all;
  cursor: pointer;
  max-width: 50vw;
  flex-grow: .6;
  margin-left: -2.5vw;
  margin-right: 0;
  display: flex;
}

itd5ka#psources.container #psource-info-container:active #psource-info-icon {
  transform: scale(1.05);
}

itd5ka#psources.container decor#psource-info-icon.container {
  pointer-events: none;
  margin: auto;
  transition-duration: .1s;
  display: block;
  position: relative;
}

itd5ka#psources.container decor#psource-info-icon.container:before {
  width: 118px;
  height: 118px;
  content: "";
  background-image: url("./res/img/textures.png");
  background-position: -1248px -835px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  overflow: hidden;
  transform: translate(0, -50%)scale(.7);
}

itd5ka#psources.container decor#psource-info-icon.container .icon {
  width: 118px;
  height: 118px;
  position: absolute;
  transform: translate(5%, -44%)scale(.65);
}

itd5ka#psources.container decor#psource-info-icon.container .icon icon#psource-manager {
  background-image: url("./res/img/textures.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  overflow: hidden;
}

itd5ka#psources.container decor#psource-info-profit.container {
  pointer-events: none;
  width: 30vw;
  color: var(--color-5ka-white);
  background-color: var(--color-5ka-b);
  --button-scale-js: 1;
  min-height: 82px;
  max-height: 82px;
  background: linear-gradient(135deg, #008731 0%, #01a13b 50%, #008f32 51% 100%);
  border-top: 5px solid #ffffff30;
  border-bottom: 5px solid #00000030;
  border-radius: 0 15px 15px 0;
  flex-direction: column;
  flex-grow: 1;
  margin: 0;
  display: flex;
  transform: translate(88px);
}

itd5ka#psources.container decor#psource-info-profit.container progressbar#psource-progress {
  width: 40vw;
  height: 5vw;
  text-shadow: 2px 2px 1px #000000a0;
  background-color: #00000050;
  border-radius: 7px;
  margin: 0 2vw 0 7vw;
  font-size: 4.5vw;
  font-weight: 600;
}

itd5ka#psources.container decor#psource-info-profit.container progressbar#psource-progress:before {
  background-color: var(--color-5ka-c);
  border-radius: 7px;
  box-shadow: inset 0 0 0 2px #ffc55b;
}

itd5ka#psources.container decor#psource-info-profit.container progressbar#psource-progress.continues:before {
  background: repeating-linear-gradient(-45deg, #ffc832, #ffc832 5px, var(--color-5ka-c) 5px, var(--color-5ka-c) 10px);
  animation: background-move .5s linear infinite;
  box-shadow: inset 0 0 3px 1px #ffc55b;
}

@keyframes background-move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 14.14px 0;
  }
}

@keyframes star-pop {
  0% {
    transform: scale(.7);
  }

  30% {
    transform: scale(.85);
  }

  80% {
    transform: scale(.85);
  }

  100% {
    transform: scale(.7);
  }
}

itd5ka#psources.container decor#psource-info-profit.container progressbar#psource-progress:after {
  content: "";
  width: 50px;
  height: 50px;
  z-index: 1;
  background-image: url("./res/img/textures.png");
  background-position: -1494px -694px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: -1rem;
  left: -.9rem;
  overflow: hidden;
  transform: scale(.7);
}

itd5ka#psources.container decor#psource-info-profit.container progressbar#psource-progress.star-bounce:after {
  animation: star-pop .3s linear infinite;
}

itd5ka#psources.container decor#psource-info-profit.container label#psource-label {
  text-align: center;
  white-space: nowrap;
  text-shadow: 2px 0 1px #00000090;
  margin-top: -.5vw;
  padding-left: 5px;
  font-size: 4.5vw;
  font-weight: 600;
}

itd5ka#psources.container decor#psource-info-profit.container container#psource-info-details {
  width: 85%;
  justify-content: space-between;
  margin: 1vw 0 0 2vw;
  display: flex;
}

itd5ka#psources.container decor#psource-info-profit.container container#psource-info-details label {
  text-shadow: 2px 0 1px #00000090;
  font-size: 4vw;
  font-weight: 600;
}

itd5ka#psources.container decor#psource-info-profit.container container#psource-info-details label#psource-time {
  color: #dee436;
  align-items: center;
  margin-left: 4vw;
  display: flex;
}

itd5ka#psources.container decor#psource-info-profit.container container#psource-info-details label#psource-time:before {
  content: "";
  width: 16px;
  height: 16px;
  background-image: url("./res/img/textures.png");
  background-position: -865px -1269px;
  background-repeat: no-repeat;
  margin-bottom: 1vw;
  margin-right: 1vw;
  display: inline-block;
  overflow: hidden;
}

itd5ka#psources.container #psource-upgrade-container.container {
  color: var(--color-5ka-white);
  pointer-events: all;
  cursor: pointer;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  flex-direction: column-reverse;
  justify-content: start;
  margin-left: auto;
  margin-right: 0;
  display: flex;
}

itd5ka#psources.container #psource-upgrade-container.container > * {
  pointer-events: none;
}

itd5ka#psources.container #psource-upgrade-container.container.disabled {
  pointer-events: none;
  filter: grayscale();
}

itd5ka#psources.container #psource-upgrade-container.container.disabled button#psource-upgrade:before {
  opacity: 0;
  transition-duration: .3s;
}

itd5ka#psources.container #psource-upgrade-container.container.disabled label#psource-upgrade-value {
  pointer-events: none;
  text-align: center;
  color: #b0b0b0;
  margin-left: 4vw;
  font-size: 4vw;
  font-weight: 400;
  display: block;
  position: relative;
}

itd5ka#psources.container #psource-upgrade-container.container.disabled label#psource-upgrade-label {
  opacity: 0;
  transition-duration: .7s;
}

itd5ka#psources.container #psource-upgrade-container.container label#psource-upgrade-label {
  background-color: var(--color-5ka-b);
  text-align: center;
  text-shadow: 1px 0 1px #00000090;
  width: auto;
  min-width: 10vw;
  margin: 0 var(--radius-border-a);
  overflow: overlay;
  background: linear-gradient(135deg, #008731 0%, #01a13b 50%, #008f32 51% 100%);
  border-bottom: 2px solid #00000030;
  border-radius: 0 0 3vw 3vw;
  margin-top: -.5vw;
  margin-left: 5vw;
  margin-right: 2vw;
  padding: .4vw 2vw;
  font-size: 2.8vw;
  font-weight: 600;
  transition-duration: .1s;
  display: block;
  position: relative;
  left: 1vw;
}

itd5ka#psources.container #psource-upgrade-container.container button#psource-upgrade {
  min-width: 30vw;
  width: 80%;
  height: 7.5vw;
  text-align: center;
  text-shadow: 1px 0 1px #00000090;
  --button-scale-js: 1;
  background: linear-gradient(135deg, #ff3131 0%, #ff2a2a 50%, red 51%, #ac0000 100%) 0 / 110%;
  border-radius: 3vw;
  align-items: center;
  margin-left: auto;
  padding: .5vw .5vw .5vw 1vw;
  font-size: 4vw;
  display: block;
  position: relative;
}

itd5ka#psources.container #psource-upgrade-container.container button#psource-upgrade:before {
  content: "";
  width: 81px;
  height: 79px;
  transform-origin: center;
  opacity: 1;
  background-image: url("./res/img/textures.png");
  background-position: -1647px -883px;
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(.45);
}

itd5ka#psources.container #psource-upgrade-container.container label#psource-upgrade-value {
  pointer-events: none;
  text-align: center;
  text-shadow: 1px 0 1px #00000090;
  margin-left: 4vw;
  font-size: 4vw;
  font-weight: bolder;
  display: block;
  position: relative;
}

itd5ka#psources.container #psource-upgrade-container.container:active button#psource-upgrade {
  background-position: 0;
  background-size: 110%;
  transform: scale(1.05);
}

itd5ka#psources.container #psource-upgrade-container.container:active button#psource-upgrade:before {
  transform: translate(-50%, -50%)scale(.55)rotate(-15deg);
}

itd5ka#psources.container #psource-upgrade-container.container:active label#psource-upgrade-value {
  pointer-events: none;
  text-align: center;
  text-shadow: 1px 0 1px #00000090;
  margin-left: 4vw;
  font-size: 4vw;
  font-weight: bolder;
  display: block;
  position: relative;
}

itd5ka#psources.container .psource_forground_overlay {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
}

itd5ka#psources.container itd5ka#psource.unactivated:after, itd5ka#psources.container itd5ka#psource.disabled:after {
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(90deg, #00000050 0%, #00000090 10% 90%, #00000050 100%);
  position: absolute;
  top: 0;
}

itd5ka#psources.container.battle_mode itd5ka#psource.currently_in_battle, itd5ka#psources.container itd5ka#psource.pvp_floor.currently_in_battle {
  box-shadow: 0 3px 2px #b93737aa, 0 -3px 2px #b93737aa;
}

itd5ka#psources.container.battle_mode itd5ka#psource.currently_in_battle:after, itd5ka#psources.container itd5ka#psource.pvp_floor.currently_in_battle:after {
  width: 100%;
  height: 100%;
  content: "";
  mix-blend-mode: overlay;
  background: linear-gradient(90deg, #00000050 0%, #953c3c 10% 90%, #00000050 100%);
  animation: pulse_overlay 2s ease-in-out infinite alternate;
  position: absolute;
  top: 0;
}

itd5ka#psources.container.battle_mode itd5ka#psource.currently_in_battle #psource-controls, itd5ka#psources.container itd5ka#psource.pvp_floor.currently_in_battle #psource-controls {
  z-index: 1;
  position: relative;
}

@keyframes pulse_overlay {
  0% {
    opacity: .8;
  }

  100% {
    opacity: .4;
  }
}

itd5ka#psources.container.battle_mode itd5ka#psource.defeated_in_battle:after, itd5ka#psources.container itd5ka#psource.pvp_floor.defeated_in_battle:after {
  width: 100%;
  height: 100%;
  content: "";
  z-index: 10;
  mix-blend-mode: multiply;
  background: linear-gradient(90deg, #00000050 0%, #c8a294 10% 90%, #00000050 100%);
  animation: none;
  position: absolute;
  top: 0;
}

itd5ka#psources.container itd5ka#psource.pvp_floor #psource-upgrade-container, itd5ka#psources.container.battle_mode #psource-upgrade-container, itd5ka#psources.container itd5ka#psource.pvp_floor #psource-level, itd5ka#psources.container.battle_mode #psource-level, itd5ka#psources.container itd5ka#psource.pvp_floor #psource-info-details, itd5ka#psources.container.battle_mode #psource-info-details, itd5ka#psources.container itd5ka#psource.pvp_floor #psource-progress, itd5ka#psources.container.battle_mode #psource-progress {
  display: none !important;
}

itd5ka#psources.container itd5ka#psource.pvp_floor #psource-info-profit, itd5ka#psources.container.battle_mode #psource-info-profit {
  min-height: 82px;
}

itd5ka#psources.container itd5ka#psource.pvp_floor #psource-info-icon, itd5ka#psources.container.battle_mode #psource-info-icon {
  margin-left: 0;
}

.sprite-characters {
  background-image: url("./res/img/characters.png");
  background-repeat: no-repeat;
  display: inline-block;
  overflow: hidden;
}

.characters-alena-a {
  width: 294px;
  height: 512px;
  background-position: -425px -516px;
}

.characters-dima-a {
  width: 413px;
  height: 512px;
  background-position: -425px -2px;
}

.characters-eduard-a {
  width: 421px;
  height: 512px;
  background-position: -2px -2px;
}

.characters-ivan-a {
  width: 421px;
  height: 512px;
  background-position: -2px -516px;
}

.characters-mascot {
  width: 450px;
  height: 500px;
  background-position: -2px -1030px;
}

.characters-vika-a {
  width: 279px;
  height: 512px;
  background-position: -454px -1030px;
}

cutscene#game_cutscene.container {
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: all;
  width: 100%;
  height: 100%;
  --padding: 10px;
  padding: var(--padding);
  background-color: #80dcf3;
  background-image: url("./res/img/itd/kafe.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column-reverse;
  display: flex;
  position: absolute;
  bottom: 0;
}

cutscene#game_cutscene.container .area-style-0 {
  background-color: var(--color-5ka-white);
  box-shadow: 0px -10px 0px var(--color-5ka-b);
  border-radius: var(--radius-border-a);
}

cutscene#game_cutscene.container cutscene#storyline_text.content {
  background-color: var(--color-5ka-white);
  box-shadow: 0px -10px 0px var(--color-5ka-b);
  border-radius: var(--radius-border-a);
  width: 100%;
  z-index: 1;
  margin-bottom: 10px;
  padding: 20px 20px 40px;
  font-size: 4vw;
  font-weight: 300;
  line-height: 1.5em;
}

cutscene#game_cutscene.container cutscene#storyline_text.content b {
  font-size: 2.2em;
}

cutscene#game_cutscene.container cutscene#storyline_text.content h1, cutscene#game_cutscene.container cutscene#storyline_text.content h2, cutscene#game_cutscene.container cutscene#storyline_text.content h3, cutscene#game_cutscene.container cutscene#storyline_text.content h4 {
  font-size: 1.2em;
}

cutscene#game_cutscene.container cutscene#storyline_text.content code {
  background-color: #aaaa;
  border-radius: 4px;
  padding: 1px 4px;
}

cutscene#game_cutscene.container #storyline_title {
  font-weight: 600;
}

cutscene#game_cutscene.container #storyline_title_container {
  color: var(--color-5ka-white);
  letter-spacing: 1px;
  z-index: 1;
  margin: 0 5vw;
  font-size: 4.5vw;
  display: flex;
  transform: translate(0, 10px)scale(1);
}

cutscene#game_cutscene.container #storyline_title, cutscene#game_cutscene.container #storyline_title_right {
  background-color: var(--color-5ka-b);
  filter: brightness(1.1);
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 3px 10px 2px #00000030;
}

cutscene#game_cutscene.container #storyline_title_right {
  margin-left: auto;
  margin-right: 0;
}

cutscene#game_cutscene.container #storyline_sprite_container {
  width: 100%;
  transform: translateY(20px);
}

cutscene#game_cutscene.container #storyline_sprite {
  margin-right: -50vw;
}

cutscene#game_cutscene.container #storyline_sprite_container {
  display: flex;
}

cutscene#game_cutscene.container #storyline_sprite_container .right {
  transform: translate(30%, 30%)scale(-.45, .45);
}

cutscene#game_cutscene.container #storyline_sprite_container .characters-mascot.right {
  transform: translate(30%, 30%)scale(.45);
}

cutscene#game_cutscene.container #storyline_sprite_container .left {
  margin-left: 0;
  transform: translate(-30%, 30%)scale(.45);
}

cutscene#game_cutscene.container #storyline_sprite, cutscene#game_cutscene.container #storyline_sprite_right {
  background-image: url("./res/img/characters.png");
  background-repeat: no-repeat;
  margin-left: auto;
  display: inline-block;
  overflow: hidden;
}

cutscene#game_cutscene.container #storyline_reward {
  font-weight: 600;
}

cutscene#game_cutscene.container #storyline_reward_container {
  color: var(--color-5ka-white);
  letter-spacing: 2px;
  z-index: 3;
  margin: auto;
  font-size: 2.5em;
  display: block;
  transform: translateY(80vw);
}

cutscene#game_cutscene.container #storyline_reward_container div#video_reward_tip {
  color: #fff;
  letter-spacing: 1px;
  text-align: center;
  vertical-align: middle;
  max-width: 50vw;
  background-color: #0008;
  border-radius: 10px;
  margin: auto auto 1vw;
  padding: 10px;
  font-size: 3.5vw;
  font-weight: bolder;
  display: flex;
  position: relative;
}

cutscene#game_cutscene.container #storyline_reward_container button#storyline_reward {
  width: 100%;
  height: 60%;
  pointer-events: all;
  border: 6px solid #a01313ab;
  display: flex;
}

cutscene#game_cutscene.container #storyline_reward_container button#storyline_reward div#video_reward {
  content: "";
  width: 81px;
  height: 74px;
  pointer-events: none;
  background-image: url("./res/img/textures.png");
  background-position: -1564px -792px;
  background-repeat: no-repeat;
  margin: auto;
  display: flex;
  position: absolute;
  overflow: hidden;
  transform: scale(.75);
}

cutscene#game_cutscene.container #storyline_reward_container button#storyline_reward:before {
  content: "";
  width: 81px;
  height: 74px;
  filter: grayscale() brightness(0);
  pointer-events: none;
  background-image: url("./res/img/textures.png");
  background-position: -1564px -792px;
  background-repeat: no-repeat;
  margin: auto;
  display: flex;
  position: absolute;
  overflow: hidden;
  transform: scale(.8);
}

cutscene#game_cutscene.container #storyline_reward_container button#storyline_reward div#video_reward_value {
  color: #fff;
  letter-spacing: 2px;
  vertical-align: text-bottom;
  pointer-events: none;
  border-radius: 100%;
  margin-top: 2vw;
  margin-left: 20vw;
  margin-right: auto;
  padding: 10px;
  font-size: 8vw;
  font-weight: bolder;
  display: inline-block;
  position: relative;
}

cutscene#game_cutscene.container #storyline_reward, cutscene#game_cutscene.container #storyline_reward_right {
  width: 100px;
  height: 100px;
  filter: brightness(1.1);
  background: linear-gradient(135deg, #ff3131 0%, #ff2a2a 50%, red 51%, #ac0000 100%) 99% / 250%;
  border-radius: 20px;
  padding: 10px;
  transition-duration: .2s;
  box-shadow: 0 3px 10px 2px #00000030;
}

cutscene#game_cutscene.container #storyline_reward:hover, cutscene#game_cutscene.container #storyline_reward_right:hover {
  perspective: 100px;
  background-position: 0;
  background-size: 110%;
  transform: scale(1.05);
}

cutscene#game_cutscene.container #storyline_reward_right {
  margin-left: auto;
  margin-right: 0;
}

cutscene#game_cutscene.container button#storyline_skip {
  right: var(--padding);
  margin: var(--padding);
  color: #00000050;
  pointer-events: all;
  cursor: pointer;
  min-width: -webkit-max-content;
  min-width: max-content;
  z-index: 2;
  border: 2px solid #00000050;
  border-radius: 5px;
  padding: .3em;
  font-size: 3vw;
  transition-duration: .2s;
  position: absolute;
  bottom: 15px;
}

cutscene#game_cutscene.container button#storyline_skip:hover {
  box-shadow: inset 0 0 0 2px #00000050;
}

.button-anim-opt-0 {
  box-shadow: 0px -1px inset #ffffff90, 0px 4px var(--button-shade);
}

.button-anim-opt-0:hover {
  box-shadow: 0px -1px inset #ffffff90, 0px 6px var(--button-shade);
  transform: translateY(-2px);
}

.button-anim-opt-0:active {
  box-shadow: 0px -1px inset #ffffff90, 0px 1px var(--button-shade);
  transform: translateY(3px);
}

.button-anim-opt-1 {
  border-bottom: 4px solid #00000090;
  margin-top: 2px;
  box-shadow: 0 2px 2px #00000030, inset 0 -1px #ffffff90;
}

.button-anim-opt-1:hover {
  border-bottom: 6px solid #00000090;
  margin-top: 0;
}

.button-anim-opt-1:active {
  border-bottom: 2px solid #00000090;
  margin-top: 4px;
}

.button-anim-opt-2 {
  border-top: solid 2px var(--button-background);
  border-bottom: 4px solid #00000090;
  box-shadow: 0 2px 2px #00000030, inset 0 -1px #ffffff90;
}

.button-anim-opt-2:hover {
  border-top: solid 0px var(--button-background);
  border-bottom: 6px solid #00000090;
}

.button-anim-opt-2:active {
  border-top: solid 5px var(--button-background);
  border-bottom: 1px solid #00000090;
}

popup.container {
  z-index: 8;
  width: 100%;
  height: 85%;
  pointer-events: auto;
  background: linear-gradient(0deg, #0000, #0000003b, #0000003b, #0000003b, #0000003b, #0000003b, #0000003b, #0000);
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: absolute;
}

popup.container.animated {
  animation: popup-appear-1 .5s ease-in-out;
}

popup.container popup_footer {
  z-index: 8;
  width: 200%;
  height: 50%;
  pointer-events: auto;
  background: linear-gradient(#0000, #0000);
  position: absolute;
  bottom: -30vh;
  left: -50%;
}

popup.container popup_bg {
  content: "";
  --padding: 30px;
  background-color: var(--color-5ka-b);
  width: 90%;
  height: calc(var(--height)  + var(--padding) * 2);
  border-radius: var(--radius-border-a);
  z-index: 9;
  --width: 90%;
  --height: 60vh;
  top: calc(56% - var(--height) / 2);
  left: calc(50% - var(--width) / 2);
  pointer-events: all;
  border-top: 5px solid #ffffff50;
  border-bottom: 5px solid #00000050;
  margin: auto;
  display: flex;
  position: absolute;
  box-shadow: 0 0 10px 2px #0003;
}

popup.container popup_inner {
  --width: 75vw;
  --height: 60vh;
  top: calc(60% - var(--height) / 2);
  left: calc(50% - var(--width) / 2);
  width: var(--width);
  height: var(--height);
  pointer-events: all;
  z-index: 10;
  margin: auto;
  display: flex;
  position: absolute;
}

popup.container popup_inner.area-style-itd5ka-0 {
  background-color: var(--color-5ka-white);
  border-radius: var(--radius-border-a);
  border-bottom: 5px solid #dbcfcb;
}

popup.container popup_inner.area-style-itd5ka-0:after {
  width: calc(var(--width) * .8);
  left: calc(var(--width) * .1);
  content: "";
  border-top: dotted 4vh var(--color-5ka-b);
  height: 0;
  position: absolute;
  top: -2vh;
}

popup.container popup_inner.area-style-itd5ka-0 label#header {
  z-index: 1;
  color: #fff;
  border-image: url("./res/img/decoration_flag-green.png") 46 / 46px;
  font-size: 6vw;
}

popup.container popup_inner.area-style-itd5ka-0 page {
  position: relative;
}

popup.container popup_inner.area-style-itd5ka-0 page:before {
  width: 100%;
  height: 26vw;
  content: "";
  border-radius: var(--radius-border-a) var(--radius-border-a) 0 0;
  background-color: #00000020;
  border-bottom: 2px solid #00000020;
  position: absolute;
  top: 0;
  left: 0;
}

popup.container popup_inner button#close_popup {
  background-color: #0000;
  border: none;
  transition-duration: .1s;
  display: flex;
  position: absolute;
  top: -65px;
  right: -65px;
  transform: scale(.5);
}

popup.container popup_inner button#close_popup:before {
  width: 96px;
  height: 95px;
  content: "";
  opacity: 1;
  filter: none;
  mix-blend-mode: normal;
  background-color: #0000;
  background-image: url("./res/img/textures.png");
  background-position: -1147px -1176px;
  background-repeat: no-repeat;
  margin: auto;
  display: inline-block;
  overflow: hidden;
  transform: scale(1);
}

popup.container popup_inner button#close_popup:active {
  transform: rotate(-15deg)scale(.7);
}

page#shell_log {
  height: 100%;
  width: 100%;
  padding: .3em;
  font-family: Azeret Mono, monospace;
  font-size: 14px;
  overflow-y: auto;
}

page label#header {
  color: gray;
  text-transform: uppercase;
  height: -webkit-max-content;
  height: max-content;
  text-align: center;
  border-bottom: 2px solid gray;
  margin: 0 auto;
  padding: 10px 40px;
  font-size: min(3rem, 8vw);
  font-weight: 600;
}

page, page * {
  scrollbar-width: none;
  font-family: "5ka Sans" !important;
}

page, page ::-webkit-scrollbar {
  width: 0;
}

page#shop #buttons, page#inventory #buttons, page#itd_reward #buttons, page#itd_room_details #buttons {
  width: 100%;
  height: 1.5em;
  min-height: 1.5em;
  justify-content: space-evenly;
  margin-top: 6vw;
  margin-bottom: 5px;
  font-size: 4vw;
  font-weight: 500;
  display: flex;
}

page#shop icon, page#inventory icon, page#itd_reward icon, page#itd_room_details icon {
  background-image: url("./res/img/textures.png");
  background-repeat: no-repeat;
  border-radius: 3px;
  display: inline-block;
  overflow: hidden;
}

page#shop icon.empty, page#inventory icon.empty, page#itd_reward icon.empty, page#itd_room_details icon.empty {
  width: 96px;
  height: 127px;
  height: 130px;
  background-position: -1147px -1047px;
  transform: scale(.7);
}

page#shop tabs, page#inventory tabs, page#itd_reward tabs, page#itd_room_details tabs {
  overflow-y: hidden;
}

page#shop tabs button, page#inventory tabs button, page#itd_reward tabs button, page#itd_room_details tabs button {
  background-color: #0000;
  border: none;
  font-size: 4vw;
  outline: none !important;
}

page#shop tabs button.selected, page#inventory tabs button.selected, page#itd_reward tabs button.selected, page#itd_room_details tabs button.selected, page#shop tabs button:active, page#inventory tabs button:active, page#itd_reward tabs button:active, page#itd_room_details tabs button:active {
  border-bottom: 4px solid gray;
}

page#shop tabs#content, page#inventory tabs#content, page#itd_reward tabs#content, page#itd_room_details tabs#content {
  flex-grow: 1;
}

page#shop tabs#content button, page#inventory tabs#content button, page#itd_reward tabs#content button, page#itd_room_details tabs#content button {
  min-width: 100px;
  background-color: var(--color-5ka-a);
  border-radius: calc(.7 * var(--radius-border-a) );
  color: var(--color-5ka-white);
  text-shadow: 0 2px 1px #00000090;
  text-align: center;
  background: linear-gradient(135deg, #ff3131 0%, #ff2a2a 50%, red 51%, #ac0000 100%) 99% / 250%;
  border: 3px solid #00000050;
  padding: 5px;
  font-weight: bolder;
  transition-duration: .2s;
  box-shadow: inset 0 0 0 4px #ffffff30;
  outline: none !important;
  font-family: "5ka Sans" !important;
}

page#shop tabs#content button:hover, page#inventory tabs#content button:hover, page#itd_reward tabs#content button:hover, page#itd_room_details tabs#content button:hover {
  transform: scale(1.05);
  box-shadow: inset 0 0 0 6px #ffffff30;
}

page#shop tabs#content button:active, page#inventory tabs#content button:active, page#itd_reward tabs#content button:active, page#itd_room_details tabs#content button:active {
  transform: scale(.95);
  box-shadow: inset 0 0 0 10px #ffffff30;
}

page#shop tabs#content button:hover, page#inventory tabs#content button:hover, page#itd_reward tabs#content button:hover, page#itd_room_details tabs#content button:hover {
  perspective: 100px;
  background-position: 0;
  background-size: 110%;
  transform: scale(1.05);
}

page#shop tabs#content button > *, page#inventory tabs#content button > *, page#itd_reward tabs#content button > *, page#itd_room_details tabs#content button > * {
  pointer-events: none;
}

page#shop tabs#content tab, page#inventory tabs#content tab, page#itd_reward tabs#content tab, page#itd_room_details tabs#content tab {
  flex-flow: wrap;
  justify-content: space-around;
  display: flex;
}

page#shop tabs#content entry, page#inventory tabs#content entry, page#itd_reward tabs#content entry, page#itd_room_details tabs#content entry {
  height: 47vw;
  width: 65%;
  background: linear-gradient(#ddd 0%, #eee 100%);
  border-radius: 10px;
  flex-direction: column;
  margin: -6vw -6vw -4vw;
  padding: 0;
  display: flex;
  position: relative;
  transform: scale(.7);
  box-shadow: 0 2px 2px gray;
}

page#shop tabs#content entry > *, page#inventory tabs#content entry > *, page#itd_reward tabs#content entry > *, page#itd_room_details tabs#content entry > * {
  margin: 1vw auto;
}

page#shop tabs#content entry h1, page#inventory tabs#content entry h1, page#itd_reward tabs#content entry h1, page#itd_room_details tabs#content entry h1 {
  text-align: center;
}

page#shop tabs#content entry.disabled, page#inventory tabs#content entry.disabled, page#itd_reward tabs#content entry.disabled, page#itd_room_details tabs#content entry.disabled {
  pointer-events: none;
}

page#shop tabs#content entry.disabled progressbar, page#inventory tabs#content entry.disabled progressbar, page#itd_reward tabs#content entry.disabled progressbar, page#itd_room_details tabs#content entry.disabled progressbar, page#shop tabs#content entry.disabled label#level, page#inventory tabs#content entry.disabled label#level, page#itd_reward tabs#content entry.disabled label#level, page#itd_room_details tabs#content entry.disabled label#level {
  display: none;
}

page#shop tabs#content entry .amount_container, page#inventory tabs#content entry .amount_container, page#itd_reward tabs#content entry .amount_container, page#itd_room_details tabs#content entry .amount_container {
  width: 90%;
  display: flex;
}

page#shop tabs#content entry .amount_container label#amount, page#inventory tabs#content entry .amount_container label#amount, page#itd_reward tabs#content entry .amount_container label#amount, page#itd_room_details tabs#content entry .amount_container label#amount {
  margin: auto;
}

page#shop tabs#content entry progressbar#exp_bar, page#inventory tabs#content entry progressbar#exp_bar, page#itd_reward tabs#content entry progressbar#exp_bar, page#itd_room_details tabs#content entry progressbar#exp_bar {
  height: 5vw;
  color: #414042;
  text-shadow: 0 1px #ffffff90;
  --progress: 0%;
  width: 59%;
  height: 7vw;
  text-shadow: 0 2px 2px #fff;
  background-color: #98b29b;
  border-radius: 4px;
  font-size: 5vw;
  font-weight: 500;
  position: relative;
  top: 1vw;
  box-shadow: 0 2px #435445;
}

page#shop tabs#content entry progressbar#exp_bar:before, page#inventory tabs#content entry progressbar#exp_bar:before, page#itd_reward tabs#content entry progressbar#exp_bar:before, page#itd_room_details tabs#content entry progressbar#exp_bar:before {
  background-color: #3eb953;
  border-bottom: 1px solid #ffffff90;
  border-radius: 4px;
  box-shadow: 0 2px #066f2b;
}

page#shop tabs#content entry label#amount, page#inventory tabs#content entry label#amount, page#itd_reward tabs#content entry label#amount, page#itd_room_details tabs#content entry label#amount {
  color: #fff;
  text-shadow: 0 2px 1px #00000090;
  height: -webkit-min-content;
  height: min-content;
  background: linear-gradient(135deg, #ff3131 0%, #ff2a2a 50%, red 51%, #ac0000 100%);
  border-top: 1px solid #ffffff90;
  border-radius: 4px;
  padding: 2px .5em;
  font-size: 4vw;
  font-weight: 600;
  position: absolute;
  top: 20px;
  left: 5px;
}

page#shop tabs#content entry label#level, page#inventory tabs#content entry label#level, page#itd_reward tabs#content entry label#level, page#itd_room_details tabs#content entry label#level {
  color: #fff;
  text-shadow: 0 2px 1px #00000090;
  height: -webkit-min-content;
  height: -webkit-min-content;
  height: min-content;
  background: linear-gradient(135deg, #008731 0%, #01a13b 50%, #008f32 51% 100%);
  border-top: 1px solid #ffffff90;
  border-radius: 4px;
  padding: 2px .5em;
  font-size: 4vw;
  font-weight: 600;
  position: absolute;
  top: -10px;
  left: 5px;
}

page#shop tabs#content entry label#name, page#inventory tabs#content entry label#name, page#itd_reward tabs#content entry label#name, page#itd_room_details tabs#content entry label#name {
  text-align: center;
  margin-top: -6vw;
  padding: 4px;
  font-size: 4.5vw;
  font-weight: 400;
}

page#shop tabs#content entry icon, page#inventory tabs#content entry icon, page#itd_reward tabs#content entry icon, page#itd_room_details tabs#content entry icon {
  justify-content: center;
  margin: auto auto -1vw;
  display: flex;
}

page#shop tabs#content entry icon.disabled, page#inventory tabs#content entry icon.disabled, page#itd_reward tabs#content entry icon.disabled, page#itd_room_details tabs#content entry icon.disabled {
  filter: grayscale();
}

page#shop tabs#content entry button, page#inventory tabs#content entry button, page#itd_reward tabs#content entry button, page#itd_room_details tabs#content entry button {
  margin-bottom: -16px;
  padding: 3px;
  font-size: 7vw;
}

page#shop tabs#content entry button.buy, page#inventory tabs#content entry button.buy, page#itd_reward tabs#content entry button.buy, page#itd_room_details tabs#content entry button.buy {
  background: linear-gradient(135deg, #3b679e 0%, #2b88d9 50%, #207cca 51%, #7db9e8 100%) 99% / 250%;
  justify-content: center;
  align-self: center;
  margin-top: 48.5vw;
  font-size: 7vw;
  transition-duration: .2s;
  display: flex;
  position: absolute;
}

page#shop tabs#content entry button.buy:hover, page#inventory tabs#content entry button.buy:hover, page#itd_reward tabs#content entry button.buy:hover, page#itd_room_details tabs#content entry button.buy:hover {
  perspective: 100px;
  background-position: 0;
  background-size: 110%;
  transform: scale(1.05);
}

page#shop tabs#content entry button.buy a, page#inventory tabs#content entry button.buy a, page#itd_reward tabs#content entry button.buy a, page#itd_room_details tabs#content entry button.buy a {
  min-width: 30px;
  text-align: left;
  z-index: 1;
  margin-left: 0%;
}

page#shop tabs#content entry button.buy:before, page#inventory tabs#content entry button.buy:before, page#itd_reward tabs#content entry button.buy:before, page#itd_room_details tabs#content entry button.buy:before {
  z-index: 0;
  content: "";
  width: 36px;
  height: 33px;
  background-image: url("./res/img/textures.png");
  background-position: -967px -1349px;
  background-repeat: no-repeat;
  margin: auto 0;
  display: inline-block;
  overflow: hidden;
}

page#shop tabs#content entry button.buy button#upgrade_relic, page#inventory tabs#content entry button.buy button#upgrade_relic, page#itd_reward tabs#content entry button.buy button#upgrade_relic, page#itd_room_details tabs#content entry button.buy button#upgrade_relic {
  top: -10vw;
}

page#shop tabs#content entry, page#inventory tabs#content entry, page#itd_reward tabs#content entry, page#itd_room_details tabs#content entry {
  transition-duration: .2s;
}

page#shop tabs#content entry:active, page#inventory tabs#content entry:active, page#itd_reward tabs#content entry:active, page#itd_room_details tabs#content entry:active {
  transform: scale(.75);
  box-shadow: 0 7px 7px #80808077;
}

page#shop tabs#content entry.item_rarity.default, page#inventory tabs#content entry.item_rarity.default, page#itd_reward tabs#content entry.item_rarity.default, page#itd_room_details tabs#content entry.item_rarity.default {
  background: linear-gradient(#adadad45 0%, #d8d8d83d 100%);
}

page#shop tabs#content entry.item_rarity.common, page#inventory tabs#content entry.item_rarity.common, page#itd_reward tabs#content entry.item_rarity.common, page#itd_room_details tabs#content entry.item_rarity.common {
  background: linear-gradient(#9b9b9b45 0%, #cacaca3d 100%);
}

page#shop tabs#content entry.item_rarity.uncommon, page#inventory tabs#content entry.item_rarity.uncommon, page#itd_reward tabs#content entry.item_rarity.uncommon, page#itd_room_details tabs#content entry.item_rarity.uncommon {
  background: linear-gradient(#57619c45 0%, #5985ff3d 100%);
}

page#shop tabs#content entry.item_rarity.rare, page#inventory tabs#content entry.item_rarity.rare, page#itd_reward tabs#content entry.item_rarity.rare, page#itd_room_details tabs#content entry.item_rarity.rare {
  background: linear-gradient(#9c925745 0%, #fff4593d 100%);
}

page#shop tabs#content entry.item_rarity.unique, page#inventory tabs#content entry.item_rarity.unique, page#itd_reward tabs#content entry.item_rarity.unique, page#itd_room_details tabs#content entry.item_rarity.unique {
  background: linear-gradient(#81579c45 0%, #af59ff3d 100%);
}

page#shop tabs#content entry {
  margin-bottom: 5vw;
}

page#shop tabs#content entry .shop_entry_label {
  pointer-events: none;
  place-self: center;
  margin: auto;
  position: absolute;
  top: 40vw;
}

page#shop tabs#content entry icon {
  margin: 0;
}

page#shop tabs {
  margin-bottom: -3vw;
}

page#shop .shop_entry_icon_wrapper {
  width: 100%;
  height: 80%;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

page#inventory entry *, page#itd_reward entry *, page#itd_room_details entry * {
  pointer-events: none;
}

page#inventory progressbar, page#itd_reward progressbar, page#itd_room_details progressbar {
  width: 59%;
  height: 7vw;
  text-shadow: 0 2px 2px #fff;
  font-size: 5vw;
  font-weight: 500;
  position: relative;
  top: 1vw;
}

page#itd_reset {
  color: var(--color-5ka-white);
}

page#itd_reset button {
  background-color: var(--color-5ka-a);
  border-radius: calc(.7 * var(--radius-border-a) );
  color: var(--color-5ka-white);
  text-shadow: 0 2px 1px #00000090;
  text-align: center;
  background: linear-gradient(135deg, #ff3131 0%, #ff2a2a 50%, red 51%, #ac0000 100%) 99% / 250%;
  border: 3px solid #00000050;
  margin: auto;
  padding: 5px;
  font-size: 2em;
  font-weight: 600;
  transition-duration: .2s;
  box-shadow: inset 0 0 0 4px #ffffff30;
  outline: none !important;
  font-family: "5ka Sans" !important;
}

page#itd_reset button:hover {
  transform: scale(1.05);
  box-shadow: inset 0 0 0 6px #ffffff30;
}

page#itd_reset button:active {
  transform: scale(.95);
  box-shadow: inset 0 0 0 10px #ffffff30;
}

page#itd_reset button:hover {
  perspective: 100px;
  background-position: 0;
  background-size: 110%;
  transform: scale(1.05);
}

page#itd_reset button#confirm {
  font-size: 4vw;
}

page#itd_reset label#description {
  color: #ab2b4f;
  align-items: center;
  margin: auto;
  font-size: 8vw;
  font-weight: 600;
  display: flex;
}

page#itd_reset label#description:before {
  content: "";
  width: 81px;
  height: 74px;
  background-image: url("./res/img/textures.png");
  background-position: -1564px -792px;
  background-repeat: no-repeat;
  margin: auto -2vw auto -5vw;
  display: inline-block;
  overflow: hidden;
  transform: scale(.5);
}

page#itd_reset background#boss {
  width: 100%;
  height: 340px;
  background-image: url("./res/img/boss-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

page#message container.flex-simple {
  flex-direction: column;
  display: flex;
}

page#message container.flex-simple > * {
  margin: auto;
}

page#message, page#shop, page#inventory, page#settings, page#itd_reset, page#itd_room_details, page#itd_employee_details, page#itd_battle_prepare, page#itd_pvp_prepare, page#itd_pvp_results, page#itd_reward, page#hint_pvp, page#hint_battle, page#hint_inventory, page#hint_shop, page#hint_hello, page#hint_reset {
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 2em .5em .5em;
  font-size: 5vw;
  display: flex;
}

page#message text.container, page#shop text.container, page#inventory text.container, page#settings text.container, page#itd_reset text.container, page#itd_room_details text.container, page#itd_employee_details text.container, page#itd_battle_prepare text.container, page#itd_pvp_prepare text.container, page#itd_pvp_results text.container, page#itd_reward text.container, page#hint_pvp text.container, page#hint_battle text.container, page#hint_inventory text.container, page#hint_shop text.container, page#hint_hello text.container, page#hint_reset text.container {
  align-self: center;
  padding: 20px;
}

page#settings container {
  flex-flow: wrap;
  justify-content: center;
  gap: 1em;
  margin: auto;
  display: flex;
}

page#settings container button#sound {
  margin: auto;
}

page#settings container button#sound:before {
  width: 69px;
  height: 70px;
  background-position: -1624px -1048px;
}

page#settings container button#sound.muted:before {
  width: 38px;
  height: 70px;
  background-position: -1465px -463px;
  margin-left: -12px;
}

page#settings container button#music {
  margin: auto;
}

page#settings container button#music:before {
  width: 65px;
  height: 71px;
  background-position: -1695px -1048px;
  margin-left: -3px;
}

page#settings container button#music.muted:before {
  width: 82px;
  height: 82px;
  background-position: -1588px -614px;
  margin-left: 0;
}

page#settings container button#help {
  background: linear-gradient(135deg, #3b679e 0%, #2b88d9 50%, #207cca 51%, #7db9e8 100%) 99% / 250%;
  margin: auto;
  padding: 0;
  font-size: 400%;
  transition-duration: .2s;
}

page#settings container button#help:before {
  content: "?";
  background-image: none;
  margin: auto;
  font-size: 6rem;
  position: absolute;
}

page#settings container button#help:hover {
  perspective: 100px;
  background-position: 0;
  background-size: 110%;
  transform: scale(1.05);
}

page#settings container:before, page#settings container:after {
  content: "";
  width: 100%;
  order: 1;
}

page#settings container button {
  width: 80px;
  height: 80px;
  background-color: var(--color-5ka-a);
  border-radius: calc(.7 * var(--radius-border-a) );
  color: var(--color-5ka-white);
  text-shadow: 0 2px 1px #00000090;
  text-align: center;
  background: linear-gradient(135deg, #ff3131 0%, #ff2a2a 50%, red 51%, #ac0000 100%) 99% / 250%;
  border: 3px solid #00000050;
  padding: 5px;
  font-weight: bolder;
  transition-duration: .2s;
  display: block;
  box-shadow: inset 0 0 0 4px #ffffff30;
  outline: none !important;
  font-family: "5ka Sans" !important;
}

page#settings container button:before {
  content: "";
  background-image: url("./res/img/textures.png");
  background-repeat: no-repeat;
  margin: auto;
  display: inline-block;
  position: absolute;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(.8);
}

page#settings container button:hover {
  transform: scale(1.05);
  box-shadow: inset 0 0 0 6px #ffffff30;
}

page#settings container button:active {
  transform: scale(.95);
  box-shadow: inset 0 0 0 10px #ffffff30;
}

page#settings container button:hover {
  perspective: 100px;
  background-position: 0;
  background-size: 110%;
  transform: scale(1.05);
}

page#settings container div#meta_info {
  z-index: 1;
  min-width: 100%;
  justify-items: center;
  font-size: 3vw;
  font-weight: 400;
  display: grid;
  position: relative;
  bottom: -14vw;
}

page#journal {
  flex-direction: column;
  flex-grow: 1;
  gap: 5px;
  padding: 30px 10px;
  display: flex;
}

page#journal container#journal {
  margin-top: 6vw;
  margin-bottom: -5vw;
  overflow-y: scroll;
}

page#journal itd5ka.journal_entry {
  border-top: 10px solid var(--color-5ka-b);
  pointer-events: none;
  background-color: #fff;
  border-radius: 5px;
  justify-content: space-between;
  margin-bottom: 2vw;
  display: flex;
  box-shadow: 0 2px 2px #00000010;
}

page#journal itd5ka.journal_entry.completed {
  pointer-events: all;
  border-top: 10px solid var(--color-5ka-c);
}

page#journal itd5ka.journal_entry.completed * {
  pointer-events: none;
}

page#journal itd5ka.journal_entry.collected {
  opacity: .7;
  border-top: 10px solid gray;
}

page#journal itd5ka.journal_entry icon#jentry {
  background-image: url("./res/img/textures.png");
  background-repeat: no-repeat;
  margin-left: -3vw;
  padding: 0;
  display: inline-block;
  overflow: hidden;
  transform: scale(.7);
}

page#journal itd5ka.journal_entry #labels {
  max-width: 50%;
  flex-direction: column;
  align-items: start;
  margin: 5vw auto 5vw 0;
  padding-right: 4vw;
  display: inline-flex;
  transform: scale(1.2);
}

page#journal itd5ka.journal_entry #labels label {
  margin: auto 0;
  font-size: 2.5vw;
  font-weight: 600;
}

page#journal itd5ka.journal_entry #labels #description {
  margin: auto;
  font-size: 2.6vw;
  font-weight: 300;
}

page#journal itd5ka.journal_entry.completed icon#jentry_status:before {
  width: 50px;
  height: 50px;
  background-position: -1494px -694px;
}

page#journal itd5ka.journal_entry.collected icon#jentry_status:before {
  width: 50px;
  height: 48px;
  background-position: -1709px -262px;
}

page#journal itd5ka.journal_entry icon#jentry_status {
  --size: 30px;
  width: 10vw;
  height: 10vw;
  border: 2px solid #00000020;
  border-radius: 4px;
  margin: auto;
  display: block;
  position: relative;
  transform: scale(.8);
}

page#journal itd5ka.journal_entry icon#jentry_status:before {
  content: "";
  background-image: url("./res/img/textures.png");
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

page#itd_employee_details entry.rarity, page#inventory entry.rarity, page#itd_reward entry.rarity, page#itd_room_details entry.rarity, page#shop entry.rarity, page#itd_employee_details label.rarity, page#inventory label.rarity, page#itd_reward label.rarity, page#itd_room_details label.rarity, page#shop label.rarity {
  color: var(--color-5ka-white);
  text-shadow: -2px -1px 4px #00000087, 1px -2px 4px #00000087, -2px 2px 4px #00000087, 2px 2px 4px #00000087;
  border: 0;
  margin-bottom: 1vw;
  font-size: 3.5vw;
}

page#itd_employee_details entry.rarity.default, page#inventory entry.rarity.default, page#itd_reward entry.rarity.default, page#itd_room_details entry.rarity.default, page#shop entry.rarity.default, page#itd_employee_details label.rarity.default, page#inventory label.rarity.default, page#itd_reward label.rarity.default, page#itd_room_details label.rarity.default, page#shop label.rarity.default {
  filter: grayscale();
  background: linear-gradient(135deg, #ffb76b 0%, #ffa73d 50%, #ff7c00 51%, #ff7f04 100%);
}

page#itd_employee_details entry.rarity.common, page#inventory entry.rarity.common, page#itd_reward entry.rarity.common, page#itd_room_details entry.rarity.common, page#shop entry.rarity.common, page#itd_employee_details label.rarity.common, page#inventory label.rarity.common, page#itd_reward label.rarity.common, page#itd_room_details label.rarity.common, page#shop label.rarity.common {
  filter: grayscale();
  background: linear-gradient(135deg, #008731 0%, #01a13b 50%, #008f32 51% 100%);
}

page#itd_employee_details entry.rarity.uncommon, page#inventory entry.rarity.uncommon, page#itd_reward entry.rarity.uncommon, page#itd_room_details entry.rarity.uncommon, page#shop entry.rarity.uncommon, page#itd_employee_details label.rarity.uncommon, page#inventory label.rarity.uncommon, page#itd_reward label.rarity.uncommon, page#itd_room_details label.rarity.uncommon, page#shop label.rarity.uncommon {
  background: linear-gradient(135deg, #3b679e 0%, #2b88d9 50%, #207cca 51%, #7db9e8 100%);
}

page#itd_employee_details entry.rarity.rare, page#inventory entry.rarity.rare, page#itd_reward entry.rarity.rare, page#itd_room_details entry.rarity.rare, page#shop entry.rarity.rare, page#itd_employee_details label.rarity.rare, page#inventory label.rarity.rare, page#itd_reward label.rarity.rare, page#itd_room_details label.rarity.rare, page#shop label.rarity.rare {
  background: linear-gradient(135deg, #ffb76b 0%, #ffa73d 50%, #ff7c00 51%, #ff7f04 100%);
}

page#itd_employee_details entry.rarity.unique, page#inventory entry.rarity.unique, page#itd_reward entry.rarity.unique, page#itd_room_details entry.rarity.unique, page#shop entry.rarity.unique, page#itd_employee_details label.rarity.unique, page#inventory label.rarity.unique, page#itd_reward label.rarity.unique, page#itd_room_details label.rarity.unique, page#shop label.rarity.unique {
  filter: hue-rotate(60deg);
  background: linear-gradient(135deg, #3b679e 0%, #2b88d9 50%, #207cca 51%, #7db9e8 100%);
}

page#itd_employee_details label.rarity, page#inventory label.rarity, page#itd_reward label.rarity, page#itd_room_details label.rarity, page#shop label.rarity {
  text-align: center;
  width: 100%;
  border-radius: 0 0 10px 10px;
  align-self: center;
  padding: 2vw;
  font-weight: 500;
  position: relative;
  bottom: -.5vw;
}

page#itd_employee_details entry.rarity {
  text-align: center;
  border-radius: 3px;
  margin: auto;
  padding: .5vw 2vw;
  font-weight: 300;
}

page#itd_employee_details container {
  height: 100%;
  grid-template: "icon description description" 60%
                 "params params params" 30%
                 "labels labels labels"
                 / 34% 56%;
  gap: 5px;
  padding: 0;
  display: grid;
}

page#itd_employee_details .icon_container {
  flex-direction: column;
  grid-area: icon;
  margin-top: 5vw;
  display: flex;
}

page#itd_employee_details .icon_container .icon {
  align-self: center;
  transform: scale(.9);
}

page#itd_employee_details .icon_container .icon icon {
  z-index: 1;
  background-image: url("./res/img/textures.png");
  background-repeat: no-repeat;
  margin: auto;
  display: inline-block;
  overflow: hidden;
}

page#itd_employee_details description {
  border-radius: var(--radius-border-a);
  flex-direction: column;
  grid-area: description;
  margin-top: 5vw;
  font-size: 3vw;
  display: flex;
}

page#itd_employee_details description label#name {
  color: var(--color-5ka-white);
  background-color: var(--color-5ka-b);
  text-shadow: 0 2px 1px #00000090;
  border-radius: 5px 5px 0 0;
  padding: 4px;
  font-weight: 500;
}

page#itd_employee_details description text#employee_description {
  overflow-wrap: break-word;
  height: 100%;
  background-color: #efe;
  border-radius: 0 0 5px 5px;
  padding: 4px;
  font-weight: 300;
}

page#itd_employee_details stats {
  text-align: center;
  position: relative;
  top: -1vw;
}

page#itd_employee_details stats entry {
  text-align: center;
  background-color: #eee;
  border-radius: 3px;
  margin: auto;
  font-size: 3vw;
  display: flow-root;
}

page#itd_employee_details stats progressbar {
  height: 5vw;
  color: #414042;
  text-shadow: 0 1px #ffffff90;
  --progress: 0%;
  background-color: #98b29b;
  border-radius: 4px;
  margin: 1vw auto auto;
  font-size: 4vw;
  font-weight: 500;
  box-shadow: 0 2px #435445;
}

page#itd_employee_details stats progressbar#level.expirience:before {
  background-color: #67d47a;
  border-bottom: 1px solid #ffffff90;
  box-shadow: 0 2px #066f2b;
}

page#itd_employee_details stats progressbar#energy.health:before {
  background-color: #ff7166;
  border-bottom: 1px solid #ffffff90;
  box-shadow: 0 2px #b60b0f;
}

page#itd_employee_details stats progressbar#energy.charges:before {
  background-color: #34aaff;
  border-bottom: 1px solid #ffffff90;
  box-shadow: 0 2px #404455;
}

page#itd_employee_details stats progressbar#level:before, page#itd_employee_details stats progressbar#energy:before {
  border-radius: 4px;
}

page#itd_employee_details stats progressbar#level:after, page#itd_employee_details stats progressbar#energy:after {
  width: 50px;
  border-radius: 3px;
  padding: 3px;
  font-size: 3vw;
  position: absolute;
  left: -36px;
}

page#itd_employee_details params {
  flex-direction: column;
  grid-area: params;
  display: flex;
  position: relative;
}

page#itd_employee_details params label {
  color: #fff;
  text-shadow: 0 2px 1px #00000090;
  background-color: gray;
  border-radius: 5px 5px 0 0;
  padding: 3px 5px;
  font-size: 3vw;
  font-weight: 500;
}

page#itd_employee_details params pcontainer {
  height: 74%;
  background-color: #eee;
  border-radius: 0 0 5px 5px;
  padding: 2px;
  font-size: 3vw;
  font-weight: 300;
}

page#itd_employee_details params pcontainer badges {
  flex-flow: column wrap;
  display: flex;
}

page#itd_employee_details params pcontainer badges entry {
  width: 8vw;
  height: 8vw;
  background-color: #98b29b;
  border-radius: 100%;
  margin-top: 1vw;
  margin-left: 5vw;
  display: block;
}

page#itd_employee_details params pcontainer badges entry:before {
  content: "";
  background-image: url("./res/img/textures.png");
  background-repeat: no-repeat;
  margin-top: 50%;
  margin-left: 50%;
  display: inline-block;
  overflow: hidden;
  transform: translate(-50%, -50%)scale(.5);
}

page#itd_employee_details params pcontainer badges entry.profit_bonus:before {
  width: 54px;
  height: 60px;
  background-position: -1704px -1186px;
}

page#itd_employee_details params pcontainer badges entry.upgrade_bonus:before {
  width: 57px;
  height: 60px;
  background-position: -1529px -594px;
}

page#itd_employee_details params pcontainer badges entry.threshold_bonus:before {
  width: 63px;
  height: 60px;
  background-position: -1532px -330px;
}

page#itd_employee_details params pcontainer badges entry.click_bonus:before {
  width: 62px;
  height: 60px;
  background-position: -967px -1287px;
}

page#itd_employee_details params pcontainer badges entry.heal_bonus:before {
  width: 266px;
  height: 249px;
  background-position: -277px -518px;
  transform: translate(-50%, -50%)scale(.1);
}

page#itd_employee_details params pcontainer #entries {
  position: absolute;
  top: 6vw;
  left: 27vw;
}

page#itd_employee_details labels {
  flex-direction: row;
  grid-area: labels;
  gap: 5px;
  margin-top: -2vw;
  margin-bottom: auto;
  font-weight: 500;
  display: flex;
  position: relative;
}

page#itd_employee_details labels entry {
  text-align: center;
  width: 30%;
  height: 9vw;
  white-space: nowrap;
  margin-left: 0;
  padding: 7px 3px 3px;
  font-size: 3vw;
  position: relative;
  top: 0;
  left: 0;
  box-shadow: inset 0 -1px #ffffff90, 0 4px #838383;
}

page#itd_employee_details labels button {
  background-color: var(--color-5ka-a);
  --button-shade: #660f0a;
  color: var(--color-5ka-white);
  text-shadow: 0 2px 1px #00000090;
  text-align: center;
  width: 35%;
  box-shadow: 0px -1px inset #ffffff90, 0px 4px var(--button-shade);
  border-radius: 5px;
  margin-left: auto;
  margin-right: 0;
  padding: 4px;
  font-size: 3vw;
}

page#itd_employee_details labels button:hover {
  box-shadow: 0px -1px inset #ffffff90, 0px 6px var(--button-shade);
  transform: translateY(-2px);
}

page#itd_employee_details labels button:active {
  box-shadow: 0px -1px inset #ffffff90, 0px 1px var(--button-shade);
  transform: translateY(3px);
}

page#itd_employee_details labels button#assign_btn {
  width: 35%;
}

page#itd_battle_prepare #start_btn {
  max-width: -webkit-max-content;
  max-width: max-content;
  margin: auto auto 10vw;
  padding: 10px;
  font-size: 6vw;
}

page#itd_battle_prepare container {
  background-color: #00000020;
  justify-content: center;
  align-self: center;
  margin: 40px 10px 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  position: relative;
}

page#itd_battle_prepare container:after {
  content: "";
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-repeat: no-repeat;
  position: absolute;
}

page#itd_battle_prepare container .task_icon {
  cursor: pointer;
  width: 128px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  justify-content: center;
  margin: -5vw -45px -5vw -41px;
  transition-duration: .1s;
  display: flex;
  transform: scale(.35);
}

page#itd_battle_prepare container .task_icon > * {
  pointer-events: none;
}

page#itd_battle_prepare container .task_icon .task_icon_image {
  align-self: center;
}

page#itd_battle_prepare container .task_icon.available .task_icon_image {
  width: 128px;
  height: 126px;
  background-image: url("./res/img/textures.png");
  background-position: -1114px -474px;
  background-repeat: no-repeat;
  display: inline-block;
  overflow: hidden;
}

page#itd_battle_prepare container .task_icon.available:nth-child(n+4) .task_icon_image {
  width: 107px;
  height: 126px;
  background-image: url("./res/img/textures.png");
  background-position: -1247px -955px;
  background-repeat: no-repeat;
  display: inline-block;
  overflow: hidden;
}

page#itd_battle_prepare container .task_icon.unavailable {
  pointer-events: none;
}

page#itd_battle_prepare container .task_icon.unavailable .task_icon_image {
  width: 48px;
  height: 66px;
  background-image: url("./res/img/textures.png");
  background-position: -1709px -194px;
  background-repeat: no-repeat;
  display: inline-block;
  overflow: hidden;
}

page#itd_battle_prepare container .task_icon.deselected {
  opacity: .5;
  filter: grayscale();
  transition-duration: .1s;
  transform: scale(.35);
}

page#itd_battle_prepare container .task_icon.deselected p {
  font-weight: 300;
}

page#itd_battle_prepare container .task_icon p {
  color: #ffb1b1;
  pointer-events: none;
  width: 128px;
  text-shadow: 1px 1px #0009, 0 -1px #fee, -1px 0 #feea;
  margin: auto 0 -.2em auto;
  font-size: 3em;
  font-weight: 600;
}

page#itd_battle_prepare container .task_icon:active {
  transform: translateX(-3vw) scale(calc(var(--scale-js) * 1.4) );
}

page#itd_pvp_prepare button#hint, page#itd_battle_prepare button#hint, page#shop button#hint, page#inventory button#hint, page#itd_reset button#hint {
  width: 50px;
  height: 50px;
  z-index: 1;
  background: linear-gradient(135deg, #3b679e 0%, #2b88d9 50%, #207cca 51%, #7db9e8 100%) 99% / 250%;
  padding: 0;
  font-size: 30px;
  transition-duration: .2s;
  position: absolute;
  top: -20px;
  left: -20px;
}

page#itd_pvp_prepare button#hint:hover, page#itd_battle_prepare button#hint:hover, page#shop button#hint:hover, page#inventory button#hint:hover, page#itd_reset button#hint:hover {
  perspective: 100px;
  background-position: 0;
  background-size: 110%;
  transform: scale(1.05);
}

.hint-page p {
  padding-top: .5em;
  font-size: 1.2em;
  font-weight: 300;
}

.hint-page container {
  margin-top: 6vw;
  padding: 10px;
  font-size: 3vw;
  overflow: scroll;
}

.hint-page strong {
  font-weight: bolder;
}

.hint-page em {
  font-style: italic;
}

.hint-page h1 {
  text-align: center;
  padding-top: .5em;
  font-size: 2em;
}

.hint-page h2 {
  text-align: center;
  padding-top: .5em;
  font-size: 1.5em;
}

page#itd_pvp_prepare container, page#itd_pvp_results container {
  flex-direction: column;
  margin: auto;
  display: flex;
}

page#itd_pvp_prepare container > *, page#itd_pvp_results container > * {
  margin: auto;
}

page#itd_pvp_prepare container label#player_rating, page#itd_pvp_results container label#player_rating {
  font-size: 1em;
}

page#itd_pvp_prepare container icon#player_rank, page#itd_pvp_results container icon#player_rank, page#itd_pvp_prepare container icon#pvp_reward, page#itd_pvp_results container icon#pvp_reward {
  background-image: url("./res/img/textures.png");
  background-repeat: no-repeat;
  display: inline-block;
  overflow: hidden;
}

page#itd_pvp_prepare container icon#player_rank, page#itd_pvp_results container icon#player_rank {
  overflow: visible;
}

page#itd_pvp_prepare container [hover-label]:after, page#itd_pvp_results container [hover-label]:after {
  white-space: break-spaces;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 4vw;
  position: relative;
}

page#itd_pvp_prepare container icon#pvp_reward, page#itd_pvp_results container icon#pvp_reward {
  transform: scale(.7);
}

page#itd_pvp_prepare container button#open_reward, page#itd_pvp_results container button#open_reward {
  font-size: 1.5em;
}

page#itd_pvp_prepare container decor#searching, page#itd_pvp_results container decor#searching {
  opacity: 0;
  flex-direction: column;
  display: flex;
}

page#itd_pvp_prepare container decor#searching > *, page#itd_pvp_results container decor#searching > * {
  margin: auto;
}

page#itd_pvp_prepare container decor#searching label, page#itd_pvp_results container decor#searching label {
  font-size: .7em;
}

page#itd_pvp_prepare container label#pvp_start_warn, page#itd_pvp_results container label#pvp_start_warn {
  height: 20px;
  font-size: .7em;
}

page#itd_pvp_prepare container button#start_btn, page#itd_pvp_results container button#start_btn {
  padding: 10px;
  font-size: 4vw;
}

page#itd_pvp_prepare.searching container decor#searching, page#itd_pvp_results.searching container decor#searching {
  opacity: 1;
}

page#itd_pvp_prepare.searching container button#start_btn, page#itd_pvp_results.searching container button#start_btn {
  pointer-events: none;
  filter: grayscale();
}

page#itd_pvp_prepare .loader, page#itd_pvp_results .loader {
  width: 100px;
  height: 100px;
  border: 0 solid #0000;
  border-radius: 50%;
}

page#itd_pvp_prepare .loader:before, page#itd_pvp_results .loader:before, page#itd_pvp_prepare .loader:after, page#itd_pvp_results .loader:after {
  content: "";
  width: inherit;
  height: inherit;
  opacity: 0;
  border: 7px solid #ccc;
  border-radius: 50%;
  animation: loader 2s linear infinite;
  position: absolute;
}

page#itd_pvp_prepare .loader:before, page#itd_pvp_results .loader:before {
  animation-delay: 1s;
}

@keyframes loader {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

page#itd_pvp_results {
  font-weight: 600;
}

page#itd_pvp_results container {
  flex-direction: column;
  margin: auto;
  display: flex;
}

page#itd_pvp_results container > * {
  margin: 2vw auto auto;
}

page#itd_pvp_results container label#player_rating {
  margin-top: 0;
  font-size: 4vw;
}

page#itd_pvp_results container label#pvp_result {
  text-align: center;
}

page#itd_pvp_results container icon#player_rank, page#itd_pvp_results container icon#pvp_reward {
  background-image: url("./res/img/textures.png");
  background-repeat: no-repeat;
  font-size: 5vw;
  font-weight: 400;
  display: inline-block;
  overflow: visible;
  transform: scale(.8);
}

page#itd_pvp_results container icon#pvp_reward {
  margin: auto;
  padding: 5px 15px;
  transform: scale(.7);
}

page#itd_pvp_results container button#open_reward {
  font-size: 5vw;
}

popup#message container.battle-results {
  flex-direction: column;
  display: flex;
}

popup#message container.battle-results > * {
  margin: auto;
}

popup#message container.battle-results div.battle-reward-container {
  min-height: 12rem;
  gap: 1vw;
  margin-left: auto;
  display: flex;
  position: relative;
}

popup#message container.battle-results icon.gems-icon {
  margin-top: 18px;
  display: flex;
  position: relative;
  overflow: visible;
  transform: scale(.85);
}

popup#message container.battle-results icon.lootpack-icon {
  display: flex;
  position: relative;
  overflow: visible;
  transform: scale(.65);
}

popup#message container.battle-results label.gems {
  color: #ff2777;
  text-shadow: 0 1px #000, 0 -2px 1px #ffb6b6;
  font-size: 2rem;
  font-weight: 600;
  position: absolute;
  bottom: -.5rem;
  right: -1.5rem;
}

popup#message container.battle-results label.duration {
  margin-bottom: 8vw;
  padding-top: 5vw;
  font-size: 6vw;
  font-weight: 700;
}

popup#message container.battle-results label.header {
  font-size: 4vw;
}

popup#message container.battle-results button#open_reward {
  font-size: 5vw;
}

popup#message container.battle-warn-message {
  font-size: 4vw;
  position: relative;
  top: 5vw;
}

popup#message container.battle-warn-message li:before {
  content: "⨯ ";
  color: red;
}

popup#message container.battle-warn-message li[checked]:before {
  content: "✓ ";
  color: green;
}

popup#message container.battle-warn-message li[checked] {
  opacity: .5;
  text-decoration: line-through;
}

page#itd_reward div#lootpack {
  transform: translateY(30%)scale(.45);
}

page#itd_reward div#lootpack icon#lootpack {
  background-image: url("./res/img/textures.png");
  background-repeat: no-repeat;
  margin: auto;
  display: inline-block;
  overflow: hidden;
}

page#itd_reward tabs#content {
  flex-flow: wrap;
  display: flex;
}

page#itd_reward.state-0 tabs#content, page#itd_reward.state-0 #claim_reward {
  display: none;
}

page#itd_reward.state-0 div#lootpack {
  align-self: center;
  transform: translateY(30%)scale(.45);
}

page#itd_reward.state-1 tabs#content, page#itd_reward.state-1 #claim_reward {
  display: none;
}

page#itd_reward.state-1 div#lootpack {
  align-self: center;
  transform: translateY(30%)scale(.45);
}

page#itd_reward.state-1 div#lootpack icon#lootpack {
  animation: popup-appear-0 .4s ease-out;
  overflow: visible;
}

page#itd_reward.state-2 {
  --delay: 0s;
}

page#itd_reward.state-2 div#lootpack {
  align-self: center;
  transform: translateY(30%)scale(.45);
}

page#itd_reward.state-2 entry {
  animation: appear .2s ease-out backwards;
  animation-delay: var(--delay);
  pointer-events: none;
}

page#itd_reward.state-2 #claim_reward {
  min-width: 30vw;
  height: 13vw;
  margin: auto auto 3vw;
  padding-left: 5vw;
  padding-right: 5vw;
  font-size: 6vw;
  position: relative;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

itd5ka#menu.container {
  width: 100%;
  text-align: center;
  --button-scale-js: .8;
  justify-content: center;
  align-items: flex-end;
  gap: 1.5vw;
  margin-bottom: 12px;
  display: flex;
  position: absolute;
  bottom: 0;
}

itd5ka#menu.container button#itd_main {
  min-width: 30vw;
  color: #fff;
  text-shadow: 1px 1px 1px #00000090;
  height: 15vw;
  justify-content: center;
  align-items: center;
  font-size: 3.3vw;
  display: flex;
}

itd5ka#menu.container label#itd_status {
  width: 80%;
  height: 4vw;
  border-radius: 0 0 var(--radius-border-a) var(--radius-border-a);
  color: #90ee90;
  text-align: center;
  background-color: #00000020;
  align-items: center;
  margin: 0 auto;
  font-size: 2vw;
  font-weight: 500;
  transition-duration: .2s;
  display: grid;
  box-shadow: inset 0 0 10px #0005;
}

itd5ka#menu.container .container {
  height: 15vw;
  max-height: 120px;
  flex-direction: column;
  display: flex;
}

itd5ka#menu.container button#inventory:active, itd5ka#menu.container button#shop:active, itd5ka#menu.container button#itd_reset:active, itd5ka#menu.container button#pvp:active {
  margin-bottom: 10px;
}

itd5ka#menu.container button#inventory:active:before, itd5ka#menu.container button#shop:active:before, itd5ka#menu.container button#itd_reset:active:before, itd5ka#menu.container button#pvp:active:before {
  transform: translate(-50%, -50%) scale(calc(var(--button-scale-js) * .8) );
}

itd5ka#menu.container button#inventory.selected, itd5ka#menu.container button#shop.selected, itd5ka#menu.container button#itd_main.selected, itd5ka#menu.container button#itd_reset.selected, itd5ka#menu.container button#pvp.selected {
  margin-top: -10px;
  margin-bottom: 10px;
}

itd5ka#menu.container button#inventory.selected:before, itd5ka#menu.container button#shop.selected:before, itd5ka#menu.container button#itd_main.selected:before, itd5ka#menu.container button#itd_reset.selected:before, itd5ka#menu.container button#pvp.selected:before {
  transform: translate(-50%, -50%) scale(calc(var(--button-scale-js) * .8) );
}

itd5ka#menu.container label#itd_status.selected {
  margin-top: -10px;
  transition-duration: .2s;
}

itd5ka#menu.container button#inventory:before {
  width: 81px;
  height: 92px;
  background-position: -1564px -698px;
}

itd5ka#menu.container button#shop:before {
  width: 97px;
  height: 93px;
  background-position: -623px -1195px;
}

itd5ka#menu.container button#itd_reset:before {
  width: 107px;
  height: 87px;
  background-position: -1245px -1083px;
}

itd5ka#menu.container button#pvp:before {
  width: 118px;
  height: 81px;
  background-position: -722px -1199px;
}

itd5ka#menu.container > button {
  width: 15vw;
  max-width: 120px;
  height: 15vw;
  max-height: 120px;
  display: block;
}

itd5ka#menu.container > button:before {
  transition-duration: inherit;
  content: "";
  transform: translate(-50%, -50%) scale(calc(var(--button-scale-js) * .7) );
  background-image: url("./res/img/textures.png");
  background-repeat: no-repeat;
  margin: auto;
  display: inline-block;
  position: absolute;
  overflow: hidden;
}

itd5ka#pvp_progress_span {
  width: 100%;
  transform: scale(var(--scale-hud-a) );
  text-align: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
  display: flex;
  position: absolute;
  bottom: 0;
}

itd5ka#pvp_progress_span.state-0 progressbar, itd5ka#pvp_progress_span.state-0 icons.container, itd5ka#pvp_progress_span.state-0 label#pvp_timer {
  color: var(--color-5ka-b);
  text-shadow: none;
}

itd5ka#pvp_progress_span.state-0 progressbar, itd5ka#pvp_progress_span.state-0 icons.container {
  display: none;
}

itd5ka#pvp_progress_span.state-1 {
  bottom: 6vw;
}

itd5ka#pvp_progress_span.state-1 button#pvp_start, itd5ka#pvp_progress_span.state-1 button#pvp_stop {
  display: none;
}

itd5ka#pvp_progress_span.state-1 label#pvp_timer {
  color: var(--color-5ka-b);
  text-shadow: none;
  display: none;
}

itd5ka#pvp_progress_span.state-1 icon {
  transform: translate(-50%, 45%)scale(.7);
}

itd5ka#pvp_progress_span.state-win, itd5ka#pvp_progress_span.state-lose progressbar, itd5ka#pvp_progress_span.state-lose icons.container, itd5ka#pvp_progress_span.state-lose label#pvp_timer, itd5ka#pvp_progress_span.state-lose button#pvp_start, itd5ka#pvp_progress_span.state-lose button#pvp_stop {
  display: none;
}

itd5ka#pvp_progress_span button#pvp_start, itd5ka#pvp_progress_span button#pvp_stop {
  width: 30vw;
  margin: 2vw auto 7vw;
  font-size: 4vw;
}

itd5ka#pvp_progress_span container#controls {
  gap: 10px;
  margin: auto;
  display: flex;
}

itd5ka#pvp_progress_span label#pvp_timer {
  width: -webkit-min-content;
  width: min-content;
  color: #fff;
  text-shadow: 0 1px #000;
  margin: auto;
  font-size: 1.5em;
  display: flex;
}

itd5ka#pvp_progress_span progressbar {
  height: 20px;
  background-color: #00000090;
  border: 2px solid #ffbc6a;
  border-radius: 4px;
}

itd5ka#pvp_progress_span progressbar:before {
  background: linear-gradient(45deg, #ff8400, red);
  border-radius: 4px;
  transition-duration: 2s;
}

itd5ka#pvp_progress_span progressbar, itd5ka#pvp_progress_span icons.container {
  width: 80%;
  margin: auto;
}

itd5ka#pvp_progress_span icons.container {
  height: 64px;
  position: relative;
}

itd5ka#pvp_progress_span icon {
  --progress: 0%;
  width: 64px;
  height: 64px;
  z-index: 1;
  left: var(--progress);
  background-color: #ffffff90;
  border-radius: 100%;
  transition-duration: 1s;
  display: block;
  position: absolute;
  transform: translate(-50%, 45%)scale(.7);
}

itd5ka#pvp_progress_span icon:after {
  content: "^";
  color: red;
}

itd5ka#pvp_progress_span icon:before {
  content: "";
  background-image: url("./res/img/textures.png");
  background-repeat: no-repeat;
  border-radius: 100%;
  margin-top: 50%;
  margin-left: 50%;
  display: inline-block;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

itd5ka#pvp_progress_span icon#employee {
  z-index: 2;
}

itd5ka#pvp_progress_span icon#employee:before {
  width: 71px;
  height: 89px;
  background-position: -1647px -792px;
}

itd5ka#pvp_progress_span icon#task:before {
  width: 64px;
  height: 63px;
  background-position: -1624px -1120px;
}

itd5ka#pvp_progress_span icon.defeated {
  filter: grayscale();
  opacity: .3;
}

itd5ka decor#game_background {
  width: 100%;
  height: 100%;
  background-color: #80dcf3;
  background-position: bottom;
  position: absolute;
}

itd5ka decor#game_background.style-default {
  background-color: #80dcf3;
  background-position: bottom;
}

itd5ka decor#game_background .background_tile_move {
  background-position: 0;
}

@keyframes slide {
  from {
    background-position: 0;
  }

  to {
    background-position: 3776px;
  }
}

itd5ka decor#game_background.color-0 {
  filter: hue-rotate();
}

itd5ka decor#game_background.color-1 {
  filter: hue-rotate(-40deg);
}

itd5ka decor#game_background.color-2 {
  filter: hue-rotate(220deg);
}

itd5ka decor#game_background.color-3 {
  filter: hue-rotate(180deg);
}

itd5ka decor#game_background.color-4 {
  filter: hue-rotate(50deg);
}

itd5ka decor#game_background.style-shop {
  background-color: #fff;
  background-image: url("./res/img/icons/shopping.png");
  background-position: 0;
  background-size: 100px;
}

itd5ka decor#game_background.style-employees {
  background-color: #fff;
  background-image: url("./res/img/icons/pawn.png");
  background-position: 0;
  background-size: 100px;
}

itd5ka decor#game_background.style-star {
  background-color: #fff;
  background-image: url("./res/img/icons/star.png");
  background-position: 0;
  background-size: 100px;
}

itd5ka decor#game_background.style-pvp {
  background-color: #fff;
  background-image: url("./res/img/icons/crown.png");
  background-position: 0;
  background-size: 100px;
}

itd5ka decor#temporal_elements {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
}

itd5ka decor#temporal_elements damage_numbers {
  pointer-events: none;
  color: #000;
  -webkit-text-stroke: 1.5px #fff573;
  font-size: 10vw;
  font-weight: 600;
  animation: damage_numbers_fade .7s linear forwards;
  position: absolute;
}

itd5ka decor#temporal_elements damage_numbers.click {
  color: red;
}

itd5ka decor#temporal_elements damage_numbers.task {
  color: #f0f;
}

itd5ka decor#temporal_elements damage_numbers.employer {
  color: #ff0;
  -webkit-text-stroke: 1.5px red;
}

@keyframes damage_numbers_fade {
  0% {
    opacity: 1;
    transform: translateY(0)scale(.5);
  }

  10% {
    transform: translateY(-20px)scale(1.5);
  }

  40% {
    transform: translateY(-40px)scale(1);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translateY(-150px);
  }
}

itd5ka decor#temporal_elements area_effect {
  border: 7px solid var(--color);
  transform-origin: center;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  animation: area_effect 1.5s ease-in forwards;
  display: flex;
  position: absolute;
  transform: translate(-50%, -50%);
}

itd5ka decor#temporal_elements area_effect.heal {
  --color: #13ff9d;
}

itd5ka decor#temporal_elements area_effect.heal:before {
  width: 266px;
  height: 249px;
  background-position: -277px -518px;
}

itd5ka decor#temporal_elements area_effect.attack {
  --color: red;
}

itd5ka decor#temporal_elements area_effect.attack:before {
  width: 62px;
  height: 60px;
  background-position: -967px -1287px;
}

itd5ka decor#temporal_elements area_effect.poison {
  --color: #000;
}

itd5ka decor#temporal_elements area_effect.poison:before {
  width: 94px;
  height: 122px;
  background-position: -1152px -821px;
}

itd5ka decor#temporal_elements area_effect.boost {
  --color: #44f;
}

itd5ka decor#temporal_elements area_effect.boost:before {
  width: 112px;
  height: 122px;
  background-position: -1031px -1225px;
}

itd5ka decor#temporal_elements area_effect.slowdown {
  --color: #000;
}

itd5ka decor#temporal_elements area_effect.slowdown:before {
  width: 126px;
  height: 96px;
  background-position: -914px -1106px;
}

itd5ka decor#temporal_elements area_effect.freeze {
  --color: #2df;
}

itd5ka decor#temporal_elements area_effect.freeze:before {
  width: 122px;
  height: 114px;
  background-position: -419px -769px;
}

itd5ka decor#temporal_elements area_effect.notification {
  --color: #fb0;
}

itd5ka decor#temporal_elements area_effect:before {
  content: "";
  background-image: url("./res/img/textures.png");
  background-repeat: no-repeat;
  margin-top: 50%;
  margin-left: 50%;
  animation: area_effect_icon 1.5s ease-in forwards;
  display: inline-block;
  position: absolute;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

itd5ka decor#temporal_elements area_effect:after {
  content: "";
  width: 50%;
  height: 50%;
  border: 7px solid var(--color);
  box-shadow: inset 0px 0px 10px 5px var(--color);
  border-radius: 100%;
  margin: auto;
  animation: area_effect_secondary 1.5s ease-in;
  display: block;
}

@keyframes area_effect_secondary {
  0% {
    width: 50%;
    height: 50%;
  }

  50% {
    width: 100%;
    height: 100%;
  }
}

@keyframes area_effect_icon {
  0% {
    transform: translate(-50%, -50%)scale(.2);
  }

  30% {
    opacity: 1;
    transform: translate(-50%, -50%)scale(.3);
  }

  50% {
    transform: translate(-50%, -50%)scale(.4);
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes area_effect {
  0% {
    opacity: 0;
    width: 0;
    height: 0;
    transform: translate(-50%, -50%)scale(.3);
  }

  20% {
    opacity: 1;
  }

  30% {
    transform: translate(-50%, -50%)scale(.5);
  }

  40% {
    opacity: 1;
  }

  100% {
    width: 500px;
    height: 500px;
    opacity: 0;
  }
}

itd5ka decor#menu_background {
  height: 43.2px;
  width: 100%;
  background-color: var(--color-5ka-b);
  position: absolute;
  bottom: 0;
  box-shadow: inset 0 7px #fff2;
}

itd5ka decor#header_background {
  height: calc(24px * 2.1 * calc(var(--scale)  + .2) * var(--iphone-header) );
  width: 100%;
  top: calc(0vw - (var(--iphone-header)  - 1) * 4vw);
  background-color: var(--color-5ka-b);
  position: absolute;
  box-shadow: inset 0 -7px #0002;
}

itd5ka decor#game_foreground {
  z-index: 30;
  width: 0%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: linear-gradient(0deg, #55e04d, #ebf3d5 200%);
  position: absolute;
  right: 0;
}

itd5ka decor#game_foreground:after {
  content: "";
  width: 100%;
  height: 100%;
  mix-blend-mode: lighten;
  background-image: url("./res/img/stamp-256px-tiled.png");
  background-size: 256px;
  display: block;
}

itd5ka decor#game_foreground.animate-forwards {
  z-index: 30;
  width: 0%;
  animation: animate 1s ease-in-out forwards;
  right: 100%;
}

itd5ka decor#game_foreground.animate-backwards {
  z-index: 30;
  width: 0%;
  animation: animate 1s ease-in-out reverse;
  right: 0;
}

@keyframes animate {
  0% {
    width: 0%;
    right: 0;
  }

  50% {
    width: 100%;
    right: 0%;
  }

  100% {
    width: 0%;
    right: 100%;
  }
}

itd5ka decor#itd5ka_paper {
  z-index: 10;
  height: 70vh;
  width: 100%;
  animation: paper_appear 1s ease-out;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

itd5ka decor#itd5ka_paper background {
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("./res/img/paper.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}

itd5ka decor#itd5ka_paper label {
  height: 100%;
  width: 30vh;
  margin: 35vw auto auto;
  font-size: 3vw;
  font-style: italic;
  font-weight: 300;
  animation: label_appear 1s ease-in 1s backwards;
  overflow: clip;
}

itd5ka decor#itd5ka_paper #reward_amount {
  color: #ab2b4f;
  font-weight: bolder;
}

itd5ka decor#itd5ka_paper icon#stamp {
  width: 100px;
  height: 100px;
  background-image: url("./res/img/textures.png");
  background-position: -865px -1287px;
  background-repeat: no-repeat;
  animation: stamp_appear .5s ease-in 2s backwards;
  display: inline-block;
  position: absolute;
  bottom: 30vw;
  left: calc(50% - 34vw);
  overflow: hidden;
  transform: scale(.6);
}

itd5ka decor#itd5ka_paper icon#sign {
  width: 77px;
  height: 61px;
  transform-origin: 0;
  background-image: url("./res/img/textures.png");
  background-position: -1687px -1289px;
  background-repeat: no-repeat;
  animation: sign_appear 1s ease-out 2.5s backwards;
  display: inline-block;
  position: absolute;
  bottom: 40vw;
  left: calc(50% + 10vw);
  overflow: hidden;
  transform: scale(.8);
}

@keyframes paper_appear {
  0% {
    transform: translate(-100%, -10%)rotate(-80deg)scale(1.5);
  }

  35% {
    transform: translate(-52%, -43%)rotate(-5deg)scale(1);
  }
}

@keyframes label_appear {
  0% {
    height: 0;
  }
}

@keyframes stamp_appear {
  0% {
    opacity: 0;
    transform: scale(1.6);
  }

  100% {
    transform: scale(.6);
  }
}

@keyframes sign_appear {
  0% {
    width: 0;
  }
}

.decor-itd-0, .decor-itd-2 {
  background-color: var(--color-5ka-a);
  border-radius: calc(.7 * var(--radius-border-a) );
  color: var(--color-5ka-white);
  text-shadow: 0 2px 1px #00000090;
  text-align: center;
  background: linear-gradient(135deg, #ff3131 0%, #ff2a2a 50%, red 51%, #ac0000 100%) 99% / 250%;
  border: 3px solid #00000050;
  padding: 5px;
  font-weight: bolder;
  transition-duration: .2s;
  box-shadow: inset 0 0 0 4px #ffffff30;
  outline: none !important;
  font-family: "5ka Sans" !important;
}

.decor-itd-0:hover {
  transform: scale(1.05);
  box-shadow: inset 0 0 0 6px #ffffff30;
}

.decor-itd-0:active {
  transform: scale(.95);
  box-shadow: inset 0 0 0 10px #ffffff30;
}

.decor-itd-0:hover {
  perspective: 100px;
  background-position: 0;
  background-size: 110%;
  transform: scale(1.05);
}

.decor-itd-2 {
  background: linear-gradient(135deg, #3b679e 0%, #2b88d9 50%, #207cca 51%, #7db9e8 100%) 99% / 250%;
  transition-duration: .2s;
}

.decor-itd-2:hover {
  perspective: 100px;
  background-position: 0;
  background-size: 110%;
  transform: scale(1.05);
}

.decor-office-assigned, .assigned-floor-no, .assigned-floor-office-1, .assigned-floor-office-2, .assigned-floor-office-3, .assigned-floor-office-4, .assigned-floor-office-5 {
  color: #fff;
  text-shadow: 0 2px 1px #00000090;
  height: -webkit-min-content;
  height: min-content;
  border-top: 1px solid #ffffff90;
  border-radius: 4px;
  margin: auto;
  padding: 2px .5em;
  font-size: 4vw;
  font-weight: 600;
  position: absolute;
  top: -2.5vw;
  right: 5px;
}

.assigned-floor-no {
  background: linear-gradient(135deg, #00000027 0%, #00000032 50%, #00000036 51%, #0000004a 100%);
}

.assigned-floor-office-1 {
  background: linear-gradient(135deg, #8ad6fe 0%, #7abde1 50%, #659dbb 51%, #4e7990 100%);
}

.assigned-floor-office-2 {
  background: linear-gradient(135deg, #8afed3 0%, #75d6b2 50%, #60ae92 51%, #4b8771 100%);
}

.assigned-floor-office-3 {
  background: linear-gradient(135deg, #fee78a 0%, #d4c173 50%, #ac9d5d 51%, #877c4a 100%);
}

.assigned-floor-office-4 {
  background: linear-gradient(135deg, #febe8a 0%, #cf9a6f 50%, #a77d5a 51%, #785941 100%);
}

.assigned-floor-office-5 {
  background: linear-gradient(135deg, #c89dff 0%, #a884d6 50%, #8d6fb4 51%, #6c558b 100%);
}

.decor-button-dust-0 {
  background-color: var(--color-5ka-a);
  border-radius: var(--radius-border-a);
  color: var(--color-5ka-white);
  height: 3em;
  border: 3px solid #00000050;
  padding: 10px;
  box-shadow: inset -1px -1px 0 2px #00000090, inset 0 0 #dbdbdb90;
}

.decor-button-dust-0:hover {
  box-shadow: inset -2px -2px 0 2px #00000090, inset 1px 1px #dbdbdb50;
}

.decor-button-dust-0:active {
  box-shadow: inset 2px 2px 0 2px #00000090, inset -1px -1px #dbdbdb50;
}

.shop_entry_disabled {
  pointer-events: none;
  filter: grayscale(.9);
  opacity: .7;
}

itd5ka#main button {
  pointer-events: all;
  cursor: pointer;
  font-size: 1.6vw;
  display: block;
}

itd5ka#main button.decor-itd-0.selected {
  box-shadow: 0 0 1rem #fffbb2;
}

itd5ka#main button.disabled, itd5ka#main .disabled button {
  opacity: .7;
  box-shadow: initial;
  pointer-events: none;
  filter: grayscale();
  font-weight: 400;
}

itd5ka#main button.decor-itd-1 {
  background-color: var(--color-5ka-a);
  border-radius: var(--radius-border-a);
  border-top: 3px solid #0000;
  border-bottom: 5px solid #00000050;
}

itd5ka#main button.decor-itd-1:hover {
  border-bottom: 8px solid #00000050;
}

itd5ka#main button.decor-itd-1:active {
  border-bottom: 3px solid #6e6e6e77;
}

itd5ka#main progressbar {
  --progress: 50%;
  z-index: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  position: relative;
}

itd5ka#main progressbar:before {
  z-index: -1;
  content: "";
  height: 100%;
  width: var(--progress);
  position: absolute;
  left: 0;
}

body dashboard#debug {
  z-index: 10;
  color: #fff;
  text-shadow: 1px 1px #000;
  pointer-events: none;
  background-color: #00000049;
  border-radius: 7px;
  margin: 20px;
  padding: 10px;
  display: none;
  position: absolute;
}

body dashboard#debug entry {
  margin-left: 2px;
  display: block;
}

body dashboard#debug entry[id]:before {
  content: attr(id) ": ";
  font-size: 1.1em;
}

body dashboard#debug #log entry:first-child {
  opacity: .7;
}

body dashboard#debug #log entry:last-child:before {
  content: "> ";
}

body.debugmode dashboard#debug {
  display: block;
}

.sprite-textures {
  background-image: url("./res/img/textures.png");
  background-repeat: no-repeat;
  display: inline-block;
  overflow: hidden;
}

.textures-icon-pointer_disabled {
  pointer-events: none;
}

.textures-icon-shop_item {
  scale: .8;
}

.textures-attack {
  width: 62px;
  height: 60px;
  background-position: -967px -1287px;
}

.textures-bag_1 {
  width: 128px;
  height: 124px;
  background-position: -674px -811px;
}

.textures-bag_2 {
  width: 128px;
  height: 193px;
  background-position: -1258px -216px;
}

.textures-bag_3 {
  width: 128px;
  height: 136px;
  background-position: -524px -905px;
}

.textures-bonus_etazh {
  width: 54px;
  height: 60px;
  background-position: -1704px -1186px;
}

.textures-bonus_money {
  width: 63px;
  height: 60px;
  background-position: -1532px -330px;
}

.textures-bonus_sale {
  width: 57px;
  height: 60px;
  background-position: -1529px -594px;
}

.textures-book {
  width: 120px;
  height: 120px;
  background-position: -934px -857px;
}

.textures-button-arrow_left {
  width: 95px;
  height: 149px;
  background-position: -1248px -600px;
}

.textures-button-arrow_right {
  width: 95px;
  height: 149px;
  background-position: -1345px -600px;
}

.textures-coffee_0 {
  width: 128px;
  height: 98px;
  background-position: -264px -1038px;
}

.textures-coffee_1 {
  width: 128px;
  height: 129px;
  background-position: -654px -937px;
}

.textures-coffee_2 {
  width: 128px;
  height: 129px;
  background-position: -394px -1034px;
}

.textures-coffee_2_green {
  width: 128px;
  height: 98px;
  background-position: -834px -743px;
}

.textures-coffee_2_pattern {
  width: 128px;
  height: 129px;
  background-position: -524px -1043px;
}

.textures-coffee_3 {
  width: 94px;
  height: 129px;
  background-position: -1056px -857px;
}

.textures-decor-frame-0 {
  width: 118px;
  height: 118px;
  background-position: -1248px -835px;
}

.textures-develop {
  width: 100px;
  height: 97px;
  background-position: -1378px -751px;
}

.textures-dice_question-green {
  width: 100px;
  height: 105px;
  background-position: -1035px -988px;
}

.textures-freeze {
  width: 122px;
  height: 114px;
  background-position: -419px -769px;
}

.textures-gadget-a-0 {
  width: 140px;
  height: 184px;
  background-position: -1116px -216px;
}

.textures-gadget-a-icon {
  width: 100px;
  height: 78px;
  background-position: -1354px -1107px;
}

.textures-gadget-b-0 {
  width: 273px;
  height: 329px;
  background-position: -2px -518px;
}

.textures-gadget-b-icon {
  width: 106px;
  height: 128px;
  background-position: -255px -1266px;
}

.textures-gadget-c-0 {
  width: 152px;
  height: 267px;
  background-position: -545px -518px;
}

.textures-gadget-c-icon {
  width: 119px;
  height: 125px;
  background-position: -914px -979px;
}

.textures-gadget-d-0 {
  width: 142px;
  height: 179px;
  background-position: -1388px -200px;
}

.textures-gadget-d-icon {
  width: 128px;
  height: 125px;
  background-position: -654px -1068px;
}

.textures-gadget-e-0 {
  width: 141px;
  height: 223px;
  background-position: -834px -518px;
}

.textures-gadget-e-icon {
  width: 128px;
  height: 126px;
  background-position: -233px -1138px;
}

.textures-gadget-f-0 {
  width: 139px;
  height: 164px;
  background-position: -977px -626px;
}

.textures-gadget-f-icon {
  width: 128px;
  height: 70px;
  background-position: -1116px -402px;
}

.textures-gadget-g-0 {
  width: 133px;
  height: 291px;
  background-position: -699px -518px;
}

.textures-gadget-g-icon {
  width: 59px;
  height: 128px;
  background-position: -1532px -200px;
}

.textures-gadget-h-0 {
  width: 229px;
  height: 214px;
  background-position: -2px -1107px;
}

.textures-gadget-h-icon {
  width: 128px;
  height: 120px;
  background-position: -363px -1165px;
}

.textures-gadget-i-0 {
  width: 135px;
  height: 167px;
  background-position: -977px -457px;
}

.textures-gadget-i-icon {
  width: 103px;
  height: 128px;
  background-position: -1042px -1095px;
}

.textures-gadget-test-0 {
  width: 183px;
  height: 190px;
  background-position: -1549px -2px;
}

.textures-GARBAGE {
  width: 256px;
  height: 256px;
  background-position: -710px -260px;
}

.textures-gui-larger {
  width: 34px;
  height: 34px;
  background-position: -1094px -821px;
}

.textures-gui-smaller {
  width: 34px;
  height: 34px;
  background-position: -1687px -1352px;
}

.textures-heal {
  width: 266px;
  height: 249px;
  background-position: -277px -518px;
}

.textures-hoodie_2 {
  width: 129px;
  height: 116px;
  background-position: -543px -787px;
}

.textures-hoodie {
  width: 112px;
  height: 127px;
  background-position: -1368px -850px;
}

.textures-icon-checkmark {
  width: 50px;
  height: 48px;
  background-position: -1709px -262px;
}

.textures-icon-clock-16px {
  width: 16px;
  height: 16px;
  background-position: -865px -1269px;
}

.textures-icon-clock {
  width: 68px;
  height: 68px;
  background-position: -1672px -614px;
}

.textures-icon-coffee {
  width: 88px;
  height: 100px;
  background-position: -1242px -1274px;
}

.textures-icon-cog {
  width: 50px;
  height: 49px;
  background-position: -1549px -1037px;
}

.textures-icon-cross {
  width: 96px;
  height: 95px;
  background-position: -1147px -1176px;
}

.textures-icon-gem-24px {
  width: 24px;
  height: 22px;
  background-position: -1089px -216px;
}

.textures-icon-gem-36px {
  width: 36px;
  height: 33px;
  background-position: -967px -1349px;
}

.textures-icon-gem {
  width: 81px;
  height: 74px;
  background-position: -1564px -792px;
}

.textures-icon-gold {
  width: 81px;
  height: 79px;
  background-position: -1647px -883px;
}

.textures-icon-journal {
  width: 50px;
  height: 50px;
  background-position: -1442px -694px;
}

.textures-icon-locations {
  width: 107px;
  height: 87px;
  background-position: -1245px -1083px;
}

.textures-icon-music-muted {
  width: 82px;
  height: 82px;
  background-position: -1588px -614px;
}

.textures-icon-music {
  width: 65px;
  height: 71px;
  background-position: -1695px -1048px;
}

.textures-icon-pawn {
  width: 81px;
  height: 92px;
  background-position: -1564px -698px;
}

.textures-icon-pvp {
  width: 118px;
  height: 81px;
  background-position: -722px -1199px;
}

.textures-icon-shop {
  width: 97px;
  height: 93px;
  background-position: -623px -1195px;
}

.textures-icon-sound-muted {
  width: 38px;
  height: 70px;
  background-position: -1465px -463px;
}

.textures-icon-sound {
  width: 69px;
  height: 70px;
  background-position: -1624px -1048px;
}

.textures-icon-star {
  width: 50px;
  height: 50px;
  background-position: -1494px -694px;
}

.textures-lock_closed-blue {
  width: 82px;
  height: 113px;
  background-position: -493px -1290px;
}

.textures-lock_closed-gray {
  width: 82px;
  height: 113px;
  background-position: -577px -1290px;
}

.textures-lock_closed-mq-blue {
  width: 48px;
  height: 66px;
  background-position: -1709px -194px;
}

.textures-lock_closed-red-128px {
  width: 96px;
  height: 127px;
  background-position: -1147px -1047px;
}

.textures-lock_closed-red {
  width: 146px;
  height: 195px;
  background-position: -968px -260px;
}

.textures-lock_closed-white {
  width: 82px;
  height: 113px;
  background-position: -661px -1290px;
}

.textures-lock_closed {
  width: 82px;
  height: 113px;
  background-position: -1465px -979px;
}

.textures-lootpack-0-open {
  width: 140px;
  height: 114px;
  background-position: -277px -769px;
}

.textures-lootpack-0 {
  width: 128px;
  height: 114px;
  background-position: -493px -1174px;
}

.textures-lootpack-1-128px {
  width: 128px;
  height: 110px;
  background-position: -363px -1287px;
}

.textures-lootpack-1-open-128px {
  width: 128px;
  height: 126px;
  background-position: -804px -843px;
}

.textures-lootpack-1-open {
  width: 260px;
  height: 256px;
  background-position: -2px -849px;
}

.textures-lootpack-1 {
  width: 299px;
  height: 256px;
  background-position: -788px -2px;
}

.textures-lootpack-2-128px {
  width: 128px;
  height: 89px;
  background-position: -1118px -730px;
}

.textures-lootpack-2-open-128px {
  width: 128px;
  height: 98px;
  background-position: -784px -1099px;
}

.textures-lootpack-2-open {
  width: 335px;
  height: 256px;
  background-position: -373px -260px;
}

.textures-lootpack-2 {
  width: 369px;
  height: 256px;
  background-position: -2px -260px;
}

.textures-lootpack-3-128px {
  width: 128px;
  height: 79px;
  background-position: -1246px -411px;
}

.textures-lootpack-3-open-128px {
  width: 115px;
  height: 81px;
  background-position: -914px -1204px;
}

.textures-lootpack-3-open {
  width: 366px;
  height: 256px;
  background-position: -420px -2px;
}

.textures-lootpack-3 {
  width: 416px;
  height: 256px;
  background-position: -2px -2px;
}

.textures-manager_girl1_cr {
  width: 73px;
  height: 92px;
  background-position: -1686px -416px;
}

.textures-manager_girl2_cr {
  width: 72px;
  height: 92px;
  background-position: -1647px -698px;
}

.textures-manager_girl3_cr {
  width: 88px;
  height: 100px;
  background-position: -1512px -1289px;
}

.textures-manager_girl4_cr {
  width: 87px;
  height: 100px;
  background-position: -1530px -1187px;
}

.textures-manager_girl5_cr {
  width: 87px;
  height: 93px;
  background-position: -1597px -321px;
}

.textures-manager_girl6_cr {
  width: 83px;
  height: 92px;
  background-position: -1681px -518px;
}

.textures-manager_girl7_cr {
  width: 78px;
  height: 93px;
  background-position: -1686px -321px;
}

.textures-manager_girl8_cr {
  width: 83px;
  height: 100px;
  background-position: -1602px -1289px;
}

.textures-manager_girl9_cr {
  width: 83px;
  height: 100px;
  background-position: -1619px -1187px;
}

.textures-manager_girl10_cr {
  width: 86px;
  height: 100px;
  background-position: -1442px -1187px;
}

.textures-manager_girl11_cr {
  width: 77px;
  height: 91px;
  background-position: -1545px -1094px;
}

.textures-manager_girl12_cr {
  width: 88px;
  height: 90px;
  background-position: -1332px -1288px;
}

.textures-manager_girl13_cr {
  width: 78px;
  height: 91px;
  background-position: -1465px -1094px;
}

.textures-manager_girl14_cr {
  width: 88px;
  height: 100px;
  background-position: -1422px -1289px;
}

.textures-manager_girl15_cr {
  width: 97px;
  height: 100px;
  background-position: -1245px -1172px;
}

.textures-manager_male1_cr {
  width: 96px;
  height: 99px;
  background-position: -1344px -1187px;
}

.textures-manager_male2_cr {
  width: 87px;
  height: 96px;
  background-position: -1376px -463px;
}

.textures-manager_male3_cr {
  width: 86px;
  height: 94px;
  background-position: -1593px -518px;
}

.textures-manager_male4_cr {
  width: 85px;
  height: 98px;
  background-position: -1510px -392px;
}

.textures-manager_male5_cr {
  width: 82px;
  height: 98px;
  background-position: -1480px -746px;
}

.textures-manager_male6_cr {
  width: 95px;
  height: 99px;
  background-position: -1145px -1273px;
}

.textures-manager_male7_cr {
  width: 85px;
  height: 98px;
  background-position: -1442px -594px;
}

.textures-manager_male8_cr {
  width: 93px;
  height: 100px;
  background-position: -1152px -945px;
}

.textures-manager_male9_cr {
  width: 79px;
  height: 97px;
  background-position: -1482px -846px;
}

.textures-manager_male10_cr {
  width: 86px;
  height: 100px;
  background-position: -1505px -492px;
}

.textures-manager_male11_cr {
  width: 87px;
  height: 100px;
  background-position: -1597px -416px;
}

.textures-nakleika {
  width: 256px;
  height: 212px;
  background-position: -1089px -2px;
}

.textures-notebook {
  width: 128px;
  height: 126px;
  background-position: -784px -971px;
}

.textures-notepad_write {
  width: 70px;
  height: 68px;
  background-position: -842px -1199px;
}

.textures-poison {
  width: 94px;
  height: 122px;
  background-position: -1152px -821px;
}

.textures-rank-0 {
  width: 82px;
  height: 82px;
  background-position: -1563px -868px;
}

.textures-rank-1 {
  width: 82px;
  height: 82px;
  background-position: -1633px -964px;
}

.textures-rank-2 {
  width: 82px;
  height: 83px;
  background-position: -1549px -952px;
}

.textures-rank-3 {
  width: 120px;
  height: 80px;
  background-position: -1388px -381px;
}

.textures-rank-4 {
  width: 127px;
  height: 80px;
  background-position: -2px -1323px;
}

.textures-rank-5 {
  width: 122px;
  height: 80px;
  background-position: -131px -1323px;
}

.textures-shield {
  width: 114px;
  height: 125px;
  background-position: -1593px -194px;
}

.textures-shnurok {
  width: 118px;
  height: 117px;
  background-position: -745px -1282px;
}

.textures-sign {
  width: 77px;
  height: 61px;
  background-position: -1687px -1289px;
}

.textures-slowdown {
  width: 126px;
  height: 96px;
  background-position: -914px -1106px;
}

.textures-speed {
  width: 112px;
  height: 122px;
  background-position: -1031px -1225px;
}

.textures-stamp {
  width: 100px;
  height: 100px;
  background-position: -865px -1287px;
}

.textures-support_1-64px {
  width: 64px;
  height: 63px;
  background-position: -1624px -1120px;
}

.textures-support_1-red {
  width: 128px;
  height: 126px;
  background-position: -1114px -474px;
}

.textures-support_1 {
  width: 128px;
  height: 126px;
  background-position: -1118px -602px;
}

.textures-support_2 {
  width: 128px;
  height: 63px;
  background-position: -964px -792px;
}

.textures-support_3 {
  width: 128px;
  height: 82px;
  background-position: -1248px -751px;
}

.textures-task-a-0-64px {
  width: 64px;
  height: 63px;
  background-position: -1690px -1121px;
}

.textures-task-a-0 {
  width: 200px;
  height: 196px;
  background-position: -1347px -2px;
}

.textures-task_1-h126px-red {
  width: 107px;
  height: 126px;
  background-position: -1247px -955px;
}

.textures-task_1-h126px {
  width: 107px;
  height: 126px;
  background-position: -1356px -979px;
}

.textures-task_1 {
  width: 128px;
  height: 151px;
  background-position: -264px -885px;
}

.textures-task_2 {
  width: 128px;
  height: 147px;
  background-position: -394px -885px;
}

.textures-task_3 {
  width: 128px;
  height: 106px;
  background-position: -1244px -492px;
}

.textures-test_icon_1 {
  width: 71px;
  height: 89px;
  background-position: -1647px -792px;
}

.sprite-sprites-managers {
  background-image: url("./res/img/sprites-managers.png");
  background-repeat: no-repeat;
  display: inline-block;
  overflow: hidden;
}

.sprites-managers-manager_girl1 {
  width: 93px;
  height: 290px;
  background-position: -410px -1px;
}

.sprites-managers-manager_girl2 {
  width: 93px;
  height: 290px;
  background-position: -599px -1px;
}

.sprites-managers-manager_girl3 {
  width: 102px;
  height: 300px;
  background-position: -344px -293px;
}

.sprites-managers-manager_girl4 {
  width: 100px;
  height: 300px;
  background-position: -448px -293px;
}

.sprites-managers-manager_girl5 {
  width: 93px;
  height: 291px;
  background-position: -694px -1px;
}

.sprites-managers-manager_girl6 {
  width: 95px;
  height: 281px;
  background-position: -1px -1px;
}

.sprites-managers-manager_girl7 {
  width: 98px;
  height: 284px;
  background-position: -98px -1px;
}

.sprites-managers-manager_girl8 {
  width: 98px;
  height: 300px;
  background-position: -550px -293px;
}

.sprites-managers-manager_girl9 {
  width: 98px;
  height: 300px;
  background-position: -650px -294px;
}

.sprites-managers-manager_girl10 {
  width: 100px;
  height: 300px;
  background-position: -140px -291px;
}

.sprites-managers-manager_girl11 {
  width: 104px;
  height: 289px;
  background-position: -304px -1px;
}

.sprites-managers-manager_girl12 {
  width: 104px;
  height: 288px;
  background-position: -198px -1px;
}

.sprites-managers-manager_girl13 {
  width: 92px;
  height: 290px;
  background-position: -505px -1px;
}

.sprites-managers-manager_girl14 {
  width: 103px;
  height: 297px;
  background-position: -789px -1px;
}

.sprites-managers-manager_girl15 {
  width: 100px;
  height: 300px;
  background-position: -242px -292px;
}

.sprites-managers-manager_male1 {
  width: 139px;
  height: 300px;
  background-position: -750px -300px;
}

.sprites-managers-manager_male2 {
  width: 142px;
  height: 300px;
  background-position: -138px -594px;
}

.sprites-managers-manager_male3 {
  width: 141px;
  height: 300px;
  background-position: -891px -300px;
}

.sprites-managers-manager_male4 {
  width: 135px;
  height: 300px;
  background-position: -1px -588px;
}

.sprites-managers-manager_male5 {
  width: 133px;
  height: 300px;
  background-position: -282px -595px;
}

.sprites-managers-manager_male6 {
  width: 138px;
  height: 297px;
  background-position: -894px -1px;
}

.sprites-managers-manager_male7 {
  width: 138px;
  height: 300px;
  background-position: -417px -595px;
}

.sprites-managers-manager_male8 {
  width: 137px;
  height: 299px;
  background-position: -1px -287px;
}

.sprites-managers-manager_male9 {
  width: 138px;
  height: 300px;
  background-position: -557px -596px;
}

.sprites-managers-manager_male10 {
  width: 166px;
  height: 331px;
  background-position: -697px -602px;
}

.sprites-managers-manager_male11 {
  width: 153px;
  height: 334px;
  background-position: -865px -602px;
}

.sprites-npc {
  background-image: url("./res/img/npc.png");
  background-repeat: no-repeat;
  display: inline-block;
  overflow: hidden;
}

.sprites-npc-npc_boy1 {
  width: 185px;
  height: 300px;
  background-position: -2px -2px;
}

.sprites-npc-npc_boy2 {
  width: 185px;
  height: 300px;
  background-position: -191px -2px;
}

.sprites-npc-npc_boy3 {
  width: 185px;
  height: 300px;
  background-position: -380px -2px;
}

.sprites-npc-npc_boy4 {
  width: 185px;
  height: 300px;
  background-position: -2px -306px;
}

.sprites-npc-npc_girl1 {
  width: 185px;
  height: 300px;
  background-position: -191px -306px;
}

.sprites-npc-npc_girl2 {
  width: 185px;
  height: 300px;
  background-position: -380px -306px;
}

.sprites-npc-npc_girl3 {
  width: 185px;
  height: 300px;
  background-position: -569px -2px;
}

.sprites-npc-npc_girl4_table {
  width: 185px;
  height: 300px;
  background-position: -569px -306px;
}

html body {
  max-width: initial;
  padding: 0;
  overflow: hidden;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

db {
  display: none;
}

#fps {
  color: #fff;
  text-shadow: 1px 1px .2em #00008c;
  z-index: 1;
  justify-content: right;
  margin: -3.35% 10px auto auto;
  font-size: 2.5vw;
  font-weight: 200;
  display: flex;
  position: relative;
  bottom: -97%;
}

#pointer-events-catcher {
  width: 100%;
  height: 100%;
  pointer-events: all;
  z-index: -2;
  position: absolute;
  top: 0;
}

.tp-dfwv {
  z-index: 1;
  pointer-events: all;
  width: 50px;
  top: auto;
  bottom: 22vw;
  left: 3vw;
}

.tp-dfwv .tp-rotv {
  opacity: .5;
  width: 50px;
  margin-top: -2em;
  transition-duration: .1s;
}

.tp-dfwv .tp-rotv .tp-rotv_t {
  transition-duration: inherit;
  margin-left: -4vw;
}

.tp-dfwv .tp-rotv.tp-rotv-expanded {
  opacity: 1;
  width: 256px;
  margin-top: 0;
}

.tp-dfwv .tp-rotv.tp-rotv-expanded .tp-rotv_t {
  margin-bottom: 0;
}

/*# sourceMappingURL=index.d030e190.css.map */
