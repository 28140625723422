@import './textures.less';
@import './sprites-managers.less';
@import './npc.less';
@import './generic.less';
@import './dust-0.less';

// @media screen and (max-width: @size-width-small_window) {
// 	itd5ka#psources.container {
// 		--width: 90vw;
// 	}
// }

// @media screen and (min-width: @size-width-small_window) {
// 	itd5ka#psources.container {
// 		--width: 70vw;
// 	}
// }

itd5ka#psources.container {
	@pixel_img_width: 1520;
	@pixel_img_height: 749;

	--background_width_px: @pixel_img_width; // share variable into js
	--decor-scale-js: 1;
	--decor-scale: ~'min(1, var(--decor-scale-js))';

	--pixel_ref_img_width: unit(@pixel_img_width, px);
	--clamped_pixel_img_width: ~'min(var(--pixel_ref_img_width), var(--width))';
	width: 100%;
	margin: auto;
	margin-bottom: @size-grid-0 * 3;

	display: flex;
	flex-direction: column-reverse;

	itd5ka#psource {
		position: relative;
		display: block;

		@img_size_ratio: @pixel_img_height / @pixel_img_width;

		// width: 100%;
		height: calc(100vw * @img_size_ratio);

		background-repeat: no-repeat;
		background-size: 102%; // need to fix floors to crop them to the same size
		background-position: center top;

		#psource-controls {
			z-index: 1;
			position: relative;
		}

		&.unactivated {
			// pop up 'buy' button
			#psource-controls {
				z-index: 1;
				position: relative;
			}

			#psource-upgrade-container {
				transform-origin: top right;
				// transform: scale(1.2);
			}

			box-shadow: 0px 3px 5px 0px #00000050;
		}

		// show lock icon for disabled floor
		&.disabled {
			&::after {
				background: linear-gradient(
					90deg,
					#00000050 0%,
					#000000bb 10%,
					#000000bb 90%,
					#00000050 100%
				);
			}
			decor#psource-lock-icon {
				display: initial;
				z-index: 1;
			}

			#psource-employee-stat,
			#psource-gadget-stat {
				display: none;
			}
		}

		&.unactivated {
			label#psource-gadget-stat,
			decor#psource-gadget-sprite,
			decor#psource-employee-sprite,
			decor#psource-npc-sprite-1,
			decor#psource-npc-sprite-2,
			decor#psource-npc-sprite-3,
			label#psource-employee-stat {
				display: none;
			}
		}

		&::before {
			background-size: cover;
			content: '';
			width: var(--clamped_pixel_img_width);
			height: calc(100vw * @img_size_ratio);
			position: absolute;
			z-index: -1;
		}
	}

	// place gameplay sprites on floor
	// {
	decor#psource-employee-sprite,
	#psource-employee-stat {
		position: absolute;
		left: 40%;
		bottom: 3.5vw;
		animation: human-breath 3.0s linear infinite;
	}

	// place npc workers sprites on floor
	decor#psource-npc-sprite-1,
	decor#psource-npc-sprite-2,
	decor#psource-npc-sprite-3 {
		--h_position: 30%;
		--v_position: 20%;
		--breath_delay: 0ms;

		position: absolute;
		left: var(--h_position);
		bottom: var(--v_position);
		animation: human-breath 3.0s ease-out var(--breath_delay) infinite;
	}

	@keyframes human-breath {
		0% {
			transform: translate(-50%, -0%) scale(calc(var(--decor-scale) * 1.5));
		}
		50% {
			transform: translate(-50%, -0%) scale(calc(var(--decor-scale) * 1.5), calc(var(--decor-scale) * 1.5 * 1.02));
		}
		100% {
			transform: translate(-50%, -0%) scale(calc(var(--decor-scale) * 1.5));
		}
	}

	#psource-gadget-stat,
	decor#psource-gadget-sprite {
		position: absolute;
		left: 20%;
		bottom: 7%;
	}

	decor#effects-container {
		position: absolute;
		transform: scale(var(--decor-scale-js));
		mix-blend-mode: screen;
		z-index: 0;
		opacity: 1;
	}

	progressbar#psource-employee-stat:extend(.progressbar-style-ref-0) {
		width: 28vw;
		height: 8vw;
		margin-bottom: 1vw;
		pointer-events: none;
		z-index: 2;
		.progressbar-color-red();
		&::before {
			transition-duration: 0.5s;
			border-radius: inherit;
			background: linear-gradient(90deg, #ff0000 0%, #ff5555 100%);
			box-shadow: 0 4px #6b070a
		}
	}

	decor#psource-gadget-sprite {
		.sprite-textures();
		transform: translate3d(-50%, 0%, 0) scale(var(--decor-scale));
		transform-origin: bottom;
		z-index: 2;
	}

	decor#psource-employee-sprite {
		.sprite-sprites-managers();
		transform: translate(-50%, -2%) scale(calc(var(--decor-scale) * 1.5));
		transform-origin: bottom;
		display: grid;
		overflow: visible;
		z-index: 2;
	}

	decor#psource-npc-sprite-1,
	decor#psource-npc-sprite-2,
	decor#psource-npc-sprite-3 {
		.sprites-npc();
		--y-rotate: 0deg;
		transform: translate(-50%, -0%) scale(var(--decor-scale)) rotateY(var(--y-rotate));
		transform-origin: bottom;
		display: grid;
		overflow: visible;
		z-index: 0;
	}

	decor#psource-employee-sprite.shielded::after {
		content: '';
		.sprite-textures();
		.textures-shield();
		bottom: 100px;
		display: block;
		margin: auto;
		animation: shield-appear 0.5s ease-in;
		@keyframes shield-appear {
			0% {
				opacity: 0;
				transform: scale(1.5);
			}
			100% {
				opacity: 1;
				transform: scale(1);
			}
		}
	}

	button#use_coffee {
		display: none;
		margin: 1vw auto auto 8vw;
		padding-left: 4vw;
		padding-right: 92px;
		padding-top: 2vw;
		padding-bottom: 2vw;
		position: relative;
		font-size: 5vw;
		&::before {
			position: absolute;
			transform: scale(0.5);
			left: 26vw;
			top: -36px;
			content: '';
			.sprite-textures();
			.textures-icon-coffee();
			pointer-events: none;
		}
		&::after {
			content: attr(data-cost);
			position: relative;
			left: 70px;
			top: 2px;
			font-size: 5vw;
			color: #ffba59;
			pointer-events: none;

		}
		&.disabled {
			opacity: 0.7;
			font-weight: 600;
			box-shadow: initial;
			pointer-events: none;
		}
	}

	itd5ka#psource.currently_in_battle button#use_coffee {
		display: flex;
	}

	.stat_decor {
		.progressbar-style-ref-0();
		padding: 2px 10px;
		margin: auto;
		border-radius: 4px;
		color: white;
		text-shadow: 0px 2px 1px #00000090;
		border-top: solid 1px #ffffff90;
	}

	progressbar#psource-gadget-stat:extend(.progressbar-style-ref-0) {
		width: 10vw;
		height: 3vw;
		margin-bottom: auto;
		background-color: #272e28;
		font-size: 3vw;
		.progressbar-color-blue-white-text();
		&::before {
			transition-duration: 0.5s;
			border-radius: inherit;
		}
		color: white;
		text-shadow: 0px 2px 1px #00000090;
		z-index: 2;
	}

	#psource-employee-stat {
		transform: translate(-50%, -100%);
	}

	#psource-gadget-stat {
		transform: translate3d(-50%, -50%, 0);
	}

	label#psource-employee-stat::after {
		content: '♥';
	}

	progressbar#psource-gadget-stat::after {
		font-size: 3vw;
		content: '✦';
	}

	// place gameplay sprites on floor
	// }

	decor#psource-lock-icon {
		//height: 200px;
		//width: 200px;
		.sprite-textures();
		.textures-lock_closed-red();

		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%) scale(calc(var(--decor-scale-js) * 2));
		display: none;
	}

	// different design for first floor
	itd5ka#psource.header_office {
		display: flex;
		button#battle_start {
			color: white;
			display: block;
			padding: 0.5em 20px;
			margin: auto;
			font-size: 4.0vw;
			text-shadow: 0 1px 1px #00000090;
			border: 2px solid #00000050;
		}

		&.unavailable button#battle_start {
			display: none;
		}

		label#buy_all_prev_hint {
			letter-spacing: 1px;
			text-align: center;
			color: #fff;
			margin: auto;
			margin-top: 0px;
			font-size: 3.5vw;
			font-weight: bolder;
			text-shadow: 1px 1px 0px #000000;
			background-color: #467c898c;
			padding-left: 5vw;
			padding-right: 5vw;
			padding-top: 4px;
			padding-bottom: 5px;
			display: none;
		}

		&.unavailable label#buy_all_prev_hint {
			display: block;
		}
	}

	// different design for first floor
	itd5ka#psource.roof_office {
		display: flex;
		height: 25vw;
	}

	#psource-controls.container {
		padding: 4px calc(8vw * var(--decor-scale));
		width: 100%;
		display: flex;
		justify-content: space-between;
		z-index: 1;
		position: relative;
	}

	#psource-tasks.container {
		margin-left: 50%;
		width: 50%;
		height: 50%;
		display: flex;
		padding: 10px calc(30vw * var(--decor-scale));
		flex-direction: row;
		pointer-events: all;
		position: absolute;
		bottom: 0;
		margin-bottom: calc(30px * var(--decor-scale));
		align-items: end;
		justify-content: space-around;
		z-index: 1;

		&:active {
			entry:nth-child(2) {
				icon#psource-task {
					transform: translate(-50%, -0%) scale(calc(var(--decor-scale) * 1.4));
				}
			}
		}

		entry {
			position: relative;
			height: 100%;
			pointer-events: none;

			display: flex;
			flex-direction: column;
			font-size: 1rem;

			icon#psource-task {
				transform: translate(-50%, -0%) scale(calc(var(--decor-scale)*1.6));
				.sprite-textures();
				margin-bottom: 0;
				position: absolute;
				transform-origin: bottom;
				left: 50%;
				bottom: 1vw;
				transition-duration: 70ms;
			}

			label {
				.stat_decor();
				z-index: 1;
				filter: hue-rotate(270deg);
			}

			label#psource-task-text {
				font-size: 0.7em;
				display: none;

				&::after {
					content: '♥';
				}
			}

			progressbar#psource-task-stat:extend(.progressbar-style-ref-0) {
				width: 8vw;
				height: 2.5vw;
				margin-top: 0vw;
				.progressbar-color-red();
				&::before {
					transition-duration: 0.5s;
					border-radius: 2px;
					background: linear-gradient(90deg, #ff0000 0%, #ffc755 100%);
					box-shadow: 0 2px #8f0e11;
				}
			}

			label#psource-task-header {
				display: none;
			}

			&:nth-child(n + 2) {
				//margin-left: -10px;
			}

			&:nth-child(4) {
				//opacity: 0.5;
			}

			&:nth-child(n + 5) {
				display: none;
			}
		}
	}

	#psource-info-container {
		transform: scale(~'min(1, calc(var(--decor-scale) * 2.5))');
		transform-origin: left top;
		pointer-events: all;
		cursor: pointer;

		display: flex;
		flex-grow: 0.6;
		max-width: 50vw;
		margin-left: -2.5vw;
		margin-right: 0;

		&:active #psource-info-icon {
			transform: scale(1.05);
		}
	}

	decor#psource-info-icon.container {
		// transform: translate(-10px, 0px); // scale(calc(var(--decor-scale) * 2), 1.5);
		transition-duration: 100ms;
		pointer-events: none;

		// @width: 10px;
		// @height: 10px;

		// width: @width;
		// // width: 50%;
		// height: @height;

		position: relative;
		display: block;
		margin: auto;

		&::before {
			display: inline-block;
			.sprite-textures();
			.textures-decor-frame-0();
			content: '';

			transform: translate(0, -50%) scale(0.7);
			position: absolute;
			// top: calc(@height * 0.5);
			// left: calc(@width * 0.5);
		}

		.icon {
			position: absolute;
			width: 118px;
			height: 118px;
			transform: translate(5%,-44%)scale(.65);
			icon#psource-manager {
				.sprite-textures();
	
				display: block;
				//transform: translate(0, -50%) scale(0.65);
				position: absolute;
				//left: 3vw;
				// top: calc(@height * 0.5);
				// left: calc(@width * 0.5);
			}
		}
	}

	decor#psource-info-profit.container {
		// transform: translateX(100px);
		pointer-events: none;
		width: 30vw;

		flex-grow: 1;

		color: var(--color-5ka-white);
		background-color: var(--color-5ka-b);
		.decor-5ka-green-gradient-effect();

		display: flex;
		flex-direction: column;
		margin: 0px;

		border-radius: 0 15px 15px 0;
		border-top: solid 5px #ffffff30;
		border-bottom: solid 5px #00000030;
		--button-scale-js: 1;
		transform: translate(88px, 0px);
		min-height: 82px;
		max-height: 82px;

		progressbar#psource-progress {
			margin: 0 2vw 0 7vw;
			width: 40vw;
			height: 5vw;
			text-shadow: 2px 2px 1px #000000a0;
			font-weight: 600;
			font-size: 4.5vw;
			background-color: #00000050;
			border-radius: 7px;
			//transform: scale(1, 1);

			&::before {
				background-color: var(--color-5ka-c);
				//border: solid 2px #ffc55b;
				box-shadow: inset 0px 0px 0px 2px #ffc55b;
				border-radius: 7px;
			}

			&.continues::before {
				box-shadow: inset 0px 0px 3px 1px #ffc55b;
				background: repeating-linear-gradient(
					-45deg,
					#ffc832,
					#ffc832 5px,
					var(--color-5ka-c) 5px,
					var(--color-5ka-c) 10px
				);
				animation: background-move 0.5s linear infinite;
			}

			@keyframes background-move {
				0% {
					background-position: 0px 0px;
				}
				100% {
					background-position: 14.14px 0px;
				}
			}

			@keyframes star-pop {
				0% {
					transform: scale(calc(0.7));
				}

				30% {
					transform: scale(calc(0.85));
				}

				80% {
					transform: scale(calc(0.85));
				}

				100% {
					transform: scale(calc(0.7));
				}
			}

			&::after {
				position: absolute;
				display: inline-block;
				content: '';
				.sprite-textures();
				.textures-icon-star();
				transform: scale(calc(0.7));
				top: -1rem;
				left: -0.9rem;
				transform: scale(calc(0.7));
				z-index: 1;
			}

			&.star-bounce::after {
				animation: star-pop 0.3s linear infinite;
			}
		}

		label#psource-label {
			text-align: center;
			white-space: nowrap;
			// padding-top: 5;
			padding-left: 5px;
			margin-top: -0.5vw;

			font-size: 4.5vw;
			font-weight: 600;
			text-shadow: 2px 0px 1px #00000090;
			// transform: scale(1, 5);
		}

		container#psource-info-details {
			display: flex;
			justify-content: space-between;
			margin: 1vw 0vw 0vw 2vw;
			width: 85%;
			label {
				font-weight: 600;
				font-size: 4vw;
				text-shadow: 2px 0px 1px #00000090;
			}

			label#psource-time {
				align-items: center;
				display: flex;
				color: #dee436;
				margin-left: 4vw;
				&::before {
					content: '';
					margin-right: 1vw;
					display: inline-block;
					margin-bottom: 1vw;
					.sprite-textures();
					.textures-icon-clock-16px();
				}
			}
		}
	}

	#psource-upgrade-container.container {
		display: flex;
		flex-direction: column-reverse;
		justify-content: start;
		margin-left: auto;
		margin-right: 0;

		color: var(--color-5ka-white);
		pointer-events: all;
		cursor: pointer;
		height: fit-content;

		& > * {
			pointer-events: none;
		}

		&.disabled {
			pointer-events: none;
			filter: grayscale(1);
			button#psource-upgrade::before {
				opacity: 0;
				transition-duration: 300ms;
			}

			label#psource-upgrade-value {
				pointer-events: none;
				text-align: center;
				margin-left: 4vw;
				font-size: 4vw;
				font-weight: 400;
				color: #b0b0b0;
				display: block;
				position: relative;
			}
			
			label#psource-upgrade-label {
				opacity: 0;
				transition-duration: 0.7s;
			}
		}

		label#psource-upgrade-label {
			transition-duration: 100ms;
			display: block;
			background-color: var(--color-5ka-b);
			.decor-5ka-green-gradient-effect();
			border-radius: 0 0 3vw 3vw;
			border-bottom: solid 2px #00000030;
			text-align: center;
			text-shadow: 1px 0px 1px #00000090;
			padding: 0.4vw;
			padding-right: 2vw;
			padding-left: 2vw;
			font-size: 2.8vw;
			font-weight: 600;

			width: auto;
			min-width: 10vw;
			margin: 0 var(--radius-border-a);
			margin-left: 5vw;
			margin-right: 2vw;
			margin-top: -0.5vw;
			overflow: overlay;
			position: relative;
			left: 1vw;
		}

		button#psource-upgrade {
			.decor-5ka-red-gradient-effect();
			background-position: 0px;
			background-size: 110%;

			display: block;
			align-items: center;
			min-width: 30vw;
			width: 80%;
			height: 7.5vw;
			text-align: center;
			position: relative;
			font-size: 4vw;
			text-shadow: 1px 0px 1px #00000090;
			--button-scale-js: 1;
			padding: 0.5vw;
			padding-left: 1vw;
			border-radius: 3vw;
			margin-left: auto;

			&::before {
				position: absolute;
				content: '';
				.sprite-textures();
				.textures-icon-gold();
				transform: translate(-50%, -50%) scale(0.45);
				left: 0;
				top: 50%;
				transform-origin: center;
				opacity: 1;
			}
		}

		label#psource-upgrade-value {
			pointer-events: none;
			text-align: center;
			text-shadow: 1px 0 1px #00000090;
			margin-left: 4vw;
			font-size: 4vw;
			display: block;
			position: relative;
			font-weight: bolder;
		}

		&:active {
			button#psource-upgrade {
				background-position: 0px;
				background-size: 110%;
				transform: scale(1.05);
				//perspective: 100px;

				&::before {
					transform: translate(-50%, -50%) scale(0.55) rotate(-15deg);
				}
			}

			label#psource-upgrade-value {
				pointer-events: none;
				text-align: center;
				text-shadow: 1px 0 1px #00000090;
				margin-left: 4vw;
				font-size: 4vw;
				display: block;
				position: relative;
				font-weight: bolder;
			}
			label#psource-upgrade-label {
			}
		}
	}

	.psource_forground_overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		content: '';
		top: 0;
	}

	// shade out
	itd5ka#psource.unactivated::after,
	itd5ka#psource.disabled::after {
		background: linear-gradient(90deg, #00000050 0%, #00000090 10%, #00000090 90%, #00000050 100%);
		.psource_forground_overlay();
	}

	// overlay in battle
	&.battle_mode itd5ka#psource,
	itd5ka#psource.pvp_floor {
		&.currently_in_battle {
			box-shadow: 0px 3px 2px 0px #b93737aa, 0px -3px 2px 0px #b93737aa;

			&::after {
				.psource_forground_overlay();
				mix-blend-mode: overlay;
				background: linear-gradient(90deg, #00000050 0%, #953c3c 10% 90%, #00000050 100%);
				animation: pulse_overlay 2s alternate infinite ease-in-out;
			}

			#psource-controls {
				z-index: 1;
				position: relative;
			}

			@keyframes pulse_overlay {
				0% {
					opacity: 0.8;
				}

				100% {
					opacity: 0.4;
				}
			}
		}

		&.defeated_in_battle::after {
			.psource_forground_overlay();
			z-index: 10;
			mix-blend-mode: multiply;
			background: linear-gradient(90deg, #00000050 0%, #c8a294 10% 90%, #00000050 100%);
			animation: none;
		}
	}

	// hide profits in pvp and battle mode
	itd5ka#psource.pvp_floor,
	&.battle_mode {
		#psource-upgrade-container,
		#psource-level,
		#psource-info-details,
		#psource-progress {
			display: none !important;
		}

		#psource-info-profit {
			min-height: 82px;
		}

		#psource-info-icon {
			margin-left: 0;
		}
	}
}
