.noselect {
	-khtml-user-select: none; /* Konqueror HTML */
	  -moz-user-select: none; /* Old versions of Firefox */
	   -ms-user-select: none; /* Internet Explorer/Edge */
	       user-select: none; /* Non-prefixed version, currently
								supported by Chrome, Edge, Opera and Firefox */
}

.noselect_webkit {
	-webkit-touch-callout: none; /* iOS Safari */
	  -webkit-user-select: none; /* Safari */
}

.div_background_image {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
