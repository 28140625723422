.sprites-npc { background-image: url(./res/img/npc.png); background-repeat: no-repeat; display:inline-block; overflow: hidden; }

.sprites-npc-npc_boy1 { width: 185px; height: 300px; background-position: -2px -2px; }
.sprites-npc-npc_boy2 { width: 185px; height: 300px; background-position: -191px -2px; }
.sprites-npc-npc_boy3 { width: 185px; height: 300px; background-position: -380px -2px; }
.sprites-npc-npc_boy4 { width: 185px; height: 300px; background-position: -2px -306px; }
.sprites-npc-npc_girl1 { width: 185px; height: 300px; background-position: -191px -306px; }
.sprites-npc-npc_girl2 { width: 185px; height: 300px; background-position: -380px -306px; }
.sprites-npc-npc_girl3 { width: 185px; height: 300px; background-position: -569px -2px; }
.sprites-npc-npc_girl4_table { width: 185px; height: 300px; background-position: -569px -306px; }
