@import './dust/generic.less';

@HIDE_TEMPLATES: true;
@HIDE_SCROLLS: true;

@size-grid-0: 24px;
@size-width-small_window: 1200px;

:root {
	--color-5ka-a-ref: #eb2316;
	--color-5ka-b-ref: #008f32;
	--color-5ka-c-ref: #f08b0b;

	--color-5ka-a-brume: #a0cf06;
	--color-5ka-b-brume: #2c2c2c;
	--color-5ka-c-brume: #ff7a00;

	--color-5ka-a: var(--color-5ka-a-ref);
	--color-5ka-b: var(--color-5ka-b-ref);
	--color-5ka-c: var(--color-5ka-c-ref);

	--color-5ka-white: #ffffff;
	--scale-hud-a: 1;
	--radius-border-a: 20px;
	--font-size-absolute: @size-grid-0;
	--font-size: @size-grid-0;
}

// @media screen and (max-width: @size-width-small_window) {
// 	:root {
// 		--font-size: 2vw;
// 	}
// }

@font-face {
	font-family: '5ka Sans';
	src: url('./res/fonts/5kaSansDesign-Thin.otf') format('opentype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: '5ka Sans';
	src: url('./res/fonts/5kaSansDesign-Light.otf') format('opentype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: '5ka Sans';
	src: url('./res/fonts/5kaSansDesign-Regular.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: '5ka Sans';
	src: url('./res/fonts/5kaSansDesign-SemiBold.otf') format('opentype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: '5ka Sans';
	src: url('./res/fonts/5kaSansDesign-Ultra.otf') format('opentype');
	font-weight: 600;
	font-style: normal;
}

body {
	pointer-events: none;
}

itd5ka#main.hidden {
	display: none !important;
}

itd5ka#minified.hidden {
	display: none !important;
}

itd5ka#main.container {
	width: 100%;
	height: 100%;

	display: flex;
	position: absolute;
	pointer-events: none;

	overflow: clip;

	font-size: var(--font-size);
	.noselect();

	font-family: '5ka Sans' !important;

	.hidden {
		display: none !important;
	}

	.highlighted {
		filter: brightness(1.5);
		box-shadow: 0px 0px 0px 5px var(--color-5ka-white);
	}

	.template {
		border: dashed 4px blue;
		display: if(@HIDE_TEMPLATES, none, '') !important;
	}

	decor#psource-info-icon.container {
		z-index: 2;
	}

	decor#psource-info-profit.container {
		z-index: 1;

		progressbar#psource-progress {
			z-index: -1;

			&::after {
				z-index: -1;
			}
		}
	}

	cutscene#game_cutscene.container {
		z-index: 9;
	}

	// z-order
	itd5ka#header.container {
		z-index: 1;
	}

	itd5ka#menu.container,
	itd5ka#pvp_progress_span {
		z-index: 1;
	}

	popup.container::before {
		z-index: -1;
	}

	itd5ka#psources.container {
		z-index: -2;
	}

	decor#game_background {
		z-index: -10;
	}
}
