@import './textures.less';
@import './generic.less';

@import './dust/effects.less';
@import './dust/effects-pos.less';
@import './dust/effects-animations.less';
@import './animations.less';

button#location_prev {
	.sprite-textures();
	.textures-button-arrow_left();

	position: absolute;
	top: 80%;
	transform: scale(0.4) translate(-40px, -100%);
	left: 100px;

	transition-duration: 100ms;
	background-color: transparent;
	border: none;

	&:active {
		//.anim-grow-2();
		transform: scale(0.6) translate(-20px, -70%);
	}
}

button#location_next {
	.sprite-textures();
	.textures-button-arrow_right();

	position: absolute;
	top: 80%;
	transform: scale(0.4) translate(40px, -100%);
	right: 100px;
	transition-duration: 100ms;
	background-color: #00000000;
	opacity: 1;
	filter: none;
	mix-blend-mode: normal;
	background-color: transparent;
	border: none;

	&:active {
		//.anim-grow-1();
		transform: scale(0.6) translate(20px, -70%);
	}
}

@media screen and (max-width: @size-width-small_window) {
	button#location_next {
		right: 5px;
	}

	button#location_prev {
		left: 5px;
	}
}

.decor-5ka-green-gradient-effect {
	background: #008731;
	background: -moz-linear-gradient(-45deg, #008731 0%, #01a13b 50%, #008f32 51%, #008f32 100%);
	background: -webkit-linear-gradient(-45deg, #008731 0%, #01a13b 50%, #008f32 51%, #008f32 100%);
	background: linear-gradient(135deg, #008731 0%, #01a13b 50%, #008f32 51%, #008f32 100%);
}

.decor-5ka-red-gradient-effect {
	background: #ff6767;
	background: -moz-linear-gradient(-45deg, #ff6767 0%, #d12323 50%, #da1616 51%, #da1616 100%);
	background: -webkit-linear-gradient(-45deg, #ff6767 0%, #d12323 50%, #da1616 51%, #da1616 100%);
	background: linear-gradient(135deg, #ff6767 0%, #d12323 50%, #da1616 51%, #da1616 100%);
}

.decor-5ka-blue-gradient-effect {
	background: #3b679e;
	background: -moz-linear-gradient(-45deg, #3b679e 0%, #2b88d9 50%, #207cca 51%, #7db9e8 100%);
	background: -webkit-linear-gradient(-45deg, #3b679e 0%, #2b88d9 50%, #207cca 51%, #7db9e8 100%);
	background: linear-gradient(135deg, #3b679e 0%, #2b88d9 50%, #207cca 51%, #7db9e8 100%);
}

.decor-5ka-gold-gradient-effect {
	background: #ffb76b;
	background: -moz-linear-gradient(-45deg, #ffb76b 0%, #ffa73d 50%, #ff7c00 51%, #ff7f04 100%);
	background: -webkit-linear-gradient(-45deg, #ffb76b 0%, #ffa73d 50%, #ff7c00 51%, #ff7f04 100%);
	background: linear-gradient(135deg, #ffb76b 0%, #ffa73d 50%, #ff7c00 51%, #ff7f04 100%);
}

.decor-5ka-red-gradient-effect {
	background: #ffb76b;
	background: -moz-linear-gradient(-45deg, #ff3131 0%, #ff2a2a 50%, #ff0000 51%, #ac0000 100%);
	background: -webkit-linear-gradient(-45deg, #ff3131 0%, #ff2a2a 50%, #ff0000 51%, #ac0000 100%);
	background: linear-gradient(135deg, #ff3131 0%, #ff2a2a 50%, #ff0000 51%, #ac0000 100%);
}

.decor-office-bg-blue {
	background: #8ad6fe;
	background: -moz-linear-gradient(-45deg, #8ad6fe 0%, #7abde1 50%, #659dbb 51%, #4e7990 100%);
	background: -webkit-linear-gradient(-45deg, #8ad6fe 0%, #7abde1 50%, #659dbb 51%, #4e7990 100%);
	background: linear-gradient(135deg, #8ad6fe 0%, #7abde1 50%, #659dbb 51%, #4e7990 100%);
}
.decor-office-bg-green {
	background: #8afed3;
	background: -moz-linear-gradient(-45deg, #8afed3 0%, #75d6b2 50%, #60ae92 51%, #4b8771 100%);
	background: -webkit-linear-gradient(-45deg, #8afed3 0%, #75d6b2 50%, #60ae92 51%, #4b8771 100%);
	background: linear-gradient(135deg, #8afed3 0%, #75d6b2 50%, #60ae92 51%, #4b8771 100%);
}
.decor-office-bg-yellow {
	background: #fee78a;
	background: -moz-linear-gradient(-45deg, #fee78a 0%, #d4c173 50%, #ac9d5d 51%, #877c4a 100%);
	background: -webkit-linear-gradient(-45deg, #fee78a 0%, #d4c173 50%, #ac9d5d 51%, #877c4a 100%);
	background: linear-gradient(135deg, #fee78a 0%, #d4c173 50%, #ac9d5d 51%, #877c4a 100%);
}
.decor-office-bg-red {
	background: #febe8a;
	background: -moz-linear-gradient(-45deg, #febe8a 0%, #cf9a6f 50%, #a77d5a 51%, #785941 100%);
	background: -webkit-linear-gradient(-45deg, #febe8a 0%, #cf9a6f 50%, #a77d5a 51%, #785941 100%);
	background: linear-gradient(135deg, #febe8a 0%, #cf9a6f 50%, #a77d5a 51%, #785941 100%);
}
.decor-office-bg-purple {
	background: #c89dff;
	background: -moz-linear-gradient(-45deg, #c89dff 0%, #a884d6 50%, #8d6fb4 51%, #6c558b 100%);
	background: -webkit-linear-gradient(-45deg, #c89dff 0%, #a884d6 50%, #8d6fb4 51%, #6c558b 100%);
	background: linear-gradient(135deg, #c89dff 0%, #a884d6 50%, #8d6fb4 51%, #6c558b 100%);
}

.decor-office-bg-empty {
	background: #00000027;
	background: -moz-linear-gradient(-45deg, #00000027 0%, #00000032 50%, #00000036 51%, #0000004a 100%);
	background: -webkit-linear-gradient(-45deg, #00000027 0%, #00000032 50%, #00000036 51%, #0000004a 100%);
	background: linear-gradient(135deg, #00000027 0%, #00000032 50%, #00000036 51%, #0000004a 100%);
}

.decor-5ka-gradient-effect-hover-animation {
	background-position: 99%;
	background-size: 250%;
	transition-duration: 200ms;

	&:hover {
		background-position: 0px;
		background-size: 110%;
		transform: scale(1.05);
		perspective: 100px;
	}
}

.decor-5ka-red-gradient-effect-with-hover {
	.decor-5ka-red-gradient-effect();
	.decor-5ka-gradient-effect-hover-animation();
}

.decor-5ka-gold-gradient-effect-with-hover {
	.decor-5ka-gold-gradient-effect();
	.decor-5ka-gradient-effect-hover-animation();
}

.decor-5ka-blue-gradient-effect-with-hover {
	.decor-5ka-blue-gradient-effect();
	.decor-5ka-gradient-effect-hover-animation();
}

.progressbar-style-ref-0 {
	height: 5vw;
	border-radius: 4px;
	font-size: 0.8em;
	font-weight: bolder;
	background-color: #98b29b;
	box-shadow: 0px 2px #435445;
	color: #414042;
	text-shadow: 0px 1px 0px #ffffff90;
}

.progressbar-color-gold {
	&::before {
		background-color: #f0c755;
		border-bottom: solid 1px #ffffff90;
		box-shadow: 0px 2px #b94a00;
	}
}

.progressbar-color-green {
	&::before {
		background-color: #67d47a;
		border-bottom: solid 1px #ffffff90;
		box-shadow: 0px 2px #066f2b;
	}
}

.progressbar-color-red {
	&::before {
		background-color: #ff7166;
		border-bottom: solid 1px #ffffff90;
		box-shadow: 0px 2px #b60b0f;
	}
}

.progressbar-color-blue {
	&::before {
		background-color: #a6d3eb;
		border-bottom: solid 1px #ffffff90;
		box-shadow: 0px 2px #0078a4;
	}
}

.progressbar-color-blue-white-text {
	&::before {
		background-color: #34aaff;
		border-bottom: solid 1px #ffffff90;
		box-shadow: 0px 2px #404455;
	}
}

*[hover-label] {
	position: relative;
}

*[hover-label]:after {
	opacity: 0;
	content: attr(hover-label);
	padding: 4px 8px;
	position: absolute;
	max-width: 165px;
	min-width: 100px;
	z-index: 20;
	background: white;
	transition: opacity 0.3s;
	pointer-events: none;
	font-size: 4vw;

	border-radius: 4px;
	box-shadow: 0px 2px 2px #00000090;
    top: 30%;
    left: 0;
	white-space: break-spaces;

	//top: -100%;
}

*[hover-label]:hover:after {
	opacity: 1;
	transition-delay: 100ms;
}

.tooltip {
	position: absolute;
	opacity: 0;
	padding: 4px 8px;
	max-width: 30vw;
	min-width: 100px;
	z-index: 100;
	background: white;
	transition: opacity 2s;
	pointer-events: none;
	border-radius: 10px;
	box-shadow: 0px 2px 2px #00000090;
	font-size: 2.8vw;
}

.tooltip.active {
	opacity: 1;
	transition: opacity 2s;
}
