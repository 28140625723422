//
// Created with TexturePacker - https://www.codeandweb.com/texturepacker
//
// SmartUpdateHash: $TexturePacker:SmartUpdate:06108f517aafc276a0a82d09e44c725e:ca7e205a677916a8c5e33b48f2446489:af381fb00e7b2559daea522afbd3b01d$
//

.sprite-sprites-managers { display:inline-block; overflow: hidden; background-repeat: no-repeat; background-image: url('./res/img/sprites-managers.png'); }

.sprites-managers-manager_girl1 { width: 93px; height: 290px; background-position: -410px -1px; }
.sprites-managers-manager_girl2 { width: 93px; height: 290px; background-position: -599px -1px; }
.sprites-managers-manager_girl3 { width: 102px; height: 300px; background-position: -344px -293px; }
.sprites-managers-manager_girl4 { width: 100px; height: 300px; background-position: -448px -293px; }
.sprites-managers-manager_girl5 { width: 93px; height: 291px; background-position: -694px -1px; }
.sprites-managers-manager_girl6 { width: 95px; height: 281px; background-position: -1px -1px; }
.sprites-managers-manager_girl7 { width: 98px; height: 284px; background-position: -98px -1px; }
.sprites-managers-manager_girl8 { width: 98px; height: 300px; background-position: -550px -293px; }
.sprites-managers-manager_girl9 { width: 98px; height: 300px; background-position: -650px -294px; }
.sprites-managers-manager_girl10 { width: 100px; height: 300px; background-position: -140px -291px; }
.sprites-managers-manager_girl11 { width: 104px; height: 289px; background-position: -304px -1px; }
.sprites-managers-manager_girl12 { width: 104px; height: 288px; background-position: -198px -1px; }
.sprites-managers-manager_girl13 { width: 92px; height: 290px; background-position: -505px -1px; }
.sprites-managers-manager_girl14 { width: 103px; height: 297px; background-position: -789px -1px; }
.sprites-managers-manager_girl15 { width: 100px; height: 300px; background-position: -242px -292px; }
.sprites-managers-manager_male1 { width: 139px; height: 300px; background-position: -750px -300px; }
.sprites-managers-manager_male2 { width: 142px; height: 300px; background-position: -138px -594px; }
.sprites-managers-manager_male3 { width: 141px; height: 300px; background-position: -891px -300px; }
.sprites-managers-manager_male4 { width: 135px; height: 300px; background-position: -1px -588px; }
.sprites-managers-manager_male5 { width: 133px; height: 300px; background-position: -282px -595px; }
.sprites-managers-manager_male6 { width: 138px; height: 297px; background-position: -894px -1px; }
.sprites-managers-manager_male7 { width: 138px; height: 300px; background-position: -417px -595px; }
.sprites-managers-manager_male8 { width: 137px; height: 299px; background-position: -1px -287px; }
.sprites-managers-manager_male9 { width: 138px; height: 300px; background-position: -557px -596px; }
.sprites-managers-manager_male10 { width: 166px; height: 331px; background-position: -697px -602px; }
.sprites-managers-manager_male11 { width: 153px; height: 334px; background-position: -865px -602px; }
