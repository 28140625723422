body {
	dashboard#debug {
		display: none;

		entry {
			display: block;
		}

		position: absolute;
		z-index: 10;

		margin: 20px;
		border-radius: 7px;
		padding: 10px;
		background-color: #00000049;
		color: white;
		text-shadow: 1px 1px black;
		pointer-events: none;

		entry {
			margin-left: 2px;
			&[id]::before {
				content: attr(id) ': ';
				font-size: 1.1em;
			}
		}

		#log {
			& entry:first-child {
				opacity: 0.7;
			}

			& entry:last-child::before {
				content: '> ';
			}
		}
	}

	&.debugmode dashboard#debug {
		display: block;
	}
}
