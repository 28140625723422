// box-shadow + transform based animation
// You have to set --button-shade variable to shade value you want to display 
.button-anim-opt-0 {
	@highlight: 0px -1px inset #ffffff90;
	box-shadow: @highlight, 0px 4px var(--button-shade);

	&:hover {
		transform: translateY(-2px);
		box-shadow: @highlight, 0px 6px var(--button-shade);
	}

	&:active {
		transform: translateY(3px);
		box-shadow: @highlight, 0px 1px var(--button-shade);
	}
}

// Margin based animation
// Could have wobbling sizes
.button-anim-opt-1 {
	margin-top: 2px;
	border-bottom: solid 4px #00000090;
	box-shadow: 0px 2px 2px #00000030, 0px -1px inset #ffffff90;

	&:hover {
		border-bottom: solid 6px #00000090;
		margin-top: 0px;
	}

	&:active {
		border-bottom: solid 2px #00000090;
		margin-top: 4px;
	}
}

// "Invisible" borders based animation
// You have to set --button-background variable to background color you render element on
.button-anim-opt-2 {
	box-shadow: 0px 2px 2px #00000030, 0px -1px inset #ffffff90;

	@hover_shift: 2px;
	@shade_height: 4px;
	@click_shift: 1px - @shade_height;

	border-top: solid @hover_shift var(--button-background);
	border-bottom: solid @shade_height #00000090;
	
	&:hover {
		border-top: solid 0px var(--button-background);
		border-bottom: solid @shade_height + @hover_shift #00000090;
	}

	&:active {
		border-top: solid @hover_shift - @click_shift var(--button-background);
		border-bottom: solid @shade_height + @click_shift #00000090;
	}
}