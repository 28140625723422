@import './generic.less';
@import './dust-0.less';

.decor-itd-0 {
	background-color: var(--color-5ka-a);
	border-radius: calc(0.7 * var(--radius-border-a));
	border: solid 3px #00000050;
	box-shadow: 0px 0px 0px 4px inset #ffffff30;
	color: var(--color-5ka-white);
	padding: 5px;
	color: var(--color-5ka-white);
	text-shadow: 0px 2px 1px #00000090;
	text-align: center;
	transition-duration: 100ms;
	font-weight: bolder;
	outline: none !important;
    font-family: "5ka Sans"!important;

	&:hover {
		box-shadow: 0px 0px 0px 6px inset #ffffff30;
		transform: scale(1.05);
	}

	&:active {
		box-shadow: 0px 0px 0px 10px inset #ffffff30;
		transform: scale(0.95);
	}

	// --- gradient animation
	.decor-5ka-red-gradient-effect-with-hover();
}

.decor-itd-2 {
	&:extend(.decor-itd-0);
	.decor-5ka-blue-gradient-effect-with-hover();
}

.decor-office-assigned {
	padding: 2px 0.5em;
	border-radius: 4px;
	color: white;
	text-shadow: 0px 2px 1px #00000090;
	border-top: solid 1px #ffffff90;
	height: min-content;
	font-weight: 600;
	font-size: 4vw;
	position: absolute;
    top: -2.5vw;
	right: 5px;
	margin: auto;
}

.assigned-floor-no {
	&:extend(.decor-office-assigned);
	.decor-office-bg-empty();
}
.assigned-floor-office-1 {
	&:extend(.decor-office-assigned);
	.decor-office-bg-blue();
}
.assigned-floor-office-2 {
	&:extend(.decor-office-assigned);
	.decor-office-bg-green();
}
.assigned-floor-office-3 {
	&:extend(.decor-office-assigned);
	.decor-office-bg-yellow();
}
.assigned-floor-office-4 {
	&:extend(.decor-office-assigned);
	.decor-office-bg-red();
}
.assigned-floor-office-5 {
	&:extend(.decor-office-assigned);
	.decor-office-bg-purple();
}

.decor-button-dust-0 {
	background-color: var(--color-5ka-a);
	border-radius: var(--radius-border-a);
	border: solid 3px #00000050;
	box-shadow: -1px -1px 0px 2px inset #00000090, 0px 0px 0px 0px inset #dbdbdb90;
	color: var(--color-5ka-white);
	padding: 10px;
	height: 3em;

	&:hover {
		box-shadow: -2px -2px 0px 2px inset #00000090, 1px 1px 0px 0px inset #dbdbdb50;
	}

	&:active {
		box-shadow: 2px 2px 0px 2px inset #00000090, -1px -1px 0px 0px inset #dbdbdb50;
	}
}

.shop_entry_disabled {
  pointer-events: none;
  filter: grayscale(0.9);
  opacity: 0.7;
}

itd5ka#main {
	button {
		display: block;
		pointer-events: all;
		cursor: pointer;
		font-size: 1.6vw;

		&.decor-itd-0.selected {
			box-shadow: 0px 0px 1rem #fffbb2;
		}
	}

	button.disabled,
	.disabled button {
		opacity: 0.7;
		font-weight: 400;
		box-shadow: initial;
		pointer-events: none;
		filter: grayscale(1);
	}

	button.decor-itd-1 {
		background-color: var(--color-5ka-a);
		border-radius: var(--radius-border-a);
		border-bottom: solid 5px #00000050;
		border-top: solid 3px #00000000;

		&:hover {
			border-bottom: solid 8px #00000050;
		}

		&:active {
			border-bottom: solid 3px #6e6e6e77;
		}
	}

	progressbar {
		--progress: 50%;

		display: flex;
		justify-content: center;
		align-items: center;
		margin: auto;

		position: relative;
		z-index: 0;

		text-align: center;

		&::before {
			z-index: -1;
			position: absolute;
			left: 0;

			content: '';
			height: 100%;
			width: var(--progress);
		}
	}
}
