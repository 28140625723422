//
// Created with TexturePacker - https://www.codeandweb.com/texturepacker
//
// SmartUpdateHash: $TexturePacker:SmartUpdate:bd69e2fd37804f156d0f1e61edf77462:37d76e68fd119f939cdfcede6f15edf3:a841f19bcab831d2ed7e5f1ffecba524$
//

.sprite-characters { display:inline-block; overflow: hidden; background-repeat: no-repeat; background-image: url('./res/img/characters.png'); }

.characters-alena-a { width: 294px; height: 512px; background-position: -425px -516px; }
.characters-dima-a { width: 413px; height: 512px; background-position: -425px -2px; }
.characters-eduard-a { width: 421px; height: 512px; background-position: -2px -2px; }
.characters-ivan-a { width: 421px; height: 512px; background-position: -2px -516px; }
.characters-mascot { width: 450px; height: 500px; background-position: -2px -1030px; }
.characters-vika-a { width: 279px; height: 512px; background-position: -454px -1030px; }
