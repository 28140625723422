@import './generic.less';
@import './textures.less';
@import './characters.less';
@import './dust-0.less';

cutscene#game_cutscene.container {
	.noselect();

	pointer-events: all;

	width: 100%;
	height: 100%;

	--padding: 10px;
	padding: var(--padding);

	flex-direction: column-reverse;
	display: flex;
	position: absolute;
	bottom: 0;

	background-color: #80dcf3;
	background-image: url('./res/img/itd/kafe.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	.area-style-0 {
		background-color: var(--color-5ka-white);
		box-shadow: 0px -10px 0px var(--color-5ka-b);
		border-radius: var(--radius-border-a);
	}

	cutscene#storyline_text.content {
		.area-style-0();

		padding: 20px 20px 40px 20px;
		width: 100%;
		// min-height: 6em;
		line-height: 1.5em;
		font-size: 4vw;
		font-weight: 300;
		margin-bottom: 10px;

		b {
			font-size: 2.2em;
		}

		h1,
		h2,
		h3,
		h4 {
			font-size: 1.2em;
		}

		code {
			background-color: #aaaaaaaa;
			border-radius: 4px;
			padding: 1px 4px;
		}

		z-index: 1;
	}

	#storyline_title {
		font-weight: 600;
		&_container {
			color: var(--color-5ka-white);
			font-size: 4.5vw;
			letter-spacing: 1px;

			transform: translate(0px, 10px) scale(1);
			margin: 0 5vw;
			display: flex;

			z-index: 1;
		}
		&,
		&_right {
			padding: 10px;

			background-color: var(--color-5ka-b);
			box-shadow: 0px 3px 10px 2px #00000030;
			border-radius: 5px;
			filter: brightness(1.1);
		}
		&_right {
			margin-right: 0;
			margin-left: auto;
		}
	}

	#storyline_sprite_container {
		width: 100%;
		transform: translateY(20px);
	}
	#storyline_sprite {
		margin-right: -50vw;
		&_container {
			display: flex;
			// [class^='characters-'] {
			// 	transform: translate(-30%, 30%) scale(0.45);
			// }
			// .characters-mascot {
			// 	transform: translate(30%, 30%) scale(0.45);
			// }
			.right {
				transform: translate(30%, 30%) scale(-0.45, 0.45);
			}
			.characters-mascot.right {
				transform: translate(30%, 30%) scale(0.45);
			}
			.left {
				transform: translate(-30%, 30%) scale(0.45);
				margin-left: 0;
			}
		}
		&, &_right {
			.sprite-characters();
			margin-left: auto;
			// transform: translate(30%, 30%) scale(0.45);
		}
		// &_right {
		// 	margin-right: 0;
		// 	margin-left: auto;
		// 	transform: translate(0%, 5em) scaleX(-1);
		// }
	}

	#storyline_reward {
		font-weight: 600;
		&_container {
			color: var(--color-5ka-white);
			font-size: 2.5em;
			letter-spacing: 2px;

			transform: translateY(80vw);
			margin: auto;
			display: block;

			z-index: 3;

			div#video_reward_tip {
				display: flex;
				position: relative;
				border-radius: 10px;
				color: #ffffff;
				font-weight: bolder;
				letter-spacing: 1px;
				font-size: 3.5vw;
				text-align: center;
				vertical-align: middle;
				margin: auto;
				margin-bottom: 1vw;
				padding: 10px;
				background-color: #00000088;
				max-width: 50vw;
			}
			
			button#storyline_reward {
				width: 100%;
				height: 60%;
				display: flex;
				pointer-events: all;
				border: 6px #a01313ab solid;
				div#video_reward {
					content: '';
					margin: auto;
					transform: scale(0.75);
					.sprite-textures();
					.textures-icon-gem();
					display: flex;
					position: absolute;
					pointer-events: none;
				}
				&::before {
					position: absolute;
					content: '';
					margin: auto;
					transform: scale(0.8);
					.sprite-textures();
					.textures-icon-gem();
					filter: grayscale(1) brightness(0);
					display: flex;
					pointer-events: none;
				}

				div#video_reward_value {
					display: inline-block;
					position: relative;
					border-radius: 100%;
					color: #ffffff;
					font-weight: bolder;
					letter-spacing: 2px;
					font-size: 8vw;
					font-weight: bolder;
					vertical-align: text-bottom;
					padding: 10px;
					margin-left: 20vw;
					margin-right: auto;
					margin-top: 2vw;
					pointer-events: none;
				}
			}
		}
		&,
		&_right {
			padding: 10px;
			width: 100px;
			height: 100px;
			.decor-5ka-red-gradient-effect-with-hover();
			box-shadow: 0px 3px 10px 2px #00000030;
			border-radius: 20px;
			filter: brightness(1.1);
		}
		&_right {
			margin-right: 0;
			margin-left: auto;
		}
	}

	button#storyline_skip {
		right: var(--padding);
		margin: var(--padding);
		font-size: 3vw;
		color: #00000050;

		position: absolute;

		padding: 0.3em;
		border: 2px solid #00000050;
		border-radius: 5px;
		pointer-events: all;
		bottom: 15px;

		cursor: pointer;
		transition-duration: 200ms;
		min-width: max-content;
		&:hover {
			box-shadow: inset 0px 0px 0px 2px #00000050;
		}

		z-index: 2;
	}
}
