.sprite-effects { transform-origin: center; position: absolute; }
.reception_1 .effect_lamp { left: 290px; top: 450px; .sprite-effects(); .effects-reception_1-lamp(); transform: translate(-50%, -50%) scale(1.05, 1.05); }
.reception_1 .effect_lamp2 { left: 315px; top: 487px; .sprite-effects(); .effects-reception_1-lamp-a(); transform: translate(-50%, -50%) scale(1.05, 1.05); }
.reception_1 .tv { left: 1115px; top: 140px; .sprite-effects(); .effects-reception_1-tv-logo(); transform: translate(-50%, -50%) scale(1.05, 1.05); }
.reception_1 .screen { left: 875px; top: 240px; .sprite-effects(); .effects-reception_1-screen(); transform: translate(-50%, -50%) scale(1.05, 1.05); }
.reception_5 .effect_lamp2 { left: 425px; top: 547px; .sprite-effects(); .effects-reception_1-lamp(); transform: translate(-50%, -50%) scale(0.95, 0.9); }
.reception_5 .effect_lamp3 { left: 1205px; top: 547px; .sprite-effects(); .effects-reception_1-lamp(); transform: translate(-50%, -50%) scale(0.95, 0.9); }
.hr .screen2 { left: 867px; top: 492px; .sprite-effects(); .effects-hr-screen-a(); transform: translate(-50%, -50%) scale(1.05, 1.05); }
.kabinet_glavnogo .screen3 { left: 860px; top: 220px; .sprite-effects(); .effects-kg-screen(); transform: translate(-50%, -50%) scale(1.05, 1.05); }
.kafe .effect_lamp { left: 375px; top: 463px; .sprite-effects(); .effects-reception_1-lamp(); transform: translate(-50%, -50%) scale(1.4, 1.4); }
.kafe .effect_lamp2 { left: 754px; top: 470px; .sprite-effects(); .effects-reception_1-lamp(); transform: translate(-50%, -50%) scale(1.4, 1.4); }
.kafe .effect_lamp3 { left: 1280px; top: 460px; .sprite-effects(); .effects-reception_1-lamp(); transform: translate(-50%, -50%) scale(1.4, 1.4); }
.kafe .tv { left: 610px; top: 125px; .sprite-effects(); .effects-reception_1-tv(); transform: translate(-50%, -50%) scale(1.05, 1.05); }
.kafe .tv2 { left: 1130px; top: 125px; .sprite-effects(); .effects-reception_1-tv(); transform: translate(-50%, -50%) scale(1.05, 1.05); }
.sklad .effect_lamp { left: 275px; top: 185px; .sprite-effects(); .effects-reception_1-lamp(); transform: translate(-50%, -50%) scale(1.6, 1); }
.sklad .effect_lamp2 { left: 820px; top: 215px; .sprite-effects(); .effects-reception_1-lamp(); transform: translate(-50%, -50%) scale(1.6, 1); }
.sklad .effect_lamp3 { left: 1300px; top: 200px; .sprite-effects(); .effects-reception_1-lamp(); transform: translate(-50%, -50%) scale(1.6, 1); }
.sportzal .sign { left: 523px; top: 168px; .sprite-effects(); .effects-gym-sign-light(); transform: translate(-50%, -50%) scale(1, 1.07); }
