@import './textures.less';
@import './generic.less';

itd5ka#header.container {
	width: 100%;
	position: absolute;
	display: flex;
	//gap: 2vw;
	height: calc(@size-grid-0 * 2.1 * var(--scale-js) * var(--iphone-header));
	top: calc(0vw - (var(--iphone-header) - 1)*4vw);
	transform: scale(var(--scale-hud-a));
    flex-direction: column-reverse;

	#currency.container {
		display: flex;
		margin: auto;
		margin-left: 6px;
		gap: 4px;
		transform-origin: bottom;
		position: absolute;
		bottom: 14px;

		div#gold::before {
			.textures-icon-gold();
			transform: translateX(1.8rem) scale(calc(var(--scale-js) * 0.4));
		}

		div#gems::before {
			.textures-icon-gem();
			transform: translateX(1.8rem) scale(calc(var(--scale-js) * 0.4));
		}

		div#coffee::before {
			.textures-icon-coffee();
			transform: translateX(1.8rem) translateY(-1vw) scale(calc(var(--scale-js) * 0.4));
		}

		.decor-0 {
			border: solid 3px #9e9e9e;
			box-shadow: 0px 0px 0px 2px inset #e1e1e1;
		}

		.decor-1 {
			border-bottom: solid 4px #00000030;
		}

		div.decor-currency {
			color: var(--color-5ka-b);
			font-weight: 600;
			min-width: 24vw;
			max-width: 24vw;
			font-size: 3vw;
			height: calc( var(--scale-js) * 35px);

			background-color: var(--color-5ka-white);
			border-radius: calc(var(--radius-border-a) * 0.7);
			display: flex;
			align-items: center;
			justify-content: center;
			.decor-0();

			&::before {
				content: '';
				.sprite-textures();

				display: block;
				position: relative;
				margin-left: -3.7rem;
			}
			label {
				display: block;
				text-align: center;
				position: relative;
				margin-left: auto;
				margin-right: 1.6vw;
				white-space: nowrap;
			}
		}

		div.decor-currency.coffee {
			min-width: 18vw;
			max-width: 18vw;
		}
	}
}

// 220407 костыль, в общем-то
itd5ka#minified {
	pointer-events: all;

	button {
		cursor: pointer;
	}

	itd5ka#header {
		flex-direction: column-reverse;
		width: min-content;
		gap: 30px;
		padding: 0;

		#currency {
			padding: 0 50px;
			flex-direction: column;
		}

		#controls {
			padding: 0 10px;
			justify-content: left;
		}
	}
}

#controls.container {
	display: flex;
	gap: 1vw;
	width: 35vw;
    margin: auto auto -13vw 2vw;

	button#journal::before {
		.textures-icon-journal();
		transform: translate(-50%, -50%) scale(0.5);
	}
	button#journal:active::before {
		transform: translate(-50%, -50%) scale(0.7);
	}

	button#journal.notification::after {
		content: '!';
		font-size: 10vw;
		color: yellow;
		position: relative;
		margin: auto;
		line-height: 0;
	}

	button#settings::before {
		.textures-icon-cog();
		transform: translate(-50%, -50%) scale(0.5);
	}
	button#settings:active::before {
		transform: translate(-50%, -50%) scale(0.7);
	}

	button#change_size_mode {
		&.minify::before {
			.textures-gui-smaller();
		}
		&.maxify::before {
			.textures-gui-larger();
		}
	}

	button.decor-itd-0 {
		&::before {
			.sprite-textures();
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
		}

		width: 10vw;
		height: 10vw;
		display: flex;
		position: relative;
		border-radius: 10px;
		box-shadow: 0 0 3px 0px #000000, inset 0 0 0 4px #ffffff30;
	}

	button::before {
		transition-duration: inherit;
	}
}
