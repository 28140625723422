@import './textures.less';

.anim-popup-appear-0 {
	animation: popup-appear-0 0.4s ease-out;
}

.anim-popup-appear-1 {
	animation: popup-appear-1 0.5s ease-in-out;
}

.anim-grow-1 {
	animation: grow-1 0.5s ease-in-out;
}

.anim-grow-2 {
	animation: grow-2 0.5s ease-in-out;
}

@keyframes popup-appear-1 {
	0% {
		margin-top: 300px;
	}

	60% {
		margin-top: -40px;
	}

	100% {
		margin-top: 0px;
	}
}

@keyframes popup-appear-0 {
	0% {
		transform: scaleX(0);
		transform: scaleY(0);
		opacity: 0;
	}

	30% {
		transform: scaleX(1.3);
	}

	60% {
		opacity: 1;
		transform: scaleY(1.1);
		transform: scaleX(0.9);
	}

	100% {
		transform: scaleX(1);
		transform: scaleY(1);
		opacity: 1;
	}
}

@keyframes anim-shine-0 {
	0% {
		transform: translate(-50%, -50%) scaleX(0);
		transform: translate(-50%, -50%) scaleY(0);
		opacity: 0;
	}

	50% {
		transform: translate(-50%, -50%) scaleX(2);
	}

	60% {
		opacity: 1;
	}

	100% {
		transform: translate(-50%, -50%) scaleX(1);
		transform: translate(-50%, -50%) scaleY(1);
		opacity: 0;
		display: none;
	}
}


@keyframes anim-shine-1 {
	0% {
		opacity: 0;
	}

	60% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		display: none;
	}
}

@keyframes grow-1 {
	0% {
		transform: scale(0.4) translate(40px, -100%);
	}

	50% {
		transform: scale(0.7) translate(0px, -100%);
	}

	70% {
		transform: scale(0.2) translate(0px, -100%);
	}

	100% {
		transform: scale(0.4) translate(40px, -100%);
	}
}

@keyframes grow-2 {
	0% {
		transform: scale(0.4) translate(-40px, -100%);
	}

	50% {
		transform: scale(0.7) translate(0px, -100%);
	}

	70% {
		transform: scale(0.2) translate(0px, -100%);
	}

	100% {
		transform: scale(0.4) translate(-40px, -100%);
	}
}