//
// Created with TexturePacker - https://www.codeandweb.com/texturepacker
//
// SmartUpdateHash: $TexturePacker:SmartUpdate:24b70ce31292bd94988cf9da4d9ddaeb:121548af73f47d1113b55cff230fa46b:7a38f2526e1cd4b1f34f667b9149bbb3$
//

.sprite-effects { display:inline-block; overflow: hidden; background-repeat: no-repeat; background-image: url('./res/img/effects.png'); }

.effects-gym-sign-light { width: 491px; height: 320px; background-position: -1px -1px; }
.effects-hr-screen-a { width: 96px; height: 67px; background-position: -341px -323px; }
.effects-kg-screen { width: 262px; height: 208px; background-position: -1px -597px; }
.effects-reception_1-lamp-a { width: 338px; height: 272px; background-position: -1px -323px; }
.effects-reception_1-lamp { width: 240px; height: 240px; background-position: -265px -597px; }
.effects-reception_1-screen { width: 240px; height: 146px; background-position: -249px -990px; }
.effects-reception_1-tv-disabled { width: 246px; height: 149px; background-position: -1px -807px; }
.effects-reception_1-tv-logo { width: 246px; height: 149px; background-position: -249px -839px; }
.effects-reception_1-tv { width: 246px; height: 149px; background-position: -1px -958px; }
